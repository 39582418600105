export class PayTransaction {

    idTienda: number;
    cardToken: string;
    cvv: string;
    amount: string;
    txnType: string | null | "checkIn" | "recurring" | "recurringCheckIn";
    contractNumber: string;
    paymentPlan: number | null | 3 | 6 | 9 | 12 | 18 | 24;
    amexCustPostalCode: string;
    amexCustAddress: string;
    amexCustFirstName: string;
    amexCustLastName: string;
    amexCustEmailAddr: string;
    amexCustHostServerNm: string;
    amexCustBrowserTypDescTxt: string;
    amexShipToCtryCd: string = "484"; //484 es mexico
    amexShipMthdCd: string = "02";
    amexMerSKUNbr: string;
    amexCustIPAddr: string;
    amexCustIdPhoneNbr: string;
    amexCallTypId: string = "63";

    constructor() { }

}