import { Component, AfterContentChecked, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RoutingService } from './services/routing.service';
import { fromEvent, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollingService } from './services/scrolling.service';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Aquí Pide';
  isLandingPage = false;
  constructor(
    private router: Router,
    private routingService: RoutingService,
    public scrollingService: ScrollingService) {
    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.isLandingPage = (route.url == "/" || route.url == "/home" || route.url == "/como-funciona" || route.url == "/saber-mas" || route.url == "/pricing") ? true : false;
        this.routingService.validateRoute(route.url);
        this.scrollingService.scrollTop();
      }
    });
  }

  gotoTop() {
    this.scrollingService.scrollTop();
  }

}
