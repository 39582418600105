import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  constructor() { }

  async verifyPopUp() {
    return await this.popupBlockerChecker.check(window.open());
  }

  createMessage(json, direccionSucursal){
    let mensaje = `Hola ${json.nombreTienda}, soy ${json.nombreCliente} y quiero hacer el siguiente pedido:%0A %0A`;
    if(json.programarPedido){
      mensaje += `*Programado para el día* *${moment(json.fecha).locale("es").format('dddd DD [de] MMMM, YYYY')} a las ${json.hora} horas* %0A %0A`;
    }
    if (json.tipoEnvio == 1) {
      mensaje += `*(Servicio a domicilio)*%0A`;
      mensaje += `*Dirección:* ${json.direccion.calle} ${json.direccion.numero}, ${json.direccion.nombreColonia}, entre ${json.direccion.entreCalle} y ${json.direccion.yCalle}.%0A`
      if (json.direccion.referencia && json.direccion.referencia.trim() != '')
        mensaje += `Referencia: ${json.direccion.referencia}%0A`
    } else {
      mensaje += `(Ordene y recoja) *Pasaré a recoger en* %0A`;
      mensaje += `*Sucursal:* ${json.nombreSucursal}. *Ubicada en* ${direccionSucursal}%0A`
    }
    mensaje += `---------------------%0A`;
    for (const prod of json.detalleProducto) {
      mensaje +=
        `*${prod.cantidad}x ${prod.nombre}* _Tamaño:_ ${prod.nombreTamano} \$${(prod.cantidad * prod.precioUnitario).toFixed(2)}%0A`;
      if (prod.ingredientes && prod.ingredientes.length > 0) {
        mensaje += `_Con los ingredientes:_ %0A`;
        for (const extra of prod.ingredientes) {
          mensaje += ` - ${extra.nombre}%0A`;
        }
      }
      if (prod.extras && prod.extras.length > 0) {
        mensaje += `_Extras:_ %0A`;
        for (const extra of prod.extras) {
          mensaje += ` - ${extra.nombre}  +\$${extra.precio.toFixed(2)} %0A`;
        }
      }
      if (prod.comentario && prod.comentario.trim() != '')
        mensaje += `_*${prod.comentario}_%0A`;
      mensaje += `%0A`;
    }
    if (json.detalleCombo.length > 0) {
      mensaje += '*COMBOS* %0A';
      for (const combo of json.detalleCombo) {
        mensaje += `  *${combo.cantidad}x ${combo.nombre}*  \$${(combo.precioUnitario * combo.cantidad).toFixed(2)}%0A`;
        for (const prod of combo.productos) {
          mensaje +=
            `     - *${prod.cantidad}x ${prod.nombre}* _Tamaño:_ ${prod.nombreTamano} %0A`;
          if (prod.ingredientes && prod.ingredientes.length > 0) {
            mensaje += `       _Con los ingredientes:_ %0A`;
            for (const extra of prod.ingredientes) {
              mensaje += `        - ${extra.nombre}%0A`;
            }
          }
          if (prod.extras && prod.extras.length > 0) {
            mensaje += `      _Extras:_ %0A`;
            for (const extra of prod.extras) {
              mensaje += `        - ${extra.nombre}  +\$${extra.precio.toFixed(2)} %0A`;
            }
          }
          if (prod.comentario && prod.comentario.trim() != '')
            mensaje += `       _*${prod.comentario}_%0A`;
        }
        if (combo.comentario && combo.comentario.trim() != '')
          mensaje += `_*${combo.comentario}_%0A`;
        mensaje += `%0A%0A`;
      }
    }
    mensaje += `*Comentarios:* ${json.comentarios}%0A`
    mensaje += `*Subtotal:* \$${json.costoPedido.toFixed(2)}%0A`
    if (json.tipoEnvio == 1)
      mensaje += `*Costo de envío:* \$${json.costoEnvio.toFixed(2)}%0A`
    mensaje += `*Total:* \$${json.costoTotal.toFixed(2)}%0A`
    // if (json.tipoEnvio == 1) {
    if (json.tipoPago == "Efectivo") {
      if (json.pagoCon)
        mensaje += `*Pagaré con:* \$${json.pagoCon.toFixed(2)}%0A`
      else
        mensaje += `*Pagaré con:* ${json.pagoCon}%0A`
    } else if (json.tipoPago == "DebitCreditCard") {
      mensaje += `*Pagado con:* Tarjeta de Crédito / Débito. %0A`
      mensaje += `*Referencia de pago:* ${json.billPocketTicketURL} %0A`
    } else
      mensaje += `*Pagaré con:* Tarjeta en domicilio %0A %0A`
    mensaje += `Revisa el estatus de tu pedido en: %0A`;
    mensaje += `${environment.estatusPedido}/home/estatus-pedido/${json.idTienda}-${json.idSucursal}-${json.id}`
    return mensaje;
  }

  sendWhatsapp(numero, json, direccionSucursal) {
    let mensaje = this.createMessage(json, direccionSucursal);
    return 'https://api.whatsapp.com/send?phone=+52' + numero + '&text=' + mensaje;
    // let myWindow:Window = window.open('https://api.whatsapp.com/send?phone=+52'+numero+'&text='+mensaje, "_blank");
    // this.popupBlockerChecker.check(myWindow);
  }

  public popupBlockerChecker = {
    check: function (popup_window) {
      return new Promise<void>((res, rej) => {
        var scope = this;
        if (popup_window) {
          if (/chrome/.test(navigator.userAgent.toLowerCase())) {
            setTimeout(function () {
              if (scope.is_popup_blocked(scope, popup_window))
                rej();
              else
                res();
            }, 200);
          } else {
            popup_window.onload = function () {
              if (scope.is_popup_blocked(scope, popup_window))
                rej();
              else
                res();
            };
          }
        } else {
          rej();
        }
      });
    },
    is_popup_blocked: function (scope, popup_window) {
      if ((popup_window.innerHeight > 0) == false)
        return true;
      else
        return false;
    },
  };

  displayError() {
    Swal.fire({
      icon: 'info',
      text: 'La orden de tu pedido ha sido bloqueada.'
    }).then((dat) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
      })
      Toast.fire({
        icon: 'info',
        title: 'Desbloquea tu pedido aqui arriba !'
      })
    });
  }

}

