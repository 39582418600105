import { Extra } from './Extra';
import { ProductoCarrito } from './ProductoCarrito';

export class Combo {

    idCombo: number;
    cantidad: number;
    precioUnitario: number;
    subTotal: number;
    nombre: string;
    comentario: string;
    productos: ProductoCarrito[];
    // extras: Extra[];
    //VARIABLES QUE NO SON DEL EO
    imagen: string;

    constructor() { }

}