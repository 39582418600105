<div class="customWrapper landingScrollBar">
    <!-- CABECERA -->
    <section class="row" id="cabecera">
        <div class="col s12">
            <a style="margin-left: 1em; margin-top: 1em;" routerLink="/home">
                <img style="height: 12vh; width: auto;" src="assets/img/logo-aqui-pido.png">
            </a>
        </div>
        <div id="aquipideText" class="col s12 m6 l8 centerAlign-vertical fullHeight">
            <div class="center fullWidth">
                <h2>AQUÍ PIDE</h2>
                <p style="padding-top: 16px; font-size: 1.2em;">¡Su tienda online para recibir
                    pedidos por Whatsapp!
                </p>
                <p style="padding-bottom: 16px; font-size: 1.2em;">Registro completamente Gratis
                </p>
                <a [routerLink]="['/registro']" routerLinkActive="router-link-active" class="waves-effect btn btn-large"><i
                        class="material-icons left">flag</i>Registrarme gratis</a>
            </div>
        </div>
    </section>
    <!-- FIN CABECERA -->

    <!-- POR QUÉ AQUÍ PIDE -->
    <section class="row center aquiPideWhy">
        <div class="col s12">
            <h3 style="padding-bottom: 16px;">¿Por qué tener su tienda en Aquí Pide?</h3>
        </div>
        <ul>
            <li class="col s12">
                <p>
                    <i class="material-icons">insert_emoticon</i> Creemos que sus clientes tienen que amar buscar y
                    pedir
                    por su tienda.
                </p>
            </li>
            <li class="col s12">
                <p>
                    <i class="material-icons">access_time</i> Creemos que tiene que tener su tienda de forma sencilla de
                    la noche a la mañana.
                </p>
            </li>
            <li class="col s12">
                <p>
                    <i class="material-icons">attach_money</i> Creemos que no tiene que pagar altas comisiones de entre
                    el 20% y 30% para vender por una app.
                </p>
            </li>
            <li class="col s12">
                <p style="font-weight: bold;">
                    <i class="material-icons">beenhere</i>Gánele al COVID-19: Seis meses de uso sin ningún pago, y
                    después
                    pague un económico abono mensual fijo
                    sin
                    comisión por venta. (en caso de requerir capacitación y ayuda para subir su menú aplicará un único
                    pago
                    de
                    $550.00 MXN más IVA)
                </p>
            </li>
        </ul>
        <div class="row">
            <div class="col s12 m6 l3" style="padding-top: 16px;">
                <a href="/como-funciona" class="waves-effect btn"><i class="material-icons left">remove_red_eye</i>Ver
                    como funciona</a>
            </div>
            <div class="col s12 m6 l3" style="padding-top: 16px;">
                <a class="waves-effect btn" href="/tacosaquipidedemo"><i class="material-icons left">stars</i>Ver
                    tienda DEMO</a>
            </div>
            <div class="col s12 m6 l3" style="padding-top: 16px;">
                <a href="/saber-mas" class="waves-effect btn btn-"><i class="material-icons left">info</i>Saber
                    más</a>
            </div>
            <div class="col s12 m6 l3" style="padding-top: 16px;">
                <a target="_blank"
                    href="https://api.whatsapp.com/send?phone=+526681584547&text=¡Hola!, me gustaría obtener más información acerca de aquipide.com."
                    class="waves-effect btn btn-"><img class="whatsappImg"
                        src="assets/img/white-whatsapp.png">&nbsp;&nbsp;&nbsp;Contáctanos</a>
            </div>
        </div>
    </section>
    <!-- FIN POR QUÉ AQUÍ PIDE -->

    <!-- PLANES COMERCIALES -->
    <section class="row planesComerciales">
        <div class="col s12 center" style="padding-top: 1em; padding-bottom: 1.5em;">
            <h2>Planes Comerciales</h2>
            <h3>Sin contrato o permanencia mínima</h3>
            <h3>Pago con tarjeta Visa, Master Card o PayPal</h3>
            <h3>Seis meses GRATIS, nos interesa apoyar a Todos</h3>
        </div>

        <div class="row planes">
            <div class="col s12 l3 planWrapper">
                <div class="plan hvr-float">
                    <h4>INICIAL</h4>
                    <h3>$500.00 / mes</h3>
                    <div class="divider"></div>
                    <ul>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Menú o catálogo de productos.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> 150 pedidos mensuales
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> 1 sucursales ó WhatsApps de destino.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col s12 l3 planWrapper">
                <div class="plan hvr-float popular">
                    <div class="popularText">
                        POPULAR
                    </div>
                    <h4>ESTÁNDAR</h4>
                    <h3>$650.00 / mes</h3>
                    <div class="divider"></div>
                    <ul>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Menú o catálogo de productos.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> 500 pedidos mensuales
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> 2 sucursales ó WhatsApps de destino.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Opciones avanzadas de armado de producto.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Zonas de domicilios.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col s12 l3 planWrapper">
                <div class="plan hvr-float">
                    <h4>AVANZADO</h4>
                    <h3>$800.00 / mes</h3>
                    <div class="divider"></div>
                    <ul>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Menú o catálogo de productos.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> 1,000 pedidos mensuales
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> 3 sucursales ó WhatsApps de destino.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Opciones avanzadas de armado de producto.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Zonas de domicilios.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col s12 l3 planWrapper">
                <div class="plan hvr-float">
                    <h4>EMPRESARIAL</h4>
                    <h3>$950.00 / mes</h3>
                    <div class="divider"></div>
                    <ul>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Menú o catálogo de productos.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> 3,000 pedidos mensuales
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> 4 sucursales ó WhatsApps de destino.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Opciones avanzadas de armado de producto.
                            </p>
                        </li>
                        <li class="col s12">
                            <p>
                                <i class="material-icons">check_circle</i> Zonas de domicilios.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col s12" style="text-align: right;">
                <i>* más IVA en su caso</i>
            </div>
        </div>
    </section>
    <!-- FIN PLANES COMERCIALES -->

    <!-- NUESTROS CLIENTES -->
    <section *ngIf="tiendas!=null && tiendas.length>0" class="row nuestrosClientes center" style="height: 50vh;">
        <h3>¡Únete a los negocios que ya nos eligieron!
        </h3>
        <div style="height: 16px;"></div>
        <swiper class="swiper-container" [config]="config" #ourStoresSwiper>
            <ng-container *ngFor="let tienda of tiendas;" class="swiper-slide cursorPointer"
                (click)="goToMenu(tienda.urlPersonalizada)">
                <ng-template swiperSlide>
                    <img class="imgRestaurante" src="{{imgUrl + tienda.logoTienda}}" alt="{{tienda?.nombreComercial}}">
                    <p>{{tienda?.nombreComercial}}</p>
                </ng-template>
            </ng-container>
        </swiper>
    </section>
    <!-- FIN NUESTROS CLIENTES -->

    <!-- NUESTROS CLIENTES -->
</div>