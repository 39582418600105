<!-- Main wrapper -->
<div class="wrapper" id="wrapper">
    <!-- Start Header Area -->
    <header class="htc__header bg--white">
        <!-- Start Mainmenu Area -->
        <div class="mainmenu__wrap sticky__header is-sticky">
            <div class="container">
                <div class="row" style="margin-bottom: 0;">
                    <div class="col s2 nav-item-wrapper">
                        <a (click)="smartNavigate()">
                            <img class="logo-navbar cursorPointer hvr-bounce-out" width="100"
                                src="assets/img/logo-aqui-pido.png">
                        </a>
                    </div>
                    <div class="col s8 nav-item-wrapper hide-on-med-and-down">
                        <ul class="nav-options centerAlign-all">
                            <ng-container *ngIf="routingService.currentShowOptionMenu | async">
                                <li class="nav-option hvr-sink" routerLink="home/restaurantes">Restaurantes</li>
                            </ng-container>
                            <li routerLink="/pricing" class="nav-option hvr-sink">¿Tienes un negocio? Vende GRATIS</li>
                            <ng-container *ngIf="routingService.currentShowOptionMenu | async">
                                <li class="nav-option hvr-sink" routerLink="/registro">Afiliarme</li>
                            </ng-container>
                        </ul>
                    </div>

                    <!-- ICONOS DE USUARIO Y BASKET QUE SE MUESTRA EN LA PANTALLA GRANDE -->
                    <div class="col s8 l2 right-align nav-item-wrapper hide-on-med-and-down">
                        <ul class="nav-options" style="justify-content: flex-end;">
                            <li class="nav-option hvr-sink minicart-favoritos-trigger">
                                <div class="centerAlign-all">
                                    <div>
                                        <i class="material-icons icon-option">favorite</i>
                                    </div>
                                </div>
                                <div class="ShoppingBasket">Favoritos</div>
                            </li>
                            <li>
                                <p>|</p>
                            </li>
                            <li class="nav-option hvr-sink minicart-trigger">
                                <div class="centerAlign-all">
                                    <div>
                                        <i class="material-icons icon-option">shopping_basket</i>
                                        <span style="background-color: transparent !important;"
                                            [ngClass]="{red: itemsInCarrito>0}" class=" customBadge aqui-pido-red">
                                            {{itemsInCarrito<=99?itemsInCarrito:'+99'}} </span>
                                    </div>
                                </div>
                                <div class="ShoppingBasket">Mi pedido</div>
                            </li>
                        </ul>
                    </div>

                    <!-- ICONOS DE USUARIO Y BASKET QUE SE MUESTRAN EN LA PANTALLA CHICA -->
                    <div class="col s8 l2 right-align nav-item-wrapper show-on-med-and-down hide-on-large-only">
                        <ul class="nav-options centerAlign-all" style="width: 100;">
                            <li class="nav-option hvr-sink minicart-favoritos-trigger">
                                <div class="centerAlign-all">
                                    <div>
                                        <i class="material-icons icon-option">favorite</i>
                                    </div>
                                </div>
                                <div class="ShoppingBasket">Favoritos</div>
                            </li>
                            <li>
                                <p>|</p>
                            </li>
                            <li class="nav-option hvr-sink minicart-trigger">
                                <div class="centerAlign-all">
                                    <div>
                                        <i class="material-icons icon-option">shopping_basket</i>
                                        <span style="background-color: transparent !important;"
                                            [ngClass]="{red: itemsInCarrito>0}" class=" customBadge aqui-pido-red">
                                            {{itemsInCarrito<=99?itemsInCarrito:'+99'}} </span>
                                    </div>
                                </div>
                                <div class="ShoppingBasket">Mi pedido</div>
                            </li>
                        </ul>
                    </div>

                    <div class="col s2 right-align nav-item-wrapper show-on-med-and-down hide-on-large-only">
                        <ul class="nav-options" style="justify-content: flex-end;">
                            <a data-target="mobile-demo" class="sidenav-trigger cursorPointer"><i
                                    class="material-icons customIcon">menu</i></a>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </header>

    <!-- CARRITO DE COMPRAS -->
    <app-carrito (onHacerPedidoPressed)="cerrarCarrito()"></app-carrito>
    <!-- CARRITO DE COMPRAS -->

    <!-- MIS FAVORITOS -->
    <app-favorito></app-favorito>
    <!-- MIS FAVORITOS -->

</div>

<!-- OPCIONES DEL SIDEBAR -->
<ul class="sidenav" id="mobile-demo">
    <ng-container *ngIf="routingService.currentShowOptionMenu | async">
        <li><a routerLink="home/restaurantes"><i class="material-icons">food_bank</i>Restaurantes</a></li>
    </ng-container>
    <li><a class="sideNavOption" routerLink="/pricing"><i class="material-icons">error_outline</i>¿Tienes un negocio?
            Vende
            GRATIS</a></li>
    <ng-container *ngIf="routingService.currentShowOptionMenu | async">
        <li><a routerLink="registro"><i class="material-icons">business_center</i>Afiliarme</a></li>
    </ng-container>
</ul>