import { Component, AfterViewInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'kt-generic-loading',
  templateUrl: './generic-loading.component.html',
  styleUrls: ['./generic-loading.component.scss']
})
export class GenericLoadingComponent implements AfterViewInit {

  isLoading: boolean;
  constructor(public loadingService: LoadingService) {
  }

  ngAfterViewInit(): void {

  }


}
