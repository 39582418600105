
export class Tienda {
    idTienda: number;
    idPais: number;
    nombreComercial: string;
    rfc: string;
    etiquetas: any;
    telefono: string;
    correo: string;
    urlPersonalizada: string;
    nombreAdministrador: string;
    telefonoAdministrador: string;
    correoAdministrador: string;
    contrasena: string;
    logoTienda: string;
    pagoEfectivo: boolean;
    pagoTPV: boolean;
    giroComercial: number;
    facebook: string;
    twitter: string;
    instagram: string;
    estatus: boolean;

    clearModel() {
        this.idTienda = null
        this.idPais = null
        this.nombreComercial = ''
        this.rfc = ''
        this.telefono = ''
        this.correo = ''
        this.urlPersonalizada = ''
        this.nombreAdministrador = ''
        this.telefonoAdministrador = ''
        this.correoAdministrador = ''
        this.contrasena = ''
        this.logoTienda = ''
        this.pagoEfectivo = false
        this.pagoTPV = false
        this.giroComercial = null
        this.facebook = ''
        this.twitter = ''
        this.instagram = ''
        this.etiquetas = []
        this.estatus = false
    }

    fillModel(data: any) {
        this.idTienda = data["idTienda"];
        this.idPais = data["idPais"];
        this.nombreComercial = data["nombreComercial"];
        this.rfc = data["rfc"];
        this.telefono = data["telefono"];
        this.etiquetas = data['etiquetas'];
        this.correo = data["correo"];
        this.urlPersonalizada = data["urlPersonalizada"];
        this.nombreAdministrador = data["nombreAdministrador"];
        this.telefonoAdministrador = data["telefonoAdministrador"];
        this.correoAdministrador = data["correoAdministrador"];
        this.contrasena = data["contrasena"];
        this.logoTienda = data["logoTienda"];
        this.pagoEfectivo = data["pagoEfectivo"];
        this.pagoTPV = data["pagoTPV"];
        this.giroComercial = data["giroComercial"];
        this.facebook = data["facebook"];
        this.twitter = data["twitter"];
        this.instagram = data["instagram"];
        this.estatus = data["estatus"];
    }

}