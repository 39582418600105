import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {
  versionCode = environment.version;

  constructor() { }

  ngAfterViewInit(): void {
    $(document).ready(function () {
      $('.modal').modal();
      $('.modal-close').on('click tap', () => {
        $('.modal').modal('close');
      });
    });
  }

  ngOnInit() {
  }

}
