import { Extra } from './Extra';

export class ProductoCarrito {

    idProducto: number;
    cantidad: number;
    nombre: string;
    idTamano: number;
    nombreTamano: string;
    precioUnitario: number;
    subTotal: number;
    comentario: string;
    extras: Extra[];
    ingredientes: any; //ESTO NO CORRESPONDE A LA BD ES SÓLO DE AYUDA
    imagen: string; //ESTO NO CORRESPONDE A LA BD ES SÓLO DE AYUDA


    constructor() { }
}