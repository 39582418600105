
export class Favorito {
    tienda: any;
    producto: any;

    constructor(producto: any, tienda: any) {
        this.tienda = tienda;
        this.producto = producto;
    }

}