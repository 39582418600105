import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saber-mas',
  templateUrl: './saber-mas.component.html',
  styleUrls: ['./saber-mas.component.scss']
})
export class SaberMasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
