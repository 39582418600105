<app-subheader></app-subheader>
<app-categories></app-categories>

<!-- POR QUÉ AQUÍ PIDE -->
<section class="row center aquiPideWhy aquipidebg" style="margin-bottom: 0;">
    <div class="col s12">
        <h3 style="padding-bottom: 16px;">¿Por qué tener su tienda en Aquí Pide?</h3>
    </div>
    <ul>
        <li class="col s12">
            <p>
                <i class="material-icons">insert_emoticon</i> Creemos que sus clientes tienen que amar buscar y
                pedir
                por su tienda.
            </p>
        </li>
        <li class="col s12">
            <p>
                <i class="material-icons">access_time</i> Creemos que tiene que tener su tienda de forma sencilla de
                la noche a la mañana.
            </p>
        </li>
        <li class="col s12">
            <p>
                <i class="material-icons">attach_money</i> Creemos que no tiene que pagar altas comisiones de entre
                el 20% y 30% para vender por una app.
            </p>
        </li>
        <li class="col s12">
            <p style="font-weight: bold;">
                <i class="material-icons">beenhere</i>Gánele al COVID-19: Seis meses de uso sin ningún pago, y
                después
                pague un económico abono mensual fijo
                sin
                comisión por venta. (en caso de requerir capacitación y ayuda para subir su menú aplicará un único
                pago
                de
                $550.00 MXN más IVA)
            </p>
        </li>
    </ul>
    <div class="row">
        <div class="col s12 m6 l3" style="padding-top: 16px;">
            <a href="/como-funciona" class="waves-effect btn"><i class="material-icons left">remove_red_eye</i>Ver
                como funciona</a>
        </div>
        <div class="col s12 m6 l3" style="padding-top: 16px;">
            <a class="waves-effect btn" href="/tacosaquipidedemo"><i class="material-icons left">stars</i>Ver
                tienda DEMO</a>
        </div>
        <div class="col s12 m6 l3" style="padding-top: 16px;">
            <a href="/saber-mas" class="waves-effect btn btn-"><i class="material-icons left">info</i>Saber
                más</a>
        </div>
        <div class="col s12 m6 l3" style="padding-top: 16px;">
            <a target="_blank"
                href="https://api.whatsapp.com/send?phone=+526681584547&text=¡Hola!, me gustaría obtener más información acerca de aquipide.com."
                class="waves-effect btn btn-"><img class="whatsappImg"
                    src="assets/img/white-whatsapp.png">&nbsp;&nbsp;&nbsp;Contáctanos</a>
        </div>
    </div>
</section>
<!-- FIN POR QUÉ AQUÍ PIDE -->