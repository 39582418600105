<div class="favoritosbox-wrap">
    <div class="cartbox text-right">
        <button class="cartbox-close favoritos"><i class="zmdi zmdi-close"></i></button>
        <ng-container *ngIf="misFavoritos!=null && misFavoritos.length>0">
            <div class="cartbox__inner text-left">
                <h1 class="title">
                    <i class="material-icons">favorite</i>
                    &nbsp;TUS FAVORITOS&nbsp;
                    <i class="material-icons">favorite</i>
                </h1>
                <div class="cartbox__items extrasScrollBar">
                    <!-- Cartbox Single Item -->
                    <ng-container *ngFor="let favorito of misFavoritos; let i = index">
                        <div class="cartbox__item">
                            <div class="cartbox__item__thumb">
                                <a>
                                    <img style="border-radius: 8px;" src="{{imageUrl + favorito?.producto.imagen}}"
                                        alt="{{favorito?.producto.nombre}}">
                                </a>
                            </div>
                            <div class="cartbox__item__content">
                                <h5><a class="product-name">{{favorito.tienda.nombreComercial}}</a></h5>
                                <span class="price">{{favorito.producto.nombre}}</span>
                                <div><button (click)="pedirProducto(favorito, i)" class="pedido_btn">Pedirlo</button>
                                </div>
                            </div>
                            <button class="cartbox__item__remove" (click)="deleteProductoDeFavoritos(i)">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="misFavoritos==null || misFavoritos.length==0">
            <div class="centerAlign-all" style="height: 99%;">
                <i class="material-icons large ">favorite_border</i>
                <br>
                <span>No tienes favoritos aún.<br> Al seleccionar un producto presiona sobre el Corazón 🧡
                    para
                    guardarlo y acceder más rápidamente a ellos.</span>
            </div>
        </ng-container>
    </div>
</div><!-- //Cartbox -->