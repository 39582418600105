import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general-service.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  girosComerciales: any;
  @Input() showAs: string = 'grid';
  imgUrl = environment.imageUrlBase;
  tiendas: any[];
  constructor(private dataService: GeneralService, private router: Router) { }

  navigateToRestaurante(idGiroComercial: any) {
    this.router.navigate(['/home/restaurantes/', idGiroComercial]);
  }

  getGiroInTiendaCounter(idGiroComercial: any) {
    let total = 0;
    if (this.tiendas && this.tiendas.length > 0) {
      this.tiendas.forEach(tienda => {
        if (tienda.girocomercial == idGiroComercial) {
          total++;
        }
      });
    } else {
      total = 0;
    }
    return total;
  }

  getGirosComerciales() {
    this.dataService.postRequest("getGirosComerciales", {}).toPromise().then(data => {
      this.girosComerciales = data["data"];
      this.getTiendasConsumidor();
    })
      .catch()
      .finally();
  }

  getTiendasConsumidor() {
    this.dataService.postRequest("getTiendasConsumidor", {}).toPromise().then(data => {
      this.tiendas = data["data"];
      let i = 0;
      while (i < this.girosComerciales.length) {
        this.girosComerciales[i].showItem = (this.getGiroInTiendaCounter(this.girosComerciales[i].idGiroComercial) > 0 ? true : false);
        i++;
      }
    })
      .catch()
      .finally();
  }

  ngOnInit() {
    this.getGirosComerciales();
  }

}
