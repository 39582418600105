<div class="comoFuncionaWrapper">
    <section class="row aqui-pide-bg-red center">
        <h2 class="padding-16 pt-2em ">¡ Así funciona Aquí Pide !</h2>
    </section>
    <section class="row">
        <h4 class="center">Presente sus productos a sus clientes profesionalmente</h4>
        <div class="row"></div>
        <div class="row"></div>
        <div class="col s12 m6">
            <ul class="container">
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Agrupe sus productos por secciones.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Agregue imágenes para una presentación más atractiva.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Su cliente armará su pedido seleccionando productos
                        de forma interactiva.
                    </p>
                </li>
            </ul>
        </div>
        <div class="col s12 m6 center">
            <img class="comoFuncionaImg z-depth-3" src="assets/img/comoFunciona1.png">
        </div>
    </section>
    <section class="row aqui-pide-bg-red">
        <div class="row"></div>
        <h4 class="center">Su cliente controla su pedido</h4>
        <div class="row"></div>
        <div class="row"></div>
        <div class="col s12 m6">
            <ul class="container">
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Personalización de productos.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Selección de extras.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Rápido control de cada producto y el total.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Acceso en todo momento a su canasta de pedido.
                    </p>
                </li>
            </ul>
        </div>
        <div class="col s12 m6 center">
            <img class="comoFuncionaImg z-depth-3" src="assets/img/comoFunciona2.png">
        </div>
        <div class="row"></div>
        <div class="row"></div>
        <div class="row"></div>
    </section>
    <section class="row">
        <h4 class="center">El comprador completa sus datos y confirma su pedido</h4>
        <div class="row"></div>
        <div class="row"></div>
        <div class="col s12 m6">
            <ul class="container">
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Selección del servicio deseado.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Búsqueda de localidades.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Visualización del costo de envío correspondiente a su
                        localidad.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Selección de forma de pago.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Posibilidad de agregar un comentario libre.
                    </p>
                </li>
            </ul>
        </div>
        <div class="col s12 m6 center">
            <img class="comoFuncionaImg z-depth-3" src="assets/img/comoFunciona3.png">
        </div>
        <div class="row"></div>
        <div class="row"></div>
        <div class="row"></div>
    </section>
    <section class="row aqui-pide-bg-red">
        <div class="row"></div>
        <h4 class="center">Reciba un Whatsapp del pedido de su cliente</h4>
        <div class="row"></div>
        <div class="row"></div>
        <div class="col s12 m6">
            <ul class="container">
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> El pedido es claro y preciso para las ambas partes.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Reduzca la cantidad de interacciones y facilite el trabajo
                        del operador.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Responda rápidamente a su cliente y procese su venta.
                    </p>
                </li>
            </ul>
        </div>
        <div class="col s12 m6 center">
            <img class="comoFuncionaImg z-depth-3" src="assets/img/comoFunciona4.png">
        </div>
        <div class="row"></div>
        <div class="row"></div>
        <a routerLink="/pricing" class="waves-effect btn fabTopRight"><i class="material-icons left">home</i>Volver al
            inicio</a>
    </section>
</div>