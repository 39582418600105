import { Injectable } from '@angular/core';
import { fromEvent, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class ScrollingService {
  topPosToStartShowing = 100;
  destroy = new Subject();
  destroy$ = this.destroy.asObservable();
  showScrollUp = new BehaviorSubject<Boolean>(false);
  showScrollUp$ = this.showScrollUp.asObservable();

  constructor() {
    if (!this.validateisIosIphoneIpadAndroid()) {
      fromEvent(document.body.querySelector('div.fullScreen.bodyScrollBar'), 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
        this.checkScroll();
      });
    } else {
      fromEvent($('html, body div.fullScreen.bodyScrollBar'), 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
        this.checkScroll();
      });
    }
  }

  private validateisIosIphoneIpadAndroid() {
    //Método que retorna si la plataforma es iosIphoneIpadAndroid
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/) ? true : false;
  }

  private checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.querySelector('div.fullScreen.bodyScrollBar').scrollTop || 0;

    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.showScrollUp.next(true);
    } else {
      this.showScrollUp.next(false);
    }
  }

  scrollTop() {
    if (!this.validateisIosIphoneIpadAndroid()) {
      $('html, body div.fullScreen.bodyScrollBar').animate({ scrollTop: 0 }, 500);
    } else {
      setTimeout(function () {
        // Hide the address bar!
        $('html').scrollTop(0, 0);
      }, 500);
      $('html, body div.fullScreen.bodyScrollBar').animate({ scrollTop: 0 }, 500);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  //#region CATEGORIASSCROLLOBSERVER
  enabledSpy = new BehaviorSubject<boolean>(true);
  enabledSpy$ = this.enabledSpy.asObservable();
  //#endregion CATEGORIASSCROLLOBSERVER

  enableSpy(enable: boolean) {
    this.enabledSpy.next(enable);
  }

}
