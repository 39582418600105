import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { getDoc } from 'firebase/firestore';
import { GeneralService } from 'src/app/services/general-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-finalizar-pedido',
  templateUrl: './finalizar-pedido.component.html',
  styleUrls: ['./finalizar-pedido.component.scss']
})
export class FinalizarPedidoComponent implements OnInit {

  reason: number = 1;
  text = '';
  pedido: any = {};
  wrongCode: boolean = false;
  code: string='';
  docRef;
  options: any[] = []
  pedidoFinalized: boolean = false;


  constructor(private route: ActivatedRoute, private dataService: GeneralService, private router: Router) {
    const db = getFirestore();
    this.route.paramMap.subscribe(async params => {
      let [idTienda, idSucursal, idPedido, ...rest] = params.get('id').split("-");
      this.docRef = doc(db, idTienda, "sucursales", idSucursal, idPedido);
      const docSnap = await getDoc(this.docRef);
      if (docSnap.exists()) {
        this.pedido = docSnap.data();
        if(this.pedido.status == 4){
          this.pedidoFinalized = true;
          return;
        }
        let s = this.pedido.text.indexOf(':')
        this.text = '<div style="text-align:left !important;"> <b> Cliente:</b> ' + this.pedido.nombreCliente + " " + this.pedido.text.substring(s + 1) + '</div>';
        while (this.text.includes('%0A'))
          this.text = this.text.replace('%0A', '<br>');
        let boldOpen = false, cursiveOpen = false;
        for (let i = 0; i < this.text.length; i++) {
          if (this.text.charAt(i) == '*') {
            if (boldOpen)
              this.text = this.text.substring(0, i) + "</b>" + this.text.substring(i + 1);
            else
              this.text = this.text.substring(0, i) + "<b>" + this.text.substring(i + 1);
            boldOpen = !boldOpen;
          } else if (this.text.charAt(i) == '_') {
            if (cursiveOpen)
              this.text = this.text.substring(0, i) + "</i>" + this.text.substring(i + 1);
            else
              this.text = this.text.substring(0, i) + "<i>" + this.text.substring(i + 1);
            cursiveOpen = !cursiveOpen
          }
        }
      }
      else{
        //que hacer si no existe
      }
    });
    this.dataService.postRequest('getEstatusFinales', {}).toPromise().then((vals: any)=>{
      this.options = vals.data;
    });
  }

  validarCodigo(){
    // if(this.code == this.pedido.secureCode){
      this.dataService.postRequest('setNewEstatusPedido', {idPedido: this.pedido.id, estatus: 4, idRazon: this.reason}).toPromise().then(()=>{
        let textReason = '';
        switch(this.reason){
          case 1:
            textReason = 'Entregado'
            break;
          case 2:
            textReason = 'Cancelado'
            break;
          case 3:
            textReason = 'Domicilio no encontrado'
            break;
        }
        updateDoc(this.docRef, {status: 4, reason: textReason}).then(()=>{
          Swal.fire({
            title: 'PEDIDO ENTREGADO',
            icon: 'success'
          }).then(() => this.router.navigateByUrl('/'));
        });
      }).catch(
        (er)=> console.error(er)
      );
    // }else{
    //   this.wrongCode = true;
    //   Swal.fire({
    //     title: 'Codigo erroneo',
    //     icon: 'error'
    //   });
    // }
  }

  ngOnInit(): void {
  }

}
