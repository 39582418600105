import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { Favorito } from '../models/Favorito';

@Injectable({
  providedIn: 'root'
})
export class FavoritoService {
  private itemsInFavoritos = new BehaviorSubject<number>(0);
  currentItemsInFavoritos = this.itemsInFavoritos.asObservable();


  private favoritos = new BehaviorSubject<any>(null);
  currentFavoritos = this.favoritos.asObservable();
  private encodePass = "AQUIPIDE";
  private decodePass = "AQUIPIDE";
  private KEY_MISFAVS = "misFavoritos";
  constructor() { }


  //#region Métodos para observables
  setMisFavoritos(misFavoritos: Favorito[]) {
    localStorage.setItem(this.KEY_MISFAVS, CryptoJS.AES.encrypt(JSON.stringify(misFavoritos), this.encodePass).toString());
    this.itemsInFavoritos.next(misFavoritos == null ? 0 : misFavoritos.length);
    this.favoritos.next(misFavoritos);
  }

  //#endregion

  //REGRESA ARREGLO VACIO SI NO HAY ITEMS EN EL CARRITO
  getCurrentFavoritos() {
    let misFavoritosLS: any;
    misFavoritosLS = localStorage.getItem(this.KEY_MISFAVS);//Se obtiene el carrito
    //Si el carrito es null se le asigna null, si no, se desencripta la key
    misFavoritosLS = (
      (misFavoritosLS == null || misFavoritosLS == 'null')
        ? null
        : CryptoJS.AES.decrypt(misFavoritosLS, this.decodePass).toString(CryptoJS.enc.Utf8));
    let misFavoritos = ((misFavoritosLS != null) ? JSON.parse(misFavoritosLS) : []);
    this.setMisFavoritos(misFavoritos);
    return misFavoritos;
  }

  addItemToMisFavoritos(favorito: Favorito): boolean {
    let favoritos = this.getCurrentFavoritos();
    let keyToSearch: string = (favorito.producto["idProducto"] !== undefined ? "idProducto" : "idCombo")
    let existia = false;
    for (let i = 0; i < favoritos.length; i++) {
      let miFavorito: Favorito = favoritos[i];
      if (miFavorito.producto[keyToSearch] == favorito.producto[keyToSearch]) {
        favoritos[i] = favorito;
        existia = true;
        break;
      }
    }
    if (!existia) { favoritos.push(favorito); }
    this.setMisFavoritos(favoritos);
    return true;
  }

  destroyMisFavoritos() {
    localStorage.removeItem(this.KEY_MISFAVS);
    return this.getCurrentFavoritos();
  }

  deleteItemInFavoritos(index: number) {
    let favoritos = [];
    favoritos = this.getCurrentFavoritos();
    if (favoritos) {
      if (favoritos.length > 0) {
        favoritos.splice(index, 1);
        if (favoritos.length == 0) {
          this.destroyMisFavoritos();
        }
      }
    }
    this.setMisFavoritos(favoritos);
  }

}
