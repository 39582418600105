import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SwiperModule } from 'swiper/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [],
  imports: [
    MatChipsModule,
    CommonModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatInputModule,
    MatTabsModule,
    MatExpansionModule,
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialFileInputModule,
    MatTooltipModule,
    SwiperModule,
    MatDatepickerModule,
  ],
  exports: [
    MatChipsModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatInputModule,
    MatTabsModule,
    MatExpansionModule,
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialFileInputModule,
    MatExpansionModule,
    MatTooltipModule,
    SwiperModule,
    MatDatepickerModule,
  ],
  providers: [
  ]
})
export class SharedModule { }
