import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import SwiperCore, { A11y, Autoplay, Keyboard, Mousewheel, SwiperOptions } from 'swiper';
import { GeneralService } from 'src/app/services/general-service.service';
import { environment } from 'src/environments/environment';

SwiperCore.use([Keyboard, Mousewheel, Autoplay, A11y]);
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingComponent implements OnInit {
  public tiendas = [];
  imgUrl = environment.imageUrlBase;
  versionCode = environment.version;
  public config: SwiperOptions = {
    slidesPerView: this.getSlidesByScreen(),
    keyboard: true,
    mousewheel: true,
    width: window.screen.width,
    autoplay: true,
    centeredSlides: true,
  };

  constructor(private dataService: GeneralService, private router: Router) { }

  getSlidesByScreen(): number {
    let slides = 0;
    if (window.screen.width < 600) {
      slides = 1;
    } else if (window.screen.width >= 600 && window.screen.width < 992) {
      slides = 1;
    } else if (window.screen.width >= 992) {
      slides = 2;
    }
    return slides;
  }

  goToMenu(urlPersonalizada: any) {
    this.router.navigate(['/', urlPersonalizada]);
  }

  getTiendasConsumidor() {
    this.dataService.postRequest("getTiendasConsumidor", {}).toPromise().then(data => {
      this.tiendas = data["data"];
    });
  }

  ngOnInit(): void {
    this.getTiendasConsumidor();
  }

}
