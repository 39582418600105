<kt-generic-loading></kt-generic-loading>
<section class="food__grid__sidebar section-padding--lg bg--white">
    <div style="margin-right: 10%; margin-left: 10%;">
        <div class="row">
            <!-- Start Sidebar -->
            <div class="col l4 m12 s12">
                <div class="food__sidebar">
                    <div class="food__category__area mt--60">
                        <h4 class="side__title">filtra por tipos de restaurates</h4>
                        <ul class="food__category">
                            <li class="cursorPointer" (click)="filterTiendasByGiroComercial(null)">
                                <a>Todos<span>({{(tiendas)?tiendas.length:0}})</span></a>
                            </li>
                            <ng-container *ngFor="let giroComercial of girosComerciales;">
                                <li class="cursorPointer"
                                    *ngIf="getGiroInTiendaCounter(giroComercial.idGiroComercial)>0"
                                    (click)="filterTiendasByGiroComercial(giroComercial.idGiroComercial)">
                                    <a>{{giroComercial.descripcion}}<span>({{getGiroInTiendaCounter(giroComercial.idGiroComercial)}})</span></a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- End Sidebar -->
            <div class="col l8 m12 s12 mt--60">
                <div class="food__blog__grid__left__sidebar">
                    <!-- Start Single Grid -->
                    <div class="row mt__-40">
                        <!-- Start Single Grid -->
                        <ng-container *ngFor="let tienda of filteredTiendas;">
                            <div class="col l6 m6 s12" (click)="goToMenu(tienda.urlPersonalizada)">
                                <div class="single__blog__grid foo z-depth-2">
                                    <div class="grid__thumb">
                                        <a class="centerAlign-all cursorPointer">
                                            <img class="imgRestaurante" src="{{imgUrl + tienda.logoTienda}}"
                                                alt="{{tienda?.nombreComercial}}">
                                        </a>
                                    </div>
                                    <div class="grid__inner" style="text-align: center; padding-top: 8px;">
                                        <h2><a class="cursorPointer">{{tienda?.nombreComercial}}</a></h2>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="filteredTiendas ==null || filteredTiendas.length==0">
                            <br><br>
                            <h5>Lo sentimos, no se econtraron restaurantes para la categoría seleccionada.</h5>
                        </ng-container>
                        <!-- End Single Grid -->
                    </div>
                    <!-- End Single Blog Grid -->
                </div>
            </div>
        </div>
    </div>
</section>