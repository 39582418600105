<kt-generic-loading></kt-generic-loading>

<div class="cartbox-wrap">
    <div class="cartbox text-right">
        <button class="cartbox-close"><i class="zmdi zmdi-close"></i></button>
        <ng-container *ngIf="carritoCompras!=null && carritoCompras.length>0">
            <div class="cartbox__inner text-left">
                <div class="cartbox__items extrasScrollBar">
                    <!-- Cartbox Single Item -->

                    <ng-container *ngFor="let producto of carritoCompras; let i = index">
                        <div class="cartbox__item">
                            <div class="cartbox__item__thumb">
                                <a>
                                    <img style="border-radius: 8px;" src="{{imageUrl + producto?.imagen}}"
                                        alt="{{producto.nombre}}">
                                </a>
                            </div>
                            <div class="cartbox__item__content">
                                <h5><a class="product-name">x{{producto.cantidad}}&nbsp;{{producto.nombre}}</a></h5>
                                <span class="price">{{producto.subTotal | currency:"MXN":"symbol"}}</span>
                            </div>
                            <button class="cartbox__item__remove" (click)="deleteProductoEnCarrito(i)">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </ng-container>
                    <!-- //Cartbox Single Item -->
                </div>
                <div>
                    <div class="cartbox__total">
                        <ul>
                            <li><span class="cartbox__total__title">Subtotal</span><span
                                    class="price">{{getTotal() | currency:"MXN":"symbol"}}</span></li>
                            <!-- <li class="shipping-charge"><span class="cartbox__total__title">Shipping Charge</span><span
                                    class="price">$05</span></li> -->
                            <li class="grandtotal">Total<span
                                    class="price">{{getTotal() | currency:"MXN":"symbol"}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="cartbox__buttons">
                        <!-- <a class="food__btn" href="cart.html"><span>View cart</span></a> -->
                        <a class="food__btn cursorPointer" (click)="navigateToPedido()"><span>Hacer pedido</span></a>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="carritoCompras==null || carritoCompras.length==0">
            <div class="centerAlign-all" style="height: 99%;">
                <i class="material-icons large ">remove_shopping_cart</i>
                <br>
                <span>Sin productos en tu carrito.</span>
            </div>
        </ng-container>
    </div>
</div><!-- //Cartbox -->