import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/services/general-service.service';
import { environment } from 'src/environments/environment';
import { MatRadioChange } from '@angular/material/radio';
import { CarritoService } from 'src/app/services/carrito.service';
import { ProductoCarrito } from 'src/app/models/ProductoCarrito';
import * as M from 'materialize-css';
import { Combo } from 'src/app/models/Combo';
import { Lightbox, IAlbum, LightboxEvent, IEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { MatTabGroup } from '@angular/material/tabs';
import { ScrollingService } from 'src/app/services/scrolling.service';
import Swal from 'sweetalert2';
import { Extra } from 'src/app/models/Extra';
import { Favorito } from 'src/app/models/Favorito';
import { FavoritoService } from 'src/app/services/favorito.service';
declare var $: any;

@Component({
  selector: 'app-tienda-menu',
  templateUrl: './tienda-menu.component.html',
  styleUrls: ['./tienda-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TiendaMenuComponent implements OnInit, AfterViewInit {
  idTienda: any;
  tienda: any;
  urlImg = environment.imageUrlBase;
  logoTienda: string;
  productosCombos: any;
  categorias: any;
  productoSeleccionado: any;
  tamanoSeleccionado: any = {
    id: null,
    precio: null
  };
  ingredientesSeleccionados = [];
  showMoreExtras = false;

  //#region Variables de combos
  combos: any;
  showCombos: boolean = false;
  comboSeleccionado: any;
  ingredientesProductosCombo: any[];
  tabSelected: any = 0;
  urlPersonalizada: any;
  //#endregion
  private imagen: Array<IAlbum> = [];
  private _subscription: Subscription;
  productosByCategorias: any;
  categoriaIndex: number;
  currentSection = '';
  @ViewChild('categoriaTabs') categoriaTabs: MatTabGroup;
  sucursales: any;
  favorito: Favorito;
  sucursalesLoaded: boolean = false;
  productosLoaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private dataService: GeneralService,
    private carritoService: CarritoService,
    private favoritosService: FavoritoService,
    private _lightBox: Lightbox,
    private _lightboxEvent: LightboxEvent,
    private scrollService: ScrollingService) {
    this.urlPersonalizada = this.route.snapshot.params["urlPersonalizada"];
    const album: IAlbum = {
      src: null,
      caption: null,
      thumb: null,
    };
    this.imagen.push(album);
  }

  arrayIngredientes(length: number) {
    return new Array(length);
  }

  //#region SCROLLSPY

  onSectionChange(sectionId: string) {
    this.productosByCategorias.forEach((categoria, index) => {
      if (sectionId == "categoria" + categoria.idCategoria) {
        this.categoriaTabs.selectedIndex = index;
      }
    });
    this.currentSection = sectionId;
  }

  scrollTo(index: number) {
    this.scrollService.enableSpy(false);
    document.body.querySelector('#categoria' + this.productosByCategorias[index].idCategoria).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    setTimeout(() => {
      this.scrollService.enableSpy(true);
    }, 700);
  }
  //#endregion SCROLLSPY

  //#region LIGHTBOX
  openImgPopup(producto: any) {
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event: IEvent) => this._onReceivedEvent(event));

    const album: IAlbum = {
      src: this.urlImg + producto.imagen,
      caption: 'Presiona por fuera para cerrar',
      thumb: this.urlImg + producto.imagen,
    };
    this.imagen[0] = album;
    this._lightBox.close();
    this._lightBox.open(this.imagen, 0, {
      wrapAround: true,
      showImageNumberLabel: false,
      disableScrolling: true,
      showZoom: false,
      showRotate: false,
      centerVertically: true,
    });
  }

  private _onReceivedEvent(event: IEvent): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }
  }
  //#endregion LIGHTBOX

  //#region COMBOS
  displayCombos() {
    this.showCombos = true;
  }

  onAddToCartComboPressed(combo: any) {
    if (this.sucursales == null || this.sucursales.length == 0) {
      return;
    }
    if (this.comboSeleccionado != combo) {
      //Si el actual combo seleccionado por el usuario 
      //no es el mismo que el seleccionado previo al actual se borra la lista de extras
      //además de los ingredientes seleccionados
      this.comboSeleccionado = combo;
      this.showMoreExtras = false;
      this.ingredientesProductosCombo = [];
      this.comboSeleccionado.cantidad = 1;
      this.comboSeleccionado.comentario = '';
      this.comboSeleccionado.favorito = false;
      let j = 0;
      this.comboSeleccionado.productos.forEach((producto, index) => {
        let i = 0;
        this.ingredientesProductosCombo.push([]);
        while (i < (producto[0].numeroExtrasIngredientes <= 5 ? producto[0].numeroExtrasIngredientes : 5)) {
          this.ingredientesProductosCombo[j][i] = null;
          i++;
        }
        j++;
        if (producto[0].extras) {
          for (let i = 0; i < producto[0].extras.length; i++) {
            this.comboSeleccionado.productos[index][0].extras[i].cantidad = 0;
          }
        }
      });

    }
    $('#modalCombo').modal('open');
    $('#modalCombo .extrasScrollBar').animate({ scrollTop: 0 }, 0);

  }

  calcularTotalCombo() {
    let total: number = 0;
    if (this.comboSeleccionado) {
      total += Number(this.comboSeleccionado.precio) * this.comboSeleccionado.cantidad;
      this.comboSeleccionado.productos.forEach(productoCombo => {
        if (productoCombo[0].extras) {
          productoCombo[0].extras.forEach(extra => {
            if (extra.precios && extra.cantidad > 0) {
              total += extra.precios[0].precio * extra.cantidad;
            }
          });
        }
      });
    }
    return total;
  }

  addComboToCarrito(idModal: string) {
    if (!this.validateProductosIngredientes('combo')) {
      return;
    }
    let combo = new Combo();
    combo.idCombo = this.comboSeleccionado.idCombo;
    combo.cantidad = this.comboSeleccionado.cantidad;
    combo.nombre = this.comboSeleccionado.nombre;
    combo.precioUnitario = Number(this.comboSeleccionado.precio);
    combo.subTotal = this.calcularTotalCombo();
    combo.comentario = this.comboSeleccionado.comentario;
    combo.imagen = this.comboSeleccionado.imagen;
    let productosCombo: ProductoCarrito[] = [];
    for (let i = 0; i < this.comboSeleccionado.productos.length; i++) {
      let productoCarrito = new ProductoCarrito();
      productoCarrito.idProducto = this.comboSeleccionado.productos[i][0].idProducto;
      productoCarrito.cantidad = 1;//this.comboSeleccionado.productos[i][0].cantidad;
      productoCarrito.nombre = this.comboSeleccionado.productos[i][0].nombre;
      productoCarrito.idTamano = this.comboSeleccionado.productos[i][0].tamanos[0].idTamano;
      productoCarrito.nombreTamano = this.comboSeleccionado.productos[i][0].tamanos[0].nombre;
      productoCarrito.ingredientes = this.ingredientesProductosCombo[i];
      productoCarrito.precioUnitario = this.comboSeleccionado.productos[i][0].tamanos[0].precio;
      productoCarrito.subTotal = null;
      productoCarrito.comentario = "";
      productoCarrito.extras = this.getProductoComboExtraSelected(this.comboSeleccionado.productos[i][0]);
      productoCarrito.imagen = this.comboSeleccionado.productos[i][0].imagen;
      productosCombo.push(productoCarrito);
    }
    combo.productos = productosCombo;

    if (this.carritoService.addItemToCarrito(combo, this.idTienda)) {
      var toastHTML = '<div class="centerAlign-all"><i class="material-icons">check_circle</i>&nbsp;&nbsp;<span>Combo agregado con éxito!</span></div>'
      M.toast({ html: toastHTML, classes: 'rounded' });
    }

    if (this.comboSeleccionado.favorito) {
      let favorito: Favorito = new Favorito(combo, this.tienda);
      this.favoritosService.addItemToMisFavoritos(favorito);
    }

    this.closeModal(idModal);
  }

  private getProductoComboExtraSelected(producto): Extra[] {
    let extras: Extra[] = [];
    for (let i = 0; i < producto.extras.length; i++) {
      const element = producto.extras[i];
      if (element.cantidad > 0) {
        extras.push(element);
      }
    }
    return extras;
  }

  sumarCantidadCombo(cantidad: number) {
    if ((this.comboSeleccionado.cantidad + cantidad) > 0) {
      this.comboSeleccionado.cantidad = this.comboSeleccionado.cantidad + cantidad;
    }
  }

  sumarCantidadExtraProductoCombo(cantidad: number, productoComboIndex: any, extra: any) {
    let producto = this.comboSeleccionado.productos[productoComboIndex][0];
    if (producto.extras) {
      let index = producto.extras.indexOf(extra);
      if (producto.extras[index].cantidad === undefined) producto.extras[index].cantidad = 0;
      if ((producto.extras[index].cantidad) >= 0) {
        this.comboSeleccionado.productos[productoComboIndex][0].extras[index].cantidad =
          this.comboSeleccionado.productos[productoComboIndex][0].extras[index].cantidad + cantidad;
      }
    }
  }
  //#endregion COMBOS

  private validateProductosIngredientes(tipo: ('producto' | 'combo')): boolean {
    let valid = true;
    if (tipo == 'producto') {
      for (let index = 0; index < this.ingredientesSeleccionados.length; index++) {
        const element = this.ingredientesSeleccionados[index];
        if (element == null) {
          valid = false;
          Swal.fire({
            title: 'Aún no elegiste los ingredientes',
            text: 'Personaliza tu ' + this.productoSeleccionado.nombre,
            icon: 'info'
          })
          break;
        }
      }
    } else if (tipo == 'combo') {
      for (let index = 0; index < this.ingredientesProductosCombo.length; index++) {
        const producto = this.ingredientesProductosCombo[index];
        for (let j = 0; j < producto.length; j++) {
          const ingrediente = producto[j];
          if (ingrediente == null) {
            valid = false;
            Swal.fire({
              title: 'Personaliza tu combo',
              text: 'Elige los ingredientes de ' + this.comboSeleccionado.productos[index][0].nombre,
              icon: 'info'
            })
            break;
          }
        }
      }
    }
    return valid;
  }

  sumarCantidadExtraProducto(cantidad: number, extra: any) {
    if (this.productoSeleccionado.extras) {
      let index = this.productoSeleccionado.extras.indexOf(extra);
      if ((this.productoSeleccionado.extras[index].cantidad) >= 0) {
        this.productoSeleccionado.extras[index].cantidad = this.productoSeleccionado.extras[index].cantidad + cantidad;
      }
    }
  }

  sumarCantidadProducto(cantidad: number) {
    if ((this.productoSeleccionado.cantidad + cantidad) > 0) {
      this.productoSeleccionado.cantidad = this.productoSeleccionado.cantidad + cantidad;
    }
  }

  addToCarrito(idModal: string) {
    if (!this.validateProductosIngredientes('producto')) {
      return;
    }
    let productoCarrito = new ProductoCarrito();
    productoCarrito.idProducto = this.productoSeleccionado.idProducto;
    productoCarrito.cantidad = this.productoSeleccionado.cantidad;
    productoCarrito.nombre = this.productoSeleccionado.nombre;
    productoCarrito.idTamano = this.tamanoSeleccionado.idTamano;
    productoCarrito.nombreTamano = this.tamanoSeleccionado.nombre;
    productoCarrito.ingredientes = this.ingredientesSeleccionados;
    productoCarrito.precioUnitario = this.tamanoSeleccionado.precio;
    productoCarrito.subTotal = this.calcularTotal();
    productoCarrito.comentario = this.productoSeleccionado.comentario;
    productoCarrito.extras = this.getProductosExtrasSelected();
    productoCarrito.imagen = this.productoSeleccionado.imagen;

    if (this.carritoService.addItemToCarrito(productoCarrito, this.idTienda)) {
      // this.openSnackBar();
      var toastHTML = '<div class="centerAlign-all"><i class="material-icons">check_circle</i>&nbsp;&nbsp;<span>Producto agregado con éxito!</span></div>'
      M.toast({ html: toastHTML, classes: 'rounded' });
    }

    if (this.productoSeleccionado.favorito) {
      let favorito: Favorito = new Favorito(productoCarrito, this.tienda);
      this.favoritosService.addItemToMisFavoritos(favorito);
    }

    this.closeModal(idModal);
  }

  private getProductosExtrasSelected(): Extra[] {
    let extras: Extra[] = [];
    for (let i = 0; i < this.productoSeleccionado.extras.length; i++) {
      const element = this.productoSeleccionado.extras[i];
      if (element.cantidad > 0) {
        extras.push(element);
      }
    }
    return extras;
  }

  calcularTotal() {
    let total: number = 0;
    if (this.productoSeleccionado && this.tamanoSeleccionado.precio) {
      total += this.tamanoSeleccionado.precio * this.productoSeleccionado.cantidad;
      this.productoSeleccionado.extras.forEach(extra => {
        if (extra.cantidad > 0) {
          extra.precios.forEach(precioPorTamano => {
            if (precioPorTamano.idTamano == this.tamanoSeleccionado.idTamano) {
              total += precioPorTamano.precio * extra.cantidad;
            }
          });
        }
      });
    }
    return total;
  }

  onTamanoSelected(event: MatRadioChange | any) {
    let idTamano = event.value;
    this.productoSeleccionado.tamanos.forEach(tamano => {
      if (tamano.idTamano == idTamano) {
        this.tamanoSeleccionado.precio = tamano.precio;
        this.tamanoSeleccionado.nombre = tamano.nombre;
      }
    });
  }

  onAddToCartPressed(producto: any) {
    if (this.sucursales == null || this.sucursales.length == 0) {
      return;
    }
    if (this.productoSeleccionado != producto) {
      //Si el actual producto seleccionado por el usuario 
      //no es el mismo que el seleccionado previo al actual se borra la lista de extras
      //además de los ingredientes seleccionados
      //Y se setea el tamanoSeleccionado al primero de la lista de tamaños del producto
      this.productoSeleccionado = producto;
      this.tamanoSeleccionado.idTamano = this.productoSeleccionado.tamanos[0].idTamano;
      this.tamanoSeleccionado.precio = this.productoSeleccionado.tamanos[0].precio;
      this.tamanoSeleccionado.nombre = this.productoSeleccionado.tamanos[0].nombre;
      this.ingredientesSeleccionados = [];
      this.productoSeleccionado.cantidad = 1;
      this.productoSeleccionado.comentario = '';
      this.productoSeleccionado.favorito = false;
      let i = 0;
      while (i < (this.productoSeleccionado.numeroExtrasIngredientes <= 5 ? this.productoSeleccionado.numeroExtrasIngredientes : 5)) {
        this.ingredientesSeleccionados.push(null);
        i++;
      }
      this.showMoreExtras = false;
      for (let i = 0; i < this.productoSeleccionado.extras.length; i++) {
        this.productoSeleccionado.extras[i].cantidad = 0;
      }
    }
    $('#modalProducto').modal('open');
    $('#modalProducto .extrasScrollBar').animate({ scrollTop: 0 }, 0);
  }

  closeModal(modalId: string) {
    $('#' + modalId).modal('close');
  }

  validaPrecioPromocion(producto: any): Boolean {
    if (producto.precioPromocion == null || producto.precioPromocion === undefined) {
      return false;
    }
    if (producto.precioPromocion <= 0) {
      return false;
    }
    if (producto.precioPromocion < producto.tamanos[0].precio) {
      return true;
    }
  }

  //#region FAVORITOS

  compareIdExtra(option, currentValue) {
    return option && currentValue ? option.idExtra == currentValue.idExtra : option === currentValue;
  }

  private searchFavoriteInAllProductos() {
    for (let i = 0; i < this.productosByCategorias.length; i++) {
      const categoria = this.productosByCategorias[i];
      for (let j = 0; j < categoria.productos.length; j++) {
        const producto = categoria.productos[j];
        if (producto.idProducto == this.favorito.producto.idProducto) {
          return producto;
        }
      }
    }
  }

  private searchFavoriteComboInAllProductos() {
    for (let i = 0; i < this.productosByCategorias.length; i++) {
      const categoria = this.productosByCategorias[i];
      for (let j = 0; j < categoria.combos.length; j++) {
        const combo = categoria.combos[i];
        if (combo.idCombo == this.favorito.producto.idCombo) {
          return combo;
        }
      }
    }
  }

  myFavoriteIsProduct() {
    this.productoSeleccionado = this.searchFavoriteInAllProductos();
    this.productoSeleccionado.cantidad = this.favorito.producto["cantidad"];
    this.ingredientesSeleccionados = this.favorito.producto["ingredientes"];
    this.tamanoSeleccionado.idTamano = this.favorito.producto["idTamano"];
    this.onTamanoSelected({ "value": this.favorito.producto["idTamano"] });//Se setea el tamaño
    this.productoSeleccionado.comentario = this.favorito.producto["comentario"];
    this.productoSeleccionado.favorito = true;
    
    this.favorito.producto.extras.forEach(favoritoExtra => { //SE SETEAN LOS EXTRAS
      this.productoSeleccionado.extras.forEach(extra => {
        if (favoritoExtra.idExtra == extra.idExtra) {
          extra.cantidad = favoritoExtra.cantidad;
        }
      });
    });
    $(document).ready(function () {
      $('#modalProducto').modal('open');
      $('#modalProducto .extrasScrollBar').animate({ scrollTop: 0 }, 0);
    });
  }

  private setIngredientesCombo() {
    for (let index = 0; index < this.favorito.producto.productos.length; index++) {
      const producto = this.favorito.producto.productos[index];
      for (let j = 0; j < producto.ingredientes.length; j++) {
        const ingredientes = producto.ingredientes[j];
        delete ingredientes.cantidad;
      }
      this.ingredientesProductosCombo.push(producto.ingredientes);
    }

    for (let iPC = 0; iPC < this.comboSeleccionado.productos.length; iPC++) {//SE ITERAN LOS PRODUCTOS DEL COMBO ORIGINAL
      const productoCombo = this.comboSeleccionado.productos[iPC];
      for (let iPCF = 0; iPCF < this.favorito.producto.productos.length; iPCF++) { //SE ITERA SOBRE LOS PRODUCTOS DEL COMBO FAVORITO
        const productoComboFavorito = this.favorito.producto.productos[iPCF];
        if (productoCombo[0].idProducto == productoComboFavorito.idProducto && (productoComboFavorito.wasValidated === undefined || !productoComboFavorito.wasValidated)) {//CUANDO SE ENCUENTRA EL ITEM SE CONFIGURA
          productoCombo[0].cantidad = productoComboFavorito.cantidad;
          //SETEAR LOS INGREDIENTES
          productoCombo[0].ingredientes = productoComboFavorito.ingredientes;
          productoCombo[0].comentario = productoComboFavorito.comentario;
          productoComboFavorito.wasValidated = true;
          //SETEAR LOS EXTRAS
          for (let i = 0; i < productoComboFavorito.extras.length; i++) {
            const extraFavorito = productoComboFavorito.extras[i];
            for (let j = 0; j < productoCombo[0].extras.length; j++) {
              const extra = productoCombo[0].extras[j];
              if (extraFavorito.idExtra == extra.idExtra) {
                extra.cantidad = extraFavorito.cantidad;
              }
            }
          }
          break;
        }
      }
      $(document).ready(function () {
        $('#modalCombo').modal('open');
        $('#modalCombo .extrasScrollBar').animate({ scrollTop: 0 }, 0);
      });
    }
  }

  private myFavoriteIsCombo() {
    this.ingredientesProductosCombo = [];
    this.comboSeleccionado = this.searchFavoriteComboInAllProductos();
    this.comboSeleccionado.cantidad = this.favorito.producto.cantidad;
    this.comboSeleccionado.comentario = this.favorito.producto.comentario;
    this.comboSeleccionado.favorito = true;
    this.setIngredientesCombo();
  }

  checkIfIsFromFavoriteRoute() {
    let indexProductoFavorito: number = this.route.snapshot.params["index"];
    if (indexProductoFavorito != null || indexProductoFavorito !== undefined) {
      let favoritos: Favorito[] = this.favoritosService.getCurrentFavoritos();
      if (indexProductoFavorito >= 0 && indexProductoFavorito < favoritos.length) {
        this.favorito = favoritos[indexProductoFavorito];
        if (this.favorito.producto["idProducto"] !== undefined) {
          this.myFavoriteIsProduct();
        } else {
          this.myFavoriteIsCombo();
        }
      }
    }
  }

  //#endregion FAVORITOS


  getProductosByTienda() {
    this.dataService.postRequest('getCategoriasProductosTienda', { idTienda: this.idTienda }).toPromise()
      .then(
        data => {
          this.productosByCategorias = data["data"];
          this.checkIfIsFromFavoriteRoute();
        })
      .catch((error) => this.dataService.displayErrorByRequest(error))
      .finally(() => {
        this.categoriaIndex = 0;
        // this.categoriaTabs.selectedIndex = 0;
        $('#modalProducto').modal();
        $('#modalCombo').modal();
        this.productosLoaded = true;
      });
  }

  getTiendaByUrlPersonalizada() {
    this.dataService.postRequest('getTiendaByUrlPersonalizada', { urlPersonalizada: this.urlPersonalizada }).toPromise()
      .then(data => {
        this.tienda = data["data"];
        this.idTienda = this.tienda.idTienda;

        this.logoTienda = this.urlImg + this.tienda['logoTienda'];
        this.getProductosByTienda();
        this.getSucursalesAbiertas();
      })
  }

  getSucursalesAbiertas() {
    this.dataService.postRequest("getSucursalesPorTiendaAbiertas", { idTienda: this.idTienda }).toPromise()
      .then(data => {
        this.sucursales = data["data"];
      }).finally(()=>{
        this.sucursalesLoaded = true;
      })
  }


  initRequests() {
    this.getTiendaByUrlPersonalizada();
  }

  ngAfterViewInit() {
    this.initRequests();
  }

  ngOnInit() {
  }

}
