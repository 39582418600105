import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Favorito } from 'src/app/models/Favorito';
import { FavoritoService } from 'src/app/services/favorito.service';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var favoritoboxClose;
@Component({
  selector: 'app-favorito',
  templateUrl: './favorito.component.html',
  styleUrls: ['./favorito.component.scss']
})
export class FavoritoComponent implements OnInit {
  misFavoritos: any = [];
  imageUrl = environment.imageUrlBase;
  // @Output()
  // onHacerPedidoPressed = new EventEmitter<string>();
  constructor(private favoritosService: FavoritoService, private router: Router) { }


  pedirProducto(favorito: Favorito, index: number) {
    // this.onHacerPedidoPressed.emit('');
    favoritoboxClose();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigateByUrl(favorito.tienda["urlPersonalizada"] + "/favorite/" + index);
  }

  getTotal() {
    return this.misFavoritos.map(e => e.subTotal).reduce((acc, value) => acc + value, 0);
  }

  deleteProductoDeFavoritos(index: number) {
    this.favoritosService.deleteItemInFavoritos(index);
  }

  ngOnInit() {
    this.favoritosService.getCurrentFavoritos();
    this.favoritosService.currentFavoritos.subscribe(favoritos => {
      this.misFavoritos = favoritos;
    })
  }

}
