import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { SubheaderComponent } from './layout/subheader/subheader.component';
import { CategoriesComponent } from './layout/home/categories/categories.component';
import { FooterComponent } from './layout/home/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TiendaMenuComponent } from './components/tienda-menu/tienda-menu.component';
import { HomeComponent } from './layout/home/home.component';
import { CarritoComponent } from './components/carrito/carrito.component';
import { PedidoComponent } from './components/pedido/pedido.component';
import { RestaurantesComponent } from './components/restaurantes/restaurantes.component';
import { FormTiendaComponent } from './components/tienda/form-tienda/form-tienda.component';
import { LoadingService } from './services/loading.service';
import { LoadingInterceptor } from './utilities/loading.interceptor';
import { GenericLoadingComponent } from './components/generic-loading/generic-loading.component';
import { QuejasSugerenciasComponent } from './components/quejas-sugerencias/quejas-sugerencias.component';
import { LandingComponent } from './components/landing/landing.component';
import { ScrollspyDirective } from './directives/scrollspy.directive';
import { ComoFuncionaComponent } from './components/como-funciona/como-funciona.component';
import { SaberMasComponent } from './components/saber-mas/saber-mas.component';
import { FavoritoComponent } from './components/favorito/favorito.component';
import { NgxPaymentCardModule } from 'ngx-payment-card';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from 'src/environments/environment';
import { PedidoEstatusComponent } from './components/pedido-estatus/pedido-estatus.component';
import { FinalizarPedidoComponent } from './components/finalizar-pedido/finalizar-pedido.component';


@NgModule({
  declarations: [
    AppComponent,
    GenericLoadingComponent,
    HeaderComponent,
    SubheaderComponent,
    CategoriesComponent,
    FooterComponent,
    TiendaMenuComponent,
    HomeComponent,
    CarritoComponent,
    PedidoComponent,
    RestaurantesComponent,
    FormTiendaComponent,
    QuejasSugerenciasComponent,
    LandingComponent,
    ScrollspyDirective,
    ComoFuncionaComponent,
    SaberMasComponent,
    FavoritoComponent,
    PedidoEstatusComponent,
    FinalizarPedidoComponent,
  ],
  imports: [
    MatFormFieldModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    NgxPaymentCardModule,
  ],
  providers: [
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);
  }
}
