import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Tienda } from 'src/app/models/Tienda';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import Swal from 'sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FileValidator } from 'ngx-material-file-input';
import { startWith, map } from 'rxjs/operators';
import { GeneralService } from 'src/app/services/general-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-tienda',
  templateUrl: './form-tienda.component.html',
  styleUrls: ['./form-tienda.component.scss']
})
export class FormTiendaComponent implements OnInit {

  apiImg = environment.imageUrlBase;
  tienda: Tienda;
  @Output() returnEvent = new EventEmitter();
  readonly maxSize = 1 * 2 **20; //4MB = 4 * 2 **20
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  formTienda: FormGroup;
  selectedEtiquetas;
  allEtiquetas = [];
  tagCtrl = new FormControl();
  filteredEtiquetas;
  datoRequerido: String = "Este dato es requerido";
  matcher = new MyErrorStateMatcher('logoTienda');
  loadingImage: boolean = false;
  listOfCategorias: any;
  listOfSubCategorias: any;
  paises: any;
  hide = true;
  girosComerciales: any;
  loading: boolean = false;
  @ViewChild('tagInput', { static: true }) tagInput: ElementRef<HTMLInputElement>;

  constructor(private fb: FormBuilder, private dataService: GeneralService, private router: Router) {
    this.filteredEtiquetas = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag) => tag ? this._filter(tag) : this.allEtiquetas.slice()));
  }

  saveTienda(): void {
    this.submitClick();
    if(this.formTienda.valid){
      let tiendaToSave: Tienda = new Tienda();
      tiendaToSave.fillModel(this.formTienda.value);
      tiendaToSave.etiquetas = this.selectedEtiquetas;
      this.dataService.postRequest('createTienda', tiendaToSave).subscribe((data: any) => {
        if (data.estatus == 200) {
          Swal.fire({
            text: 'Te Hemos enviado un correo electrónico, para continuar con el proceso de alta de tu empresa, Revisa Bandeja de entrada y el SPAM',
            icon: 'success'
          }).then((result) => {
            this.router.navigateByUrl('/home')
          });
        } else {
          this.dataService.displayErrorMessage(data);
        }
      }, (error) => {
        this.dataService.displayErrorMessage(error.error.text);
      });
    }else{
      Swal.fire({
        title: 'Completa los datos solicitados marcados en rojo.',
        icon: 'error'
      })
    }
  }

  add(event): void {
    const input = event.input;
    const etiqueta = event.value;
    // Add our fruit
    if ((etiqueta || '').trim()) {
      this.selectedEtiquetas.push({ idEtiqueta: 0, nombreEtiqueta: etiqueta });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue(null);
  }

  remove(etiqueta): void {
    if (etiqueta.idEtiqueta != 0)
      this.allEtiquetas.push(etiqueta);
    const index = this.selectedEtiquetas.findIndex(etiq => etiq.nombreEtiqueta == etiqueta.nombreEtiqueta);
    if (index >= 0) {
      this.selectedEtiquetas.splice(index, 1);
    }
  }

  submitClick(){
    this.formTienda.markAllAsTouched();
  }

  selected(event) {
    let value = event.option.value;
    this.selectedEtiquetas.push(value);
    this.tagInput.nativeElement.value = '';
    this.allEtiquetas.splice(this.allEtiquetas.indexOf(value), 1);
    this.tagCtrl.setValue(null);
  }

  fileChangeEvent(file: any) {
    if (file != null && typeof file != undefined && file != '') {
      let imageToUpload: File = file.target.files[0];
      //SI LA IMAGEN SELECCIONADA NO TERMINA EN PNG O SI LA IMAGEN PESA MAS DE 4MB
      //SE SETEA LA IMAGEN COMO NULL PARA QUE NO PERMITA AVANZAR
      if (!imageToUpload.name.endsWith('png') || this.formTienda.controls['logoInput'].hasError('maxContentSize')) {
        this.formTienda.controls['logoTienda'].setValue(null);
        return;
      }
      this.loadingImage = true;
      this.dataService.uploadImage(imageToUpload).toPromise().then(//Realiza la petición
        (data) => {//Si no hubo error se setea el nombre de la imagen al modelo 
          if (data["estatus"] == 200) {
            this.formTienda.controls['logoTienda'].setValue(data["nombreImagen"]);
            /*Swal.fire({
              title: 'Aviso',
              text: 'Imagen cargada con éxito',
              icon: 'success'
            })*/
          } else {
            this.dataService.displayErrorByRequest(data);
          }
          this.loadingImage = false;
        },
        (error) => {
          this.formTienda.controls['logoTienda'].setValue(null);
          this.dataService.displayErrorByRequest(error);
          this.loadingImage = false;
        }
      );
    }
  }

  initForm() {
    this.tienda = new Tienda();
    this.tienda.clearModel();
    this.formTienda = this.fb.group({
      idTienda: [this.tienda.idTienda],
      idPais: [this.tienda.idPais, [Validators.required]],
      nombreComercial: [this.tienda.nombreComercial, [Validators.required, Validators.maxLength(100)]],
      rfc: [this.tienda.rfc, [Validators.required, Validators.maxLength(13)]],
      telefono: [this.tienda.telefono, [Validators.required, Validators.maxLength(10)]],
      correo: [this.tienda.correo, [Validators.required, Validators.email]],
      urlPersonalizada: [this.tienda.urlPersonalizada, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      nombreAdministrador: [this.tienda.nombreAdministrador, [Validators.required, Validators.maxLength(100)]],
      telefonoAdministrador: [this.tienda.telefonoAdministrador, [Validators.required, Validators.maxLength(10)]],
      correoAdministrador: [this.tienda.correoAdministrador, [Validators.required, Validators.email]],
      contrasena: [this.tienda.contrasena, [Validators.required, Validators.minLength(10)]],
      logoTienda: [this.tienda.logoTienda, [Validators.required]],
      pagoEfectivo: [this.tienda.pagoEfectivo, [Validators.required]],
      pagoTPV: [this.tienda.pagoTPV, [Validators.required]],
      giroComercial: [this.tienda.giroComercial, [Validators.required]],
      facebook: [this.tienda.facebook, []],
      twitter: [this.tienda.twitter, []],
      instagram: [this.tienda.instagram, []],
      estatus: [this.tienda.estatus, [Validators.required]],
    });
    this.selectedEtiquetas = this.tienda.etiquetas;
    this.formTienda.addControl('logoInput', this.fb.control(null));
    this.formTienda.addControl('terminos', this.fb.control(false));
    this.formTienda.removeControl('logoInput');
    this.formTienda.addControl('logoInput', this.fb.control(null));
    this.formTienda.controls['logoInput'].setValidators([FileValidator.maxContentSize(this.maxSize)]);
  }

  getPaises() {
    this.dataService.postRequest(`getPaises`, {}).toPromise().then(data => {
      this.paises = data["data"];
    }).catch(error => {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al recuperar los países. ' + error.message,
        icon: 'error'
      })
    });
  }

  getGirosComerciales() {
    this.dataService.postRequest(`getGirosComerciales`, {}).toPromise().then(data => {
      this.girosComerciales = data["data"];
    }).catch(error => {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al recuperar los giros comerciales. ' + error.message,
        icon: 'error'
      })
    });
  }

  getEtiquetas() {
    this.dataService.postRequest('getEtiquetas', {}).toPromise().then((etiqs: any) => {
      this.allEtiquetas = etiqs.data;
      this.selectedEtiquetas.forEach(element => {
        const index = this.allEtiquetas.findIndex((tag) => tag.idEtiqueta == element.idEtiqueta);
        if (index >= 0) {
          this.allEtiquetas.splice(index, 1);
        }
      });
    }).catch(error => {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al recuperar las etiquetas. ' + error.message,
        icon: 'error'
      })
    });
  }

  private _filter(value: any) {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();
      return this.allEtiquetas.filter(etiqueta => {
        return etiqueta.nombreEtiqueta.toLowerCase().indexOf(filterValue) >= 0;
      });
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getPaises();
    this.getEtiquetas();
    this.getGirosComerciales();
  }
}


export class MyErrorStateMatcher implements ErrorStateMatcher {
  controlName: string;
  constructor(controlName: string) {
    this.controlName = controlName;
  }
  readonly maxSize = 1 * 2 **20; //4MB = 4 * 2 **20
  public isErrorState(control: FormControl, _: NgForm | FormGroupDirective): boolean {
    const size = control?.value?._files[0].size > this.maxSize;
    const extValidation = !(control?.value?._fileNames?.endsWith('png'));
    if (_.form.controls[this.controlName].value == null) { //Sí el logo no tiene cargado alguna imagen
      if (extValidation == true) {
        if (control.errors && control.errors.maxContentSize) {
          let maxContentSize = control?.errors?.maxContentSize;
          control?.setErrors({ maxContentSize, extension: extValidation });
        } else {
          control?.setErrors({ extension: extValidation });
        }
      } else {
        control?.updateValueAndValidity()
      }
    } else { //Sí el logo tiene previamente cargado alguna imagen se le quitan los errores
      control?.setErrors(undefined);
    }

    return (control && control.value && control.value._fileNames && (size || extValidation));
  }
}