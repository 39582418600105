<div class="comoFuncionaWrapper">
    <section class="row aqui-pide-bg-red center">
        <h2 class="padding-16 pt-2em ">¡ Más información de Aquí Pide !</h2>
    </section>
    <section class="row">
        <h4 class="center">Tu Nexo entre
            Instagram&nbsp;<img class="whatsappImg" src="assets/img/logoInsta_black.svg">
            Facebook&nbsp;<img class="whatsappImg" src="assets/img/logoFace_black.svg">
            y Whatsapp <img class="whatsappImg" src="assets/img/whatsapp.png">
        </h4>
        <div class="row"></div>
        <div class="row"></div>
        <div class="row center">
            <p>Instagram y Facebook son las redes sociales líderes del momento y dónde es clave tener fuerte presencia
                para estár cerca de
                tus clientes. Sin embargo, el punto fuerte de éstas es generar interacción con tu audiencia, pero no
                cerrar una venta.
            </p>
            <p>
                En el momento que tu visitante se vió atraido por tus publicaciones y quiere comprarte, es muy
                importante que el proceso sea lo más sencillo posible, porque cada contratiempo que tenga es una
                posibilidad adicional de perder una venta y un futuro cliente feliz.
            </p>
            <p>
                Por eso, es muy importante que sea sencillo para él consultar todos los geniales productos y promociones
                que tenes para ofrecerle junto con sus precios y que no tenga que estar perdiendo tiempo en consultarte
                esa valiosa información por mensaje o privado.
            </p>
            <p>
                Aquí Pide mejora y simplifica la comunicación entre el cliente y el comercio permitiéndolo armar su
                compra en una interfaz sencilla e intuitiva para luego enviártela en un mensaje claro y detallando
                exactamente todo lo que necesita vía WhatsApp.
            </p>
        </div>
    </section>
    <section class="row aqui-pide-bg-red">
        <div class="row"></div>
        <h4 class="center">¿Cómo se compara Aquí Pide con las apps de Delivery?</h4>
        <div class="row"></div>
        <div class="row"></div>
        <div class="row center">
            <p>Las apps de delivery son de doble filo para los pequeños y medianos comercios. Por un lado, no tendrás
                que preocuparte de la logística de envío o como abonará tu cliente su compra pero, en contrapartida, te
                cobrarán una alta comisión por sus servicios que rondará entre el 15% y 30% del total.
            </p>
            <p>Esta pérdida de rentabilidad es en muchos casos demasiado alta para comercios locales que no cuenten
                con un volumen de ventas suficiente para compensarlo.
            </p>
        </div>
        <div class="col s12 m6">
            <ul class="container col s12">
                <li class="title center">
                    Apps de Delivery
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Servicio de logística y cobro unificado.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">check</i> Aumentan las ventas a través de su portal.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">close</i> Requiere que el cliente instale una app.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">close</i> No recibes el total vendido en el momento.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">close</i> Altas comisiones que bajaran tu rentabilidad.
                    </p>
                </li>
            </ul>
        </div>
        <div class="col s12 m6">
            <ul class="container col s12">
                <li class="title center">
                    Aquí Pide
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">motorcycle</i> Debes contar con tu propio delivery.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">store</i> Puedes ofrecer retiro en sucursal.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">monetization_on</i> Ofreces tus propios medios de pago.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">cloud_done</i> Es rápido y no requiere instalación.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">attach_money</i> Cobras el total de la venta en el momento.
                    </p>
                </li>
                <li class="col s12">
                    <p>
                        <i class="material-icons">autorenew</i> En el futuro pagarás un económico abono fijo.
                    </p>
                </li>
            </ul>
        </div>
        <div class="row"></div>
        <div class="row"></div>
    </section>

    <section class="row">
        <div class="row"></div>
        <h4 class="center">El comercio electrónico viene ganando terreno fuerte</h4>
        <div class="row center">
            <i class="material-icons large">trending_up</i>
        </div>
        <div class="row center">
            <p>
                El mundo cambia vertiginosamente y los comercios y negocios que mejor se adapten con él más crecerán
                ofreciendo las experiencias más satisfactorias a sus clientes.
            </p>
            <p>
                Año a año han aumentadon las operaciones de comercio electrónico y más gente empieza a realizar su
                primera compra por Internet.
            </p>
            <p>
                Si bien la pandemia de COVID-19 que estamos padeciendo ha obligado a comercios a reorganizarse hacia una
                modalidad de entrega a domicilio, todo indica que los cambios en los hábitos de los consumidores serán
                modificados.
            </p>
        </div>
        <div class="row"></div>
        <a routerLink="/pricing" class="waves-effect btn fabTopRight"><i class="material-icons left">home</i>Volver al
            inicio</a>
    </section>
</div>