<!-- <kt-generic-loading></kt-generic-loading> -->
<section class="food__menu__grid__area invisibleScroll" appScrollspy style="height: 100vh; overflow-y: scroll;"
    [spiedTags]="['H4']" [idContainer]="'productSection'" (sectionChange)="onSectionChange($event)">
    <!-- INICIO FONDO E INFO DE LA TIENDA -->
    <div class="ht__bradcaump__area background" style="margin-top: 18vh;"
        [style.background-image]="'url('+logoTienda+')'">
        <div class="ht__bradcaump__wrap d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="bradcaump__inner text-center">
                            <!-- <br> -->
                            <!-- <h2 class="bradcaump-title">{{tienda?.nombreComercial}}</h2> -->
                            <!-- <img src="{{logoTienda}}"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="menuWrapper">
        <div class="row">
            <div class="col s12 center">
                <div class="grid__show">
                    <div class="grid__show__item">
                        <p class="textCapitalize" style="font-size:1.500em">{{tienda?.nombreComercial}}
                            {{(sucursalesLoaded && sucursales == null)?'Se encuentra cerrado':''}}</p>
                    </div>
                    <!-- <div class="grid__show__btn">
                        <a class="food__btn" href="#">Default Sorting</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- TERMINA FONDO E INFO DE LA TIENDA -->

        <div class="row mt--30" *ngIf="productosByCategorias!=null && productosByCategorias.length>0">

            <!-- CATEGORIAS -->
            <mat-tab-group mat-align-tabs="center" #categoriaTabs class="tabGroup" [(selectedIndex)]="categoriaIndex"
                (selectedIndexChange)="scrollTo($event)">
                <mat-tab *ngFor="let categoria of productosByCategorias; let index = index"
                    [label]="categoria.descripcion">
                </mat-tab>
            </mat-tab-group>
            <!-- END CATEGORIAS -->

            <section id="productSection">
                <!-- <div class="row"></div> -->
                <!-- LISTADO DE PRODUCTOS Y COMBOS-->
                <ng-container *ngFor="let productosCategoria of productosByCategorias">
                    <h4 class="row categoriaTitle" id="categoria{{productosCategoria.idCategoria}}"
                        *ngIf="productosCategoria.descripcion!='Combos'">
                        {{productosCategoria.descripcion}}</h4>

                    <!--  LISTADO DE COMBOS -->
                    <div *ngFor="let combo of productosCategoria?.combos" class="col l4 s12 m6 productoWrapper">
                        <div class="menu__grid__item wow fadeInLeft">
                            <div class="menu__grid__thumb centerAlign-horizontal cursorPointer">
                                <a class="centerAlign-horizontal hvr-grow" (click)="openImgPopup(combo)">
                                    <img src="{{urlImg + combo.imagen}}" class="product-img responsive-img"
                                        alt="{{combo.nombre}}">
                                </a>
                                <div *ngIf="validaPrecioPromocion(combo)" class="grid__item__offer">
                                    <span>En</span>
                                    <span>Oferta</span>
                                </div>
                            </div>
                            <div class="menu__grid__inner">
                                <div class="menu__grid__details">
                                    <h2 class="textCapitalize">{{combo?.nombre}}</h2>
                                    <ul class="grid__prize__list">
                                        <li *ngIf="validaPrecioPromocion(combo)"
                                            style="text-decoration-line: line-through;" class="old__prize">
                                            {{combo?.precio | currency:"MXN":"symbol"}}</li>
                                        <li *ngIf="validaPrecioPromocion(combo)">
                                            {{combo.precioPromocion | currency:"MXN":"symbol"}}</li>
                                        <li *ngIf="!validaPrecioPromocion(combo)">
                                            {{combo?.precio | currency:"MXN":"symbol"}}</li>
                                    </ul>
                                    <p>{{combo?.descripcion}}</p>
                                </div>
                                <div [ngClass]="{btnDisabled:sucursales==null || sucursales.length==0}"
                                    (click)="onAddToCartComboPressed(combo)" class="aqui-pido-btn"
                                    style="text-overflow: ellipsis;">
                                    {{(sucursales != null && sucursales?.length > 0)?'Seleccionar':'Cerrado'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- FIN LISTADO DE COMBOS -->

                    <div *ngFor="let producto of productosCategoria?.productos" class="col l4 s12 m6 productoWrapper">
                        <div class="menu__grid__item wow fadeInLeft">
                            <div class="menu__grid__thumb centerAlign-horizontal">
                                <a class="centerAlign-horizontal hvr-grow cursorPointer"
                                    (click)="openImgPopup(producto)">
                                    <img src="{{urlImg + producto.imagen}}"
                                        onError="this.src='assets/img/logo-aqui-pido.png'"
                                        class="responsive-img product-img" alt="{{producto.nombre}}">
                                </a>
                                <div *ngIf="validaPrecioPromocion(producto)" class="grid__item__offer">
                                    <span>En</span>
                                    <span>Oferta</span>
                                </div>
                            </div>
                            <div class="menu__grid__inner">
                                <div class="menu__grid__details">
                                    <h2 class="textCapitalize">{{producto?.nombre}}</h2>
                                    <ul class="grid__prize__list">
                                        <li *ngIf="validaPrecioPromocion(producto)"
                                            style="text-decoration-line: line-through;" class="old__prize">
                                            {{producto?.tamanos[0].precio | currency:"MXN":"symbol"}}</li>
                                        <li *ngIf="validaPrecioPromocion(producto)">
                                            {{producto.precioPromocion | currency:"MXN":"symbol"}}</li>
                                        <li *ngIf="!validaPrecioPromocion(producto)">
                                            {{producto?.tamanos[0].precio | currency:"MXN":"symbol"}}</li>
                                    </ul>
                                    <p>{{producto?.descripcion}}</p>
                                </div>
                                <div id="select{{producto.idProducto}}"
                                    [ngClass]="{btnDisabled:sucursales == null || sucursales.length==0}"
                                    (click)="onAddToCartPressed(producto)" class="aqui-pido-btn"
                                    style="text-overflow: ellipsis;">
                                    {{(sucursales != null && sucursales?.length > 0)?'Seleccionar':'Cerrado'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row"></div>
                </ng-container>
                <!-- FIN LISTADO DE PRODUCTOS -->

                <div *ngIf="productosByCategorias==null || productosByCategorias?.length<=0">
                    <div class="grid__show__item">
                        <p>Lo sentimos, no logramos encontrar los productos de esta tienda</p>
                    </div>
                </div>
            </section>
        </div>
        <div class="row mt--30"
            *ngIf="productosLoaded && (productosByCategorias==null || productosByCategorias?.length<=0)">
            <div class="grid__show__item">
                <p>Lo sentimos, no logramos encontrar los productos de esta tienda</p>
            </div>
        </div>
    </div>

    <section *ngIf="tienda?.facebook?.length>5 || tienda?.instagram?.length>5 || tienda?.twitter?.length>5">
        <div class="row center">
            <h4>SÍGUENOS EN NUESTRAS REDES SOCIALES</h4>
        </div>
        <div class="row">
            <div class="centerAlign-all">
                <a target="_blank" href="{{tienda?.facebook}}" *ngIf="tienda?.facebook?.length>5">
                    <img style="padding:8px" src="assets/img/facebook.png">
                </a>
                <a target="_blank" href="{{tienda?.instagram}}" *ngIf="tienda?.instagram?.length>5">
                    <img style="padding:8px" src="assets/img/instagram.png">
                </a>
                <a target="_blank" href="{{tienda?.twitter}}" *ngIf="tienda?.twitter?.length>5">
                    <img style="padding:8px" src="assets/img/twitter.png">
                </a>
            </div>
        </div>
    </section>

    <app-footer id="menu-footer"></app-footer>
</section>

<!-- MODAL DE PRODUCTO -->
<div id="modalProducto" class="modal modal-fixed-footer">
    <div class="modal-content backgroundWhite extrasScrollBar" *ngIf="productoSeleccionado">
        <div (click)="closeModal('modalProducto')" class="col s12"
            style="position: fixed; right: 0; margin-right: 16px; margin-top: 8px;">
            <i class="material-icons cursorPointer">close</i>
        </div>
        <!-- Card Content -->
        <div>
            <br>
            <div class="card-title fullWidth textCenter">Personaliza tu pedido</div>
            <br>
            <div class="textCapitalize fullWidth textCenter">{{productoSeleccionado?.nombre}}</div>
        </div>
        <div class="col s12" style="padding: 0; padding-bottom: 0; padding-top: 0;">
            <!-- TAMAÑOS DEL PRODUCTO -->
            <br>
            <div>Selecciona el tamaño/presentación</div>
            <ng-container style="font-size: 12px !important;">
                <mat-radio-group [(ngModel)]="tamanoSeleccionado.idTamano" style="display: grid">
                    <mat-radio-button (change)="onTamanoSelected($event)" class="textCapitalize" style="padding: 8px;"
                        [value]="tamano.idTamano" *ngFor="let tamano of productoSeleccionado.tamanos; let i = index;">
                        {{tamano.nombre}}
                        <ng-container *ngIf="tamanoSeleccionado.precio!=tamano.precio">
                            <span style="font-size: 0.750em;">({{(tamano.precio - tamanoSeleccionado.precio) |
                                currency:"USD"}})</span>
                        </ng-container>
                    </mat-radio-button>
                </mat-radio-group>
            </ng-container>
            <br>
            <!-- FIN TAMAÑOS DEL PRODUCTO -->

            <!-- INGREDIENTES DEL PRODUCTO -->
            <section *ngIf="ingredientesSeleccionados.length>0">
                <div class="divider"></div>
                <div>Selecciona los ingredientes</div>
                <ng-container
                    *ngFor="let noIngrediente of arrayIngredientes(ingredientesSeleccionados.length); let i = index;">
                    <label [ngClass]="{errorLabel:ingredientesSeleccionados[i]==null}">Ingrediente
                        {{i+1}}</label><label class="errorLabel" *ngIf="ingredientesSeleccionados[i]==null">*</label>
                    <select [(ngModel)]="ingredientesSeleccionados[i]"
                        style="display: block; height: auto; font-size: 0.750em; color: gray;">
                        <ng-container *ngFor="let ingrediente of productoSeleccionado?.extras">
                            <option *ngIf="ingrediente.sugerido==0 || ingrediente.sugerido==false"
                                [ngValue]="ingrediente">
                                {{ingrediente.nombre}}
                            </option>
                        </ng-container>
                    </select>
                </ng-container>
            </section>
            <!-- FIN INGREDIENTES DEL PRODUCTO -->

            <div class="transparentDivider"></div>

            <!-- EXTRAS DEL PRODUCTO -->
            <div *ngIf="tamanoSeleccionado.idTamano && productoSeleccionado?.extras?.length>0">
                <div>Agrega extras a tu producto</div>
                <br>
                <section>
                    <!-- EXTRAS SUGERIDOS -->
                    <ng-container *ngFor="let xtra of productoSeleccionado?.extras; let i = index;">
                        <ng-container *ngIf="xtra.sugerido==1 || xtra.sugerido==true">
                            <div class="row" style="margin-bottom: 0.7em;">
                                <div class="col s7">
                                    <label>{{xtra.nombre}}</label>
                                    <div>
                                        <ng-container *ngFor="let xtraPrice of xtra?.precios">
                                            <label>
                                                <b style="font-size: 12px; margin-left: 8px;"
                                                    [ngStyle]=" {'display':xtraPrice.idTamano==tamanoSeleccionado.idTamano? 'block' : 'none' }">+
                                                    {{xtraPrice.precio | currency:"MXN":"symbol"}}
                                                </b>
                                            </label>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col s5">
                                    <div class="col s4 cursorPointer not-user-select"
                                        (click)="sumarCantidadExtraProducto(-1, xtra)" style="text-align: left;">
                                        <i *ngIf="productoSeleccionado.extras[i].cantidad > 0"
                                            class="material-icons">indeterminate_check_box</i>
                                    </div>
                                    <div class="col s4 not-user-select" style="text-align: center;">
                                        <span *ngIf="productoSeleccionado.extras[i].cantidad > 0">
                                            {{productoSeleccionado?.extras[i].cantidad}}
                                        </span>
                                    </div>
                                    <div class="col s4 cursorPointer not-user-select"
                                        (click)="sumarCantidadExtraProducto(+1, xtra)" style="text-align: left;">
                                        <i class="material-icons">add_box</i>
                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                        </ng-container>
                    </ng-container>
                    <div *ngIf="!showMoreExtras" class="cursorPointer fullWidth"
                        (click)="showMoreExtras = !showMoreExtras">ver mas extras...
                    </div>
                    <!-- FIN EXTRAS SUGERIDOS -->

                    <!-- MÁS EXTRAS -->
                    <ng-container *ngIf="showMoreExtras">
                        <ng-container *ngFor="let xtra of productoSeleccionado?.extras; let i = index;">
                            <ng-container *ngIf="xtra.sugerido==0 || xtra.sugerido==false">
                                <div class="row" style="margin-bottom: 0.7em;">
                                    <div class="col s7">
                                        <label>{{xtra.nombre}}</label>
                                        <div>
                                            <ng-container *ngFor="let xtraPrice of xtra?.precios">
                                                <label>
                                                    <b style="font-size: 12px; margin-left: 8px;"
                                                        [ngStyle]=" {'display':xtraPrice.idTamano==tamanoSeleccionado.idTamano? 'block' : 'none' }">+
                                                        {{xtraPrice.precio | currency:"MXN":"symbol"}}
                                                    </b>
                                                </label>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col s5">
                                        <div class="col s4 cursorPointer not-user-select"
                                            (click)="sumarCantidadExtraProducto(-1, xtra)" style="text-align: left;">
                                            <i *ngIf="productoSeleccionado.extras[i].cantidad > 0"
                                                class="material-icons">indeterminate_check_box</i>
                                        </div>
                                        <div class="col s4 not-user-select" style="text-align: center;">
                                            <span *ngIf="productoSeleccionado.extras[i].cantidad > 0">
                                                {{productoSeleccionado?.extras[i].cantidad}}
                                            </span>
                                        </div>
                                        <div class="col s4 cursorPointer not-user-select"
                                            (click)="sumarCantidadExtraProducto(+1, xtra)" style="text-align: left;">
                                            <i class="material-icons">add_box</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider"></div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <!-- FIN MÁS EXTRAS -->

                </section>
            </div>
            <!-- EXTRAS DEL PRODUCTO -->
            <br>
            <section class="row">
                <mat-form-field class="fullWidth">
                    <input maxlength="100" placeholder="Agrega alguna nota (sin salsa, sin cebolla, etc)" matInput
                        [(ngModel)]="productoSeleccionado.comentario">
                </mat-form-field>
            </section>
        </div>
    </div>
    <div class="modal-footer" style="height: 85px;">
        <!-- TERMINAR DE PERSONALIZA PRODUCTO -->
        <div class="row" style="margin-bottom: 8px;">
            <div *ngIf="productoSeleccionado" (click)="productoSeleccionado.favorito = !productoSeleccionado.favorito"
                [ngStyle]=" {'color':productoSeleccionado.favorito==true ? '#f70025' : '#444444' }"
                class="col s3 centerAlign-all fav_wrapper not-user-select cursorPointer">
                Fav<i class="material-icons">favorite</i>
            </div>
            <div class="col s3">Cantidad: </div>
            <div class="col s6">
                <div class="col s4 cursorPointer not-user-select" (click)="sumarCantidadProducto(-1)"
                    style="text-align: right;">
                    <i class="material-icons">indeterminate_check_box</i>
                </div>
                <div class="col s4 not-user-select" style="text-align: center;">{{productoSeleccionado?.cantidad}}</div>
                <div class="col s4 cursorPointer not-user-select" (click)="sumarCantidadProducto(+1)"
                    style="text-align: left;">
                    <i class="material-icons">add_box</i>
                </div>
            </div>
        </div>
        <a class="agregarCarrito centerAlign-all col s6" (click)="addToCarrito('modalProducto')">
            <i class="material-icons">add_circle</i>&nbsp; Agregar
            {{calcularTotal() | currency:"MXN":"symbol"}}
        </a>
        <!-- TERMINAR DE PERSONALIZA PRODUCTO -->
    </div>
</div>
<!-- FIN MODAL DE PRODUCTO -->

<!-- MODAL DE COMBO -->
<div id="modalCombo" class="modal modal-fixed-footer">
    <div class="modal-content backgroundWhite extrasScrollBar" *ngIf="comboSeleccionado">
        <div (click)="closeModal('modalCombo')" class="col s12"
            style="position: fixed; right: 0; margin-right: 16px; margin-top: 8px;">
            <i class="material-icons cursorPointer">close</i>
        </div>
        <!-- Card Content -->
        <div>
            <br>
            <div class="card-title fullWidth textCenter">Personaliza tu combo</div>
            <br>
            <div class="row textCapitalize fullWidth textCenter">{{comboSeleccionado?.nombre}}</div>
        </div>
        <div class="col s12">
            <div class="fullWidth centerAlign-vertical" style="font-size: 0.8em;"><i class="material-icons"
                    style="font-size: 1.5em;">star</i>Personalizable</div>
            <mat-accordion [multi]="true">
                <mat-expansion-panel *ngFor="let producto of comboSeleccionado?.productos; let index = index;"
                    [expanded]="ingredientesProductosCombo[index]?.length>0 && producto[0]?.extras?.length>0"
                    style="margin-top: 8px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="font-weight: bold;">
                            {{ index + 1}}
                        </mat-panel-title>
                        <mat-panel-description style="font-weight: bold;">
                            <i style="font-size: 1.4em;"
                                *ngIf="ingredientesProductosCombo[index]?.length>0 && producto[0]?.extras?.length>0"
                                class="material-icons">star</i>{{producto[0].nombre}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <!-- INGREDIENTES DEL PRODUCTO -->
                    <div class="transparentDivider"></div>
                    <section *ngIf="ingredientesProductosCombo[index]?.length>0">
                        <div style="font-weight: bold;" class="genericDiv">Selecciona los ingredientes de tu producto
                        </div><br>
                        <ng-container
                            *ngFor="let noIngrediente of arrayIngredientes(ingredientesProductosCombo[index].length); let i = index;">
                            <label [ngClass]="{errorLabel:ingredientesProductosCombo[index][i]==null}">Ingrediente
                                {{i+1}}</label><label class="errorLabel"
                                *ngIf="ingredientesProductosCombo[index][i]==null">*</label>
                            <select [(ngModel)]="ingredientesProductosCombo[index][i]" [compareWith]="compareIdExtra"
                                style="display: block; height: auto; font-size: 0.750em; color: gray;">
                                <ng-container *ngFor="let ingrediente of producto[0]?.extras">
                                    <option *ngIf="ingrediente.sugerido==0 || ingrediente.sugerido==false"
                                        [ngValue]="ingrediente">
                                        {{ingrediente.nombre}}
                                    </option>
                                </ng-container>
                            </select>
                        </ng-container>
                    </section>
                    <!-- FIN INGREDIENTES DEL PRODUCTO -->

                    <br>
                    <div class="transparentDivider"></div>
                    <div class="transparentDivider"></div>
                    <div class="transparentDivider"></div>

                    <!-- EXTRAS DEL PRODUCTO -->
                    <div *ngIf="producto[0]?.extras?.length>0">
                        <div style="font-weight: bold;" class="genericDiv">Agrega extras a tu producto</div>
                        <br>
                        <div class="transparentDivider"></div>
                        <section>
                            <!-- EXTRAS SUGERIDOS -->
                            <ng-container *ngFor="let xtra of producto[0]?.extras; let i = index;">
                                <ng-container *ngIf="xtra.sugerido==1 || xtra.sugerido==true">
                                    <div class="row" style="margin-bottom: 0.7em;">
                                        <div class="col s7">
                                            <label>{{xtra.nombre}}</label>
                                            <div>
                                                <ng-container *ngFor="let xtraPrice of xtra?.precios">
                                                    <label>
                                                        <b style="font-size: 12px; margin-left: 8px;">+
                                                            {{xtraPrice.precio | currency:"MXN":"symbol"}}
                                                        </b>
                                                    </label>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col s5">
                                            <div class="col s4 cursorPointer not-user-select"
                                                (click)="sumarCantidadExtraProductoCombo(-1, index, xtra)"
                                                style="text-align: left;">
                                                <i *ngIf="comboSeleccionado?.productos[index][0].extras[i].cantidad > 0"
                                                    class="material-icons">indeterminate_check_box</i>
                                            </div>
                                            <div class="col s4 not-user-select" style="text-align: center;">
                                                <span
                                                    *ngIf="comboSeleccionado?.productos[index][0].extras[i].cantidad > 0">
                                                    {{comboSeleccionado?.productos[index][0].extras[i].cantidad}}
                                                </span>
                                            </div>
                                            <div class="col s4 cursorPointer not-user-select"
                                                (click)="sumarCantidadExtraProductoCombo(+1, index, xtra)"
                                                style="text-align: left;">
                                                <i class="material-icons">add_box</i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="divider"></div>
                                </ng-container>
                            </ng-container>
                            <div *ngIf="!showMoreExtras" class="cursorPointer fullWidth"
                                (click)="showMoreExtras = !showMoreExtras">ver mas extras...
                            </div>
                            <!-- FIN EXTRAS SUGERIDOS -->

                            <!-- MÁS EXTRAS -->
                            <ng-container *ngIf="showMoreExtras">
                                <ng-container *ngFor="let xtra of producto[0]?.extras; let i = index;">
                                    <ng-container *ngIf="xtra.sugerido==0 || xtra.sugerido==false">
                                        <div class="row" style="margin-bottom: 0.7em;">
                                            <div class="col s7">
                                                <label>{{xtra.nombre}}</label>
                                                <div>
                                                    <ng-container *ngFor="let xtraPrice of xtra?.precios">
                                                        <label>
                                                            <b style="margin-left: 8px;">+
                                                                {{xtraPrice.precio | currency:"MXN":"symbol"}}
                                                            </b>
                                                        </label>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col s5">
                                                <div class="col s4 cursorPointer not-user-select"
                                                    (click)="sumarCantidadExtraProductoCombo(-1, index, xtra)"
                                                    style="text-align: left;">
                                                    <i *ngIf="comboSeleccionado?.productos[index][0].extras[i].cantidad > 0"
                                                        class="material-icons">indeterminate_check_box</i>
                                                </div>
                                                <div class="col s4 not-user-select" style="text-align: center;">
                                                    <span
                                                        *ngIf="comboSeleccionado?.productos[index][0].extras[i].cantidad > 0">
                                                        {{comboSeleccionado?.productos[index][0].extras[i].cantidad}}
                                                    </span>
                                                </div>
                                                <div class="col s4 cursorPointer not-user-select"
                                                    (click)="sumarCantidadExtraProductoCombo(+1, index, xtra)"
                                                    style="text-align: left;">
                                                    <i class="material-icons">add_box</i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="divider"></div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <!-- FIN MÁS EXTRAS -->

                        </section>
                    </div>
                    <!-- EXTRAS DEL PRODUCTO -->

                    <!-- NADA QUE CONFIGURAR MESSAGE -->
                    <ng-container
                        *ngIf="ingredientesProductosCombo[index]?.length==0 && producto[0]?.extras?.length==0">
                        <span>Este producto no se puede personalizar 😁</span>
                    </ng-container>
                    <!-- FIN NADA QUE MOSTRAR -->

                </mat-expansion-panel>
            </mat-accordion>
            <!-- END PRODUCTOS -->
            <br>
            <section class="row">
                <mat-form-field class="fullWidth">
                    <input maxlength="100" placeholder="Agrega alguna nota (sin salsa, sin cebolla, etc)" matInput
                        [(ngModel)]="comboSeleccionado.comentario">
                </mat-form-field>
            </section>
        </div>
    </div>
    <div class="modal-footer" style="height: 85px;">
        <!-- TERMINAR DE PERSONALIZA PRODUCTO -->
        <div class="row" style="margin-bottom: 8px;">
            <div *ngIf="comboSeleccionado" (click)="comboSeleccionado.favorito = !comboSeleccionado.favorito"
                [ngStyle]=" {'color':comboSeleccionado.favorito==true ? '#f70025' : '#444444' }"
                class="col s3 centerAlign-all fav_wrapper not-user-select cursorPointer">
                Fav<i class="material-icons">favorite</i>
            </div>
            <div class="col s3">Cantidad: </div>
            <div class="col s6">
                <div class="col s4 cursorPointer not-user-select" (click)="sumarCantidadCombo(-1)"
                    style="text-align: right;">
                    <i class="material-icons">indeterminate_check_box</i>
                </div>
                <div class="col s4 not-user-select" style="text-align: center;">{{comboSeleccionado?.cantidad}}</div>
                <div class="col s4 cursorPointer not-user-select" (click)="sumarCantidadCombo(+1)"
                    style="text-align: left;">
                    <i class="material-icons">add_box</i>
                </div>
            </div>
        </div>
        <div class="agregarCarrito centerAlign-all col s6" (click)="addComboToCarrito('modalCombo')">
            <i class="material-icons">add_circle</i>&nbsp; Agregar
            {{calcularTotalCombo() | currency:"MXN":"symbol"}}
        </div>
        <!-- TERMINAR DE PERSONALIZA PRODUCTO -->
    </div>
</div>
<!-- FIN MODAL DE COMBO -->