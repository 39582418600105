import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DireccionesService {
  // private misDirecciones = new BehaviorSubject<any>([]);
  // currentMisDirecciones = this.misDirecciones.asObservable();

  constructor() { }

  //#region Métodos para observables
  saveMisDirecciones(misDirecciones: any[]) {
    localStorage.setItem('misDirecciones', JSON.stringify(misDirecciones));
    // this.misDirecciones.next(misDirecciones);
  }

  addDireccionToMisDirecciones(direccion: any) {
    let misDireccionesLS = this.getMisDirecciones();
    misDireccionesLS.push(direccion);
    this.saveMisDirecciones(misDireccionesLS);
    return true;
  }

  //#endregion

  //REGRESA ARREGLO VACIO SI NO HAY ITEMS EN EL direcciones
  getMisDirecciones() {
    let misDireccionesLS: any;
    misDireccionesLS = localStorage.getItem('misDirecciones');//Se obtienen las direcciones
    //Si misDirecciones es null se le asigna null, si no, se desencripta la key
    let direcciones = ((misDireccionesLS != null) ? JSON.parse(misDireccionesLS) : []);
    return direcciones;
  }

  destroyMisDirecciones() {
    localStorage.removeItem('misDirecciones');
    Swal.fire(
      'Las direcciones fueron eliminadas correctamente',
      'Todas tus dirección se ha eliminado del dispositivo.',
      'success'
    )
    return this.getMisDirecciones();
  }

  deleteDireccion(index: number) {
    let misDireccionesLS = [];
    misDireccionesLS = this.getMisDirecciones();
    if (misDireccionesLS) {
      if (misDireccionesLS.length > 0) {
        misDireccionesLS.splice(index, 1);
        this.saveMisDirecciones(misDireccionesLS);
        if (misDireccionesLS.length == 0) {
          this.destroyMisDirecciones();
        }
      }
    }
    Swal.fire(
      'Direccion eliminada correctamente',
      'Su dirección se ha eliminado del dispositivo.',
      'success'
    )
  }

}
