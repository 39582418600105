export class Direccion {

    idColonia: number;
    nombreColonia: string;
    calle: string;
    numero: string;
    referencia: string;
    entreCalle: string;
    yCalle: string;

    constructor() { }
}