import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  private itemsInCarrito = new BehaviorSubject<number>(0);
  currentItemsInCarrito = this.itemsInCarrito.asObservable();


  private carritoCompras = new BehaviorSubject<any>(null);
  currentCarritoCompras = this.carritoCompras.asObservable();
  private encodePass = "AQUIPIDE";
  private decodePass = "AQUIPIDE";
  constructor() { }


  //#region Métodos para observables
  setCarritoCompras(carrito: any) {
    localStorage.setItem('currentKart', CryptoJS.AES.encrypt(JSON.stringify(carrito), this.encodePass).toString());
    this.itemsInCarrito.next(carrito == null ? 0 : carrito.length);
    this.carritoCompras.next(carrito);
  }

  //#endregion

  //REGRESA ARREGLO VACIO SI NO HAY ITEMS EN EL CARRITO
  getCurrentCarrito() {
    let carritoLS: any;
    carritoLS = localStorage.getItem('currentKart');//Se obtiene el carrito
    //Si el carrito es null se le asigna null, si no, se desencripta la key
    carritoLS = (
      (carritoLS == null || carritoLS == 'null')
        ? null
        : CryptoJS.AES.decrypt(carritoLS, this.decodePass).toString(CryptoJS.enc.Utf8));
    let carrito = ((carritoLS != null) ? JSON.parse(carritoLS) : []);
    this.setCarritoCompras(carrito);
    return carrito;
  }

  addItemToCarrito(producto: any, idTienda: number) {
    let id = this.getTiendaLS();
    let currentTienda = (id != null) ? Number(id) : null;
    if (currentTienda == null) {
      localStorage.setItem('idTienda', CryptoJS.AES.encrypt(JSON.stringify(idTienda.toString()), this.encodePass).toString());
    } else {
      if (currentTienda != idTienda) {
        Swal.fire({
          title: 'Error',
          text: 'Lo sentimos. No es posible agregar productos de tiendas diferentes.\nVerifica tu pedido',
          icon: "error"
        });
        return false;
      }
    }
    let carrito = this.getCurrentCarrito();
    carrito.push(producto);
    this.setCarritoCompras(carrito);
    return true;
  }

  destroyCarrito() {
    localStorage.removeItem('currentKart');
    localStorage.removeItem('idTienda');
    return this.getCurrentCarrito();
  }

  deleteItemInCarrito(index: number) {
    let carrito = [];
    carrito = this.getCurrentCarrito();
    if (carrito) {
      if (carrito.length > 0) {
        carrito.splice(index, 1);
        if (carrito.length == 0) {
          this.destroyCarrito();
        }
      }
    }
    this.setCarritoCompras(carrito);
  }

  getTiendaLS() {
    let id = localStorage.getItem('idTienda');
    id = (
      (id == null || id == 'null')
        ? null
        : CryptoJS.AES.decrypt(id, this.decodePass).toString(CryptoJS.enc.Utf8).toString().replace('"', '').replace('"', '')
    )
    return id;
  }

}
