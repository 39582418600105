import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  activateOptionsInMenu = [
    '/home',
    '/registro',
    '/home/restaurantes',
  ]
  private showOptionMenu = new BehaviorSubject<boolean>(false);
  currentShowOptionMenu = this.showOptionMenu.asObservable();

  constructor() { }

  validateRoute(idRuta: string) {
    if (this.activateOptionsInMenu.toString().indexOf(idRuta) != -1) {
            this.showOptionMenu.next(true);
    } else {
            this.showOptionMenu.next(false);
    }
  }

}
