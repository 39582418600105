<kt-generic-loading></kt-generic-loading>
<!-- Modal Structure -->
<div id="modalDirecciones" class="modal">
    <button (click)="closeDirecciones()" class="modal-close btn-flat" style="position:absolute;top:0;right:0;">
        <i class="material-icons large ">close</i></button>
    <div class="modal-content">
        <h4>Tus direcciones</h4>
        <br>
        <p>Presiona el botón SELECCIONAR para elegir esa dirección</p>
        <p>Presiona el botón ELIMINAR para borrar la dirección permanentemente</p>
        <br>
        <table class="highlight">
            <thead>
                <tr>
                    <th>Dirección</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let direccion of misDireccionesGuardadas; let i = index;">
                    <td>
                        {{composeDireccion(i)}}
                    </td>
                    <td>
                        <div (click)="onDireccionSelected(i)" class="seleccionar-btn cursorPointer">
                            Seleccionar
                        </div>
                    </td>
                    <td>
                        <div (click)="onDireccionDeleted(i)" class="mis-direcciones-btn">
                            Eliminar
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<section class="htc__checkout bg--white section-padding--lg" style="padding-top: 14vh;">
    <!-- Checkout Section Start-->
    <div class="checkout-section">
        <div class="container">
            <div class="row cursorPointer" (click)="goToTienda(tienda.urlPersonalizada)">
                <div class="col s12">
                    <img style="height: 3.5em; width: auto;" src="{{imageUrl+tienda?.logoTienda}}">
                    {{tienda?.nombreComercial}}
                </div>
            </div>
            <div class="row">
                <form [formGroup]="formPedido" (ngSubmit)="enviarPedido()">
                    <div class="col l6 s12 mb-30">
                        <!-- CONFIGURACIÓN DE PEDIDO -->
                        <div class="single-accordion">
                            <a class="accordion-head collapsed">1. Sucursal & Servicio</a>
                            <div id="billing-method" class="collapse">

                                <div class="accordion-body billing-method fix">

                                    <section action="#" class="billing-form checkout-form">
                                        <div class="row">

                                            <div class="col s12">
                                                <mat-form-field class="fullWidth">
                                                    <mat-label>Selecciona la sucursal</mat-label>
                                                    <mat-select required formControlName="idSucursal" name="idSucursal"
                                                        (selectionChange)="onSucursalSelected()">
                                                        <mat-option *ngFor="let sucursal of sucursales"
                                                            [value]="sucursal.idSucursal">
                                                            <span>
                                                                {{sucursal.nombre}},
                                                            </span>
                                                            <span>
                                                                {{sucursal.domicilio.substring(0,sucursal.domicilio.length>30?30:sucursal.domicilio.length)}}
                                                            </span>
                                                        </mat-option>
                                                        <mat-label style="margin-left: 8px">Se muestran sólo las
                                                            sucursales abiertas.
                                                        </mat-label>
                                                    </mat-select>
                                                    <mat-error *ngIf="formPedido.get('idSucursal').errors?.required">
                                                        {{datoRequerido}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12" *ngIf="form.idSucursal.value != null">
                                                <div id="example-radio-group-label">Elige el servicio</div>
                                                <mat-radio-group required class="example-radio-group"
                                                    aria-labelledby="example-radio-group-label"
                                                    formControlName="tipoEnvio">
                                                    <mat-error *ngIf="formPedido.get('tipoEnvio').errors?.required">
                                                        {{datoRequerido}}
                                                    </mat-error>
                                                    <mat-radio-button *ngIf="sucursalSeleccionada?.servicioDomicilio"
                                                        class="example-radio-button" [value]="1">
                                                        Servicio a domicilio
                                                    </mat-radio-button>
                                                    <mat-radio-button *ngIf="sucursalSeleccionada?.ordenaRecoja"
                                                        class="example-radio-button" [value]="0">
                                                        Ordena & Recoge
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col s12"
                                                *ngIf="form.tipoEnvio.value==1 && form.idSucursal.value !==null">
                                                <div class="row centerAlign-horizontal"
                                                    style="display: grid !important;"
                                                    *ngIf="misDireccionesGuardadas && misDireccionesGuardadas?.length > 0">
                                                    <div class="col s12">
                                                        <div (click)="showDirecciones()"
                                                            class="mis-direcciones-btn fullWidth">
                                                            Ver mis direcciones guardadas
                                                        </div>
                                                    </div>
                                                </div>
                                                <mat-form-field class="fullWidth">
                                                    <input class="res__search" required matInput
                                                        style="max-width: -webkit-fill-available;" type="text"
                                                        placeholder="Busca tu colonia..."
                                                        [formControl]="formPedido.controls.nombreColonia"
                                                        (change)="onBlurColonia(true)" [matAutocomplete]="auto"
                                                        (blur)="onBlurColonia()">
                                                    <mat-autocomplete [class]="'res__search'" autoActiveFirstOption
                                                        (optionSelected)="optionSelected($event.option.value)"
                                                        #auto="matAutocomplete" [panelWidth]="auto">
                                                        <mat-option *ngFor="let colonia of filteredColonias | async"
                                                            [value]="colonia.idColonia">
                                                            <span>
                                                                {{colonia.nombre}}
                                                            </span>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <mat-error *ngIf="formPedido.get('idColonia').errors?.required">
                                                        {{datoRequerido}}
                                                    </mat-error>
                                                </mat-form-field>
                                                <span
                                                    *ngIf="coloniaSelected != undefined && coloniaSelected.idColonia == 0"
                                                    style="color: #f94d66; font-size: 0.7em; font-weight: 300;">
                                                    El costo de servicio a domicilio podría variar a tu colonia.
                                                    (siempre le informaremos oportunamente)
                                                </span>
                                            </div>

                                            <ng-container *ngIf="sucursalSeleccionada">
                                                <div class="col s12">
                                                    <mat-slide-toggle formControlName="programarPedido">
                                                        Programar pedido
                                                    </mat-slide-toggle>
                                                    <mat-error
                                                        *ngIf="formPedido.get('programarPedido').errors?.invalidDate">
                                                        Hora y Fecha seleccionados no válidos.
                                                    </mat-error>
                                                </div>

                                                <ng-container *ngIf="formPedido.get('programarPedido').value">
                                                    <div class="col s6">
                                                        <label for="fecha">Fecha</label>
                                                        <input [min]="today" id="fecha" type="date"
                                                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" formControlName="fecha"
                                                            placeholder="Fecha">
                                                        <mat-error *ngIf="formPedido.get('fecha').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                    </div>
                                                    <div class="col s6">
                                                        <label for="fecha">Hora</label>
                                                        <input id="hora" type="time" formControlName="hora"
                                                            placeholder="Hora">
                                                        <mat-error *ngIf="formPedido.get('hora').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                    </div>
                                                </ng-container>

                                            </ng-container>
                                        </div>
                                    </section>

                                </div>
                            </div>
                        </div>
                        <!-- FIN CONFIGURACIÓN DE PEDIDO -->


                        <!-- INFO DE CONTACTO Y DOMICILIO -->
                        <div class="single-accordion">
                            <a class="accordion-head collapsed">2. Contacto & Domicilio</a>
                            <div id="billing-method" class="collapse">

                                <div class="accordion-body billing-method fix">

                                    <section action="#" class="billing-form checkout-form">
                                        <div class="row">
                                            <div class="col s12">
                                                <mat-form-field class="fullWidth">
                                                    <input required minlength="5" maxlength="50" type="text" matInput
                                                        formControlName="nombreCliente" placeholder="Nombre">
                                                    <mat-error *ngIf="formPedido.get('nombreCliente').errors?.required">
                                                        {{datoRequerido}}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="formPedido.get('nombreCliente').errors?.maxlength">
                                                        Máximo número de caracteres
                                                        {{formPedido.get('nombreCliente').errors?.maxlength.actualLength}}/{{formPedido.get('nombreCliente').errors?.maxlength.requiredLength}}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="formPedido.get('nombreCliente').errors?.minlength">
                                                        Minimo número de caracteres
                                                        {{formPedido.get('nombreCliente').errors?.minlength.requiredLength}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12">
                                                <mat-form-field class="fullWidth">
                                                    <mat-label>Teléfono</mat-label>
                                                    <input maxlength="10" required formControlName="telefono" matInput
                                                        placeholder="Ingresa tu número telefónico" type="text">
                                                    <mat-error *ngIf="formPedido.get('telefono').errors?.required">
                                                        {{datoRequerido}}
                                                    </mat-error>
                                                    <mat-error *ngIf="formPedido.get('telefono').errors?.pattern">
                                                        Ingresa un número de teléfono válido
                                                    </mat-error>
                                                    <mat-error *ngIf="formPedido.get('telefono').errors?.minlength">
                                                        Mínimo número de caracteres
                                                        {{formPedido.get('telefono').errors?.minlength.actualLength}}/{{formPedido.get('telefono').errors?.minlength.requiredLength}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <!-- INFORMACIÓN DEL DOMICILIO -->
                                            <ng-container
                                                *ngIf="form.tipoEnvio.value==1 && form.idSucursal.value !==null">
                                                <div class="col s6">
                                                    <mat-form-field class="fullWidth">
                                                        <input maxlength="50" required type="text" matInput
                                                            formControlName="calle" placeholder="Calle">
                                                        <mat-error *ngIf="formPedido.get('calle').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                        <mat-error *ngIf="formPedido.get('calle').errors?.maxlength">
                                                            Máximo número de caracteres
                                                            {{formPedido.get('calle').errors?.maxlength.actualLength}}/{{formPedido.get('calle').errors?.maxlength.requiredLength}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col s6">
                                                    <mat-form-field class="fullWidth">
                                                        <input required maxlength="6" type="text" matInput
                                                            formControlName="numero" placeholder="Número de casa">
                                                        <mat-error *ngIf="formPedido.get('numero').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                        <mat-error *ngIf="formPedido.get('numero').errors?.maxlength">
                                                            Máximo número de caracteres
                                                            {{formPedido.get('numero').errors?.maxlength.actualLength}}/{{formPedido.get('numero').errors?.maxlength.requiredLength}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col s6">
                                                    <mat-form-field class="fullWidth">
                                                        <input maxlength="50" required type="text" matInput
                                                            formControlName="entreCalle" placeholder="Entre calle">
                                                        <mat-error
                                                            *ngIf="formPedido.get('entreCalle').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="formPedido.get('entreCalle').errors?.maxlength">
                                                            Máximo número de caracteres
                                                            {{formPedido.get('entreCalle').errors?.maxlength.actualLength}}/{{formPedido.get('entreCalle').errors?.maxlength.requiredLength}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col s6">
                                                    <mat-form-field class="fullWidth">
                                                        <input maxlength="50" required formControlName="yCalle" matInput
                                                            placeholder="Y Calle" type="text">
                                                        <mat-error *ngIf="formPedido.get('yCalle').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                        <mat-error *ngIf="formPedido.get('yCalle').errors?.maxlength">
                                                            Máximo número de caracteres
                                                            {{formPedido.get('yCalle').errors?.maxlength.actualLength}}/{{formPedido.get('yCalle').errors?.maxlength.requiredLength}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col s12">
                                                    <mat-form-field class="fullWidth">
                                                        <mat-label>Referencia</mat-label>
                                                        <input maxlength="70" required formControlName="referencia"
                                                            matInput
                                                            placeholder="Color de la casa, departamento, lugares cerca, etc"
                                                            type="text">
                                                        <mat-error
                                                            *ngIf="formPedido.get('referencia').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="formPedido.get('referencia').errors?.maxlength">
                                                            Máximo número de caracteres
                                                            {{formPedido.get('referencia').errors?.maxlength.actualLength}}/{{formPedido.get('referencia').errors?.maxlength.requiredLength}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </ng-container>
                                            <!-- END INFORMACIÓN DEL DOMICILIO -->

                                            <!-- FORMA DE PAGO -->
                                            <div class="col s12">
                                                <div id="example-radio-group-label">Forma de pago</div>
                                                <mat-radio-group required class="example-radio-group"
                                                    aria-labelledby="example-radio-group-label"
                                                    formControlName="tipoPago">
                                                    <mat-radio-button class="example-radio-button" [value]="'Efectivo'">
                                                        Efectivo
                                                    </mat-radio-button>
                                                    <div class="col s12" *ngIf="form.tipoPago.value=='Efectivo'">
                                                        <mat-form-field class="fullWidth">
                                                            <input required formControlName="pagoCon" matInput
                                                                placeholder="Pagaré con" type="number">
                                                            <mat-error
                                                                *ngIf="formPedido.get('pagoCon').errors?.required">
                                                                {{datoRequerido}}
                                                            </mat-error>
                                                            <mat-error *ngIf="formPedido.get('pagoCon').errors?.min">
                                                                Tu pago debe ser mayor
                                                                o igual al total
                                                                de tu compra
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <mat-radio-button *ngIf="tienda?.apiKeyBillPocket == 1"
                                                        class="example-radio-button" [value]="'DebitCreditCard'">
                                                        Tarjeta de crédito o débito
                                                    </mat-radio-button>
                                                    <mat-radio-button
                                                        *ngIf="sucursalSeleccionada?.pagoTPV && form.tipoEnvio.value==1"
                                                        class="example-radio-button" [value]="'TPV'">
                                                        TPV (Terminal Punto de Venta)
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <!-- END FORMA DE PAGO -->
                                            <div class="col s12">
                                                <mat-form-field class="fullWidth">
                                                    <input matInput formControlName="comentarios"
                                                        placeholder="Comentarios" type="text">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <!-- FIN INFO DE CONTACTO Y DOMICILIO -->

                        <!-- PAGO CON TARJETA -->
                        <ng-container *ngIf="form.tipoPago.value=='DebitCreditCard'">

                            <div class="single-accordion">
                                <a class="accordion-head collapsed">3. Pago con tarjeta</a>
                                <div id="billing-method" class="collapse">

                                    <div class="accordion-body billing-method fix">

                                        <section action="#" class="billing-form checkout-form">
                                            <div class="row">
                                                <div class="col s12">
                                                    <ngx-payment-card #ngxPaymentCard [IBAN_Text]="'Número de tarjeta'"
                                                        [nameText]="'Nombre del titular'"
                                                        [expirationText]="'Expiración'" [securityCodeText]="''"
                                                        [cardNumberText]="''"
                                                        [IBAN]="formPedido.get('cardNumber').value"
                                                        [cardNumber]="formPedido.get('cardNumber').value"
                                                        [expirationDate]="formPedido.get('cardExp').value"
                                                        [securityCode]="formPedido.get('cardCVV').value"
                                                        [name]="formPedido.get('cardProprietaryName').value?.length > 18 ? formPedido.get('cardProprietaryName').value.substring(0,16)?.toUpperCase() + '...' : formPedido.get('cardProprietaryName').value?.toUpperCase()">
                                                    </ngx-payment-card>
                                                </div>

                                                <div class="col s12" style="padding-top: 3%;">
                                                    <mat-form-field class="fullWidth">
                                                        <input required minlength="15" maxlength="16" type="text"
                                                            autocomplete="cc-number" matInput
                                                            formControlName="cardNumber"
                                                            placeholder="Número de tarjeta">
                                                        <mat-error *ngIf="formPedido.get('cardNumber').errors">
                                                            Ingresa un número de tarjeta válido
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col s12">
                                                    <mat-form-field class="fullWidth">
                                                        <input required type="text" matInput autocomplete="cc-name"
                                                            formControlName="cardProprietaryName"
                                                            placeholder="Nombre del titular">
                                                        <mat-error
                                                            *ngIf="formPedido.get('cardProprietaryName').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col s6 m5" id="expiration_date">
                                                    <mat-form-field appearance="standard" class="fullWidth">
                                                        <mat-label>Fecha de expiración</mat-label>
                                                        <input matInput [min]="minDate" [matDatepicker]="dp"
                                                            formControlName="cardExp" disabled>
                                                        <mat-datepicker-toggle matSuffix [for]="dp">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #dp disabled="false" startView="multi-year"
                                                            (yearSelected)="chosenYearHandler($event)"
                                                            (monthSelected)="chosenMonthHandler($event, dp)">
                                                        </mat-datepicker>
                                                        <mat-error *ngIf="formPedido.get('cardExp').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col s6 m3" style="padding-top: 3%;">
                                                    <mat-form-field class="fullWidth">
                                                        <input required minlength="3" maxlength="4" type="password"
                                                            autocomplete="cc-csc" matInput formControlName="cardCVV"
                                                            placeholder="CVV">
                                                        <mat-error *ngIf="formPedido.get('cardCVV').errors?.required">
                                                            {{datoRequerido}}
                                                        </mat-error>
                                                        <mat-error *ngIf="formPedido.get('cardCVV').errors?.pattern">
                                                            Ingresa un CVV válido
                                                        </mat-error>
                                                        <mat-error *ngIf="formPedido.get('cardCVV').errors?.maxlength">
                                                            Ingresa un CVV válido
                                                        </mat-error>
                                                        <mat-error *ngIf="formPedido.get('cardCVV').errors?.minlength">
                                                            Ingresa un CVV válido
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>

                                                <ng-container *ngIf="isAmericanExpress">
                                                    <div class="col s12">
                                                        <mat-form-field class="fullWidth">
                                                            <input required type="text" matInput
                                                                autocomplete="postal-code" maxlength="9"
                                                                formControlName="amexCustPostalCode"
                                                                placeholder="Código Postal">
                                                            <mat-error
                                                                *ngIf="formPedido.get('amexCustPostalCode').errors?.required">
                                                                {{datoRequerido}}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col s12">
                                                        <mat-form-field class="fullWidth">
                                                            <input required type="text" matInput maxlength="20"
                                                                autocomplete="address-line1"
                                                                formControlName="amexCustAddress"
                                                                placeholder="Dirección">
                                                            <mat-error
                                                                *ngIf="formPedido.get('amexCustAddress').errors?.required">
                                                                {{datoRequerido}}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col s12">
                                                        <mat-form-field class="fullWidth">
                                                            <input required type="text" matInput maxlength="15"
                                                                autocomplete="cc-name"
                                                                formControlName="amexCustFirstName"
                                                                placeholder="Nombre del propietario">
                                                            <mat-error
                                                                *ngIf="formPedido.get('amexCustFirstName').errors?.required">
                                                                {{datoRequerido}}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col s12">
                                                        <mat-form-field class="fullWidth">
                                                            <input required type="text" matInput maxlength="30"
                                                                autocomplete="cc-family-name"
                                                                formControlName="amexCustLastName"
                                                                placeholder="Apellido del propietario">
                                                            <mat-error
                                                                *ngIf="formPedido.get('amexCustLastName').errors?.required">
                                                                {{datoRequerido}}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col s12">
                                                        <mat-form-field class="fullWidth">
                                                            <input required type="text" matInput maxlength="60"
                                                                autocomplete="address-line1"
                                                                formControlName="amexCustEmailAddr"
                                                                placeholder="Correo del propietario">
                                                            <mat-error
                                                                *ngIf="formPedido.get('amexCustEmailAddr').errors?.required">
                                                                {{datoRequerido}}
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="formPedido.get('amexCustEmailAddr').errors?.email">
                                                                Ingresa un email válido
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col s12">
                                                        <mat-form-field class="fullWidth">
                                                            <input required type="text" matInput maxlength="10"
                                                                autocomplete="tel" formControlName="amexCustIdPhoneNbr"
                                                                placeholder="Teléfono del propietario">
                                                            <mat-error
                                                                *ngIf="formPedido.get('amexCustIdPhoneNbr').errors">
                                                                Ingresa un número de teléfono válido
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>

                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- FIN PAGO CON TARJETA -->

                        <div class="col s12" *ngIf="form.tipoEnvio.value == 1 && form.idSucursal.value !==null">
                            <button type="submit"
                                [ngClass]="{btnDisabled:!formPedido.valid || carritoCompras==null || carritoCompras.length==0}"
                                class="food__btn fullWidth show-on-large hide-on-med-and-down">
                                Enviar mi pedido <img class="whatsappImg" src="assets/img/white-whatsapp.png"></button>
                        </div>
                    </div>

                    <!-- Order Details -->
                    <div class="col l6 s12 mb-30">

                        <div class="order-details-wrapper" style="position: relative;">
                            <h2>TU ORDEN <img class="aquipidologo"
                                    src="assets/img/logo_aqui_pide_blanco_sin_letras.png"></h2>
                            <div class="order-details">
                                <ul>
                                    <ng-container *ngIf="carritoCompras!=null && carritoCompras.length>0">
                                        <li>
                                            <p class="strong">Producto</p>
                                            <p class="strong">Total</p>
                                        </li>
                                        <ng-container *ngFor="let producto of carritoCompras; let i = index;">
                                            <li>
                                                <p>{{producto.nombre}}</p>
                                                <p>{{producto.subTotal | currency:"MXN":"symbol"}}</p>
                                            </li>
                                        </ng-container>
                                        <li>
                                            <p class="strong">Subtotal</p>
                                            <p class="strong">{{getSubTotal() | currency:"MXN":"symbol"}}</p>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="carritoCompras==null || carritoCompras.length==0">
                                        <li>
                                            <div class="centerAlign-all" style="height: 99%;">
                                                <i class="material-icons large ">remove_shopping_cart</i>
                                                <br>
                                                <span>Sin productos en tu carrito.</span>
                                            </div>
                                        </li>
                                    </ng-container>
                                    <!-- <li>
                                        <p class="strong">shipping</p>
                                        <p>
                                            <input type="radio" name="order-shipping" id="flat" /><label for="flat">Flat
                                                Rate $
                                                7.00</label><br />
                                            <input type="radio" name="order-shipping" id="free" /><label for="free">Free
                                                Shipping</label>
                                        </p>
                                    </li> -->
                                    <ng-container *ngIf="getCostoEnvio()!=null">
                                        <li style="border-bottom: 0;">
                                            <p class="strong">Envío</p>
                                            <p class="strong">
                                                {{getCostoEnvio() | currency:"MXN":"symbol"}}
                                            </p>
                                        </li>
                                        <li *ngIf="coloniaSelected != undefined && coloniaSelected.idColonia == 0"
                                            style="border-top: 0; padding-top: 0px;">
                                            <p
                                                style="inline-size: auto; color: #f94d66; font-size: 0.7em; font-weight: 300;">
                                                El costo de servicio a domicilio podría variar a tu colonia.
                                                (siempre le
                                                informaremos oportunamente)
                                            </p>
                                        </li>
                                    </ng-container>
                                    <li>
                                        <p class="strong">Total de la orden</p>
                                        <p class="strong">{{getTotal() | currency:"MXN":"symbol"}}</p>
                                    </li>
                                    <li><button type="submit"
                                            [ngClass]="{btnDisabled:!formPedido.valid || carritoCompras==null || carritoCompras.length==0}"
                                            class="food__btn fullWidth">
                                            Enviar mi pedido <img class="whatsappImg"
                                                src="assets/img/white-whatsapp.png"></button></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </form>

            </div>
        </div>
    </div>
</section>