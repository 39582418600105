import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient) { }

  getRequest(url: string) {
    return this.http.delete(`${environment.apiUrl}${url}`, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  }

  uploadImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post(`${environment.uploadImg}/subirImagen.php`, formData, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  }

  postRequest(url: string, body: any) {
    return this.http.post(`${environment.apiUrl}${url}`, body, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  }

  putRequest(url: string, body: any) {
    return this.http.put(`${environment.apiUrl}${url}`, body, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  }

  deleteRequest(url: any) {
    return this.http.delete(`${environment.apiUrl}`, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  }

  displayGenericSuccess(data: any) {
    if (data["estatus"] == 200) {
      Swal.fire({
        title: data["mensaje"],
        icon: "success"
      })
    } else {
      Swal.fire({
        title: data["mensaje"],
        icon: "info"
      })
    }
  }

  displayErrorByRequest(error) {
    let errorText: String = error["error"]["text"];
    let errorJson = {};
    if (errorText != null) {//SI EL ERROR ES ARROJADO POR LA API
      if (errorText.indexOf("{") != -1) {
        errorText = errorText.substring(errorText.indexOf("{") - 1, errorText.indexOf("}") + 1);
         
        if (JSON.parse(errorText.toString())) {
          errorJson = JSON.parse(errorText.toString());
        } else {
          errorJson["mensaje"] = 'Ha ocurrido un error al contactar al servidor.'
        }
      } else {
        errorJson["mensaje"] = 'Ha ocurrido un error en el servidor.'
      }
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error. ' + errorJson["mensaje"] ? errorJson["mensaje"] : 'Lo sentimos, ha ocurrido un error en nuestros servidores.',
        icon: 'error'
      })
    } else { //SI EL ERROR NO ES ARROJADO POR LA API
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error. ' + error["error"]["message"],
        icon: 'error'
      })
    }
  }

  displayInfoMessage(message: string) {
    Swal.fire({
      title: 'Información',
      text: message,
      icon: 'info'
    })
  }

  displayErrorMessage(errorMessage: string) {
    Swal.fire({
      title: 'Error',
      text: errorMessage ? errorMessage : 'Sin mensaje de error',
      icon: 'error'
    });
  }

  displaySuccessMessage(message: string) {
    Swal.fire({
      text: message,
      icon: 'success'
    })
  }

  displaySuccessTitleMessage(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      icon: 'success'
    })
  }

}
