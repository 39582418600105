import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import * as M from 'materialize-css';
import { CarritoService } from 'src/app/services/carrito.service';
import { GeneralService } from 'src/app/services/general-service.service';
import { RoutingService } from 'src/app/services/routing.service';

declare var cartboxToggler;
declare var cartboxClose;
declare var favoritoboxToggler;
declare var favoritoboxClose;
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, AfterContentChecked {
  itemsInCarrito: number = 0;

  constructor(private carritoService: CarritoService,
    public routingService: RoutingService,
    private router: Router,
    private dataService: GeneralService) { }

  smartNavigate() {
    let idTiendaActual = this.carritoService.getTiendaLS();
    if (idTiendaActual != null) {
      this.dataService.postRequest('getTiendaById', { idTienda: idTiendaActual }).toPromise()
        .then(data => {
          this.router.navigateByUrl('/' + data["data"]["urlPersonalizada"]);
        })
        .catch()
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  ngAfterContentChecked(): void {
    this.carritoService.currentItemsInCarrito.subscribe(data => {
      this.itemsInCarrito = data;
    })
  }

  cerrarCarrito() {
    cartboxClose();
  }

  cerrarFavoritos() {
    favoritoboxClose();
  }

  ngAfterViewInit(): void {
    $(document).ready(function () {
      $('.sidenav')
        .sidenav({ edge: 'right' })
        .on('click tap', 'li a', () => {
          $('.sidenav').sidenav('close');
        });
    });
    cartboxToggler();
    favoritoboxToggler();
  }

  ngOnInit() {
    this.carritoService.getCurrentCarrito();
  }

}
