<!-- Start Slider Area -->
<div class="slider__area slider--one">
    <div class="slider__activation--1">
        <!-- Start Single Slide -->
        <div class="slide fullscreen bg-image--15">
            <div class="container">
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="slider__content">
                            <div class="slider__inner">
                                <h2>“AQUÍ PIDE”</h2>
                                <h3 style="color:white">Servicios de comida a domicilio & ordena y recoja</h3>
                                <div class=" slider__input">
                                    <input id="inputSearchTienda" class="res__search"
                                        style="max-width: -webkit-fill-available;" type="text"
                                        placeholder="Busca restaurantes (pizza, hamburguesa, sushi, italiano, etc)..."
                                        [formControl]="searchTienda" [matAutocomplete]="auto">
                                    <mat-autocomplete [class]="'res__search'" [displayWith]="getTiendaText"
                                        (optionSelected)="onTiendaSelected()" autoActiveFirstOption
                                        #auto="matAutocomplete" [panelWidth]="auto">
                                        <mat-option *ngFor="let tienda of filteredTiendas | async" [value]="tienda"
                                            class="hvr-sweep-to-right">
                                            <span><b>{{tienda?.nombreComercial}}</b></span>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <div class="src__btn">
                                        <a (click)="goToMenu()" class="cursor-not-allowed"
                                            [class.cursorPointer]="this.searchTienda.value && this.searchTienda.value['idTienda']"
                                            [class.pulse]="this.searchTienda.value && this.searchTienda.value['idTienda']">
                                            Ir al menú
                                        </a>
                                    </div>
                                </div>
                                <div class="slider__input">
                                    <mat-accordion>
                                        <mat-expansion-panel class="custom-expansion-color">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Nuestros tipos de tiendas
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <app-categories [showAs]="'chips'">
                                            </app-categories>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Single Slide -->
</div>
<!-- End Slider Area -->

<!-- Start Service Area -->
<section class="fd__service__area bg-image--25 section-padding--xlg">
    <div class="container">
        <div class="service__wrapper bg--white">
            <div class="row">
                <div class="col m12 l12">
                    <div class="section__title service__align--left">
                        <p>El proceso de nuestro servicio</p>
                        <h2 class="title__line">¿Cómo funciona?</h2>
                    </div>
                </div>
            </div>
            <div class="row mt-30">
                <!-- Start Single Service -->
                <div class="col s12 m6 l4">
                    <div class="service">
                        <div class="service__title">
                            <div class="ser__icon">
                                <img src="assets/css/ahaar/images/icon/color-icon/1.png" alt="icon image">
                            </div>
                            <h2><a>Elige tu tienda</a></h2>
                        </div>
                        <div class="service__details">
                            <p>Busca & elige tu tienda preferida.</p>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->
                <!-- Start Single Service -->
                <div class="col s12 m6 l4">
                    <div class="service">
                        <div class="service__title">
                            <div class="ser__icon">
                                <img src="assets/css/ahaar/images/icon/color-icon/2.png" alt="icon image">
                            </div>
                            <h2><a>Crea tu pedido</a></h2>
                        </div>
                        <div class="service__details">
                            <p>Elige lo que desees & crea tu pedido.</p>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->
                <!-- Start Single Service -->
                <div class="col s12 m6 l4">
                    <div class="service">
                        <div class="service__title">
                            <div class="ser__icon">
                                <img src="assets/css/ahaar/images/icon/color-icon/3.png" alt="icon image">
                            </div>
                            <h2><a>Solicita tu pedido</a></h2>
                        </div>
                        <div class="service__details">
                            <p>Solicita tu órden a tu tienda preferida fácilmente vía Whatsapp <img
                                    src="assets/img/whatsapp.png" class="whatsappImg">.</p>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->
            </div><br>
            <div class="row">
                <a routerLink="/pricing" style="font-size: 1.2em; text-decoration: wavy;">
                    <span class="red-text-color">¿Tienes un negocio? Vende GRATIS</span><br>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Service Area -->