<kt-generic-loading></kt-generic-loading>
<section class="htc__checkout bg--white section-padding--lg" style="padding-top: 14vh;  min-height:  79vh;">
    <div class="container">
        <div class="row" >
            <div class="col-12 col-md-6" style="text-align: center;">
                Tu pedido se encuentra: <br><br>
                <span style="font-size: 25px;">
                    {{estatusPedido}}                
                </span>
            </div>
        </div>
        <!-- <div class="row">
            Detalles del pedido:
            <div class="col l6 s12 mb-30">

                <div class="order-details-wrapper" style="position: relative;">
                    <h2>TU ORDEN <img class="aquipidologo"
                            src="assets/img/logo_aqui_pide_blanco_sin_letras.png"></h2>
                    <div class="order-details">
                        <ul>
                            <ng-container *ngIf="carritoCompras!=null && carritoCompras.length>0">
                                <li>
                                    <p class="strong">Producto</p>
                                    <p class="strong">Total</p>
                                </li>
                                <ng-container *ngFor="let producto of carritoCompras; let i = index;">
                                    <li>
                                        <p>{{producto.nombre}}</p>
                                        <p>{{producto.subTotal | currency:"MXN":"symbol"}}</p>
                                    </li>
                                </ng-container>
                                <li>
                                    <p class="strong">Subtotal</p>
                                    <p class="strong">{{getSubTotal() | currency:"MXN":"symbol"}}</p>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="carritoCompras==null || carritoCompras.length==0">
                                <li>
                                    <div class="centerAlign-all" style="height: 99%;">
                                        <i class="material-icons large ">remove_shopping_cart</i>
                                        <br>
                                        <span>Sin productos en tu carrito.</span>
                                    </div>
                                </li>
                            </ng-container>
                            <!-- <li>
                                <p class="strong">shipping</p>
                                <p>
                                    <input type="radio" name="order-shipping" id="flat" /><label for="flat">Flat
                                        Rate $
                                        7.00</label><br />
                                    <input type="radio" name="order-shipping" id="free" /><label for="free">Free
                                        Shipping</label>
                                </p>
                            </li> ->
                            <ng-container *ngIf="getCostoEnvio()!=null">
                                <li style="border-bottom: 0;">
                                    <p class="strong">Envío</p>
                                    <p class="strong">
                                        {{getCostoEnvio() | currency:"MXN":"symbol"}}
                                    </p>
                                </li>
                                <li *ngIf="coloniaSelected != undefined && coloniaSelected.idColonia == 0"
                                    style="border-top: 0; padding-top: 0px;">
                                    <p
                                        style="inline-size: auto; color: #f94d66; font-size: 0.7em; font-weight: 300;">
                                        El costo de servicio a domicilio podría variar a tu colonia.
                                        (siempre le
                                        informaremos oportunamente)
                                    </p>
                                </li>
                            </ng-container>
                            <li>
                                <p class="strong">Total de la orden</p>
                                <p class="strong">{{getTotal() | currency:"MXN":"symbol"}}</p>
                            </li>
                            <li><button type="submit"
                                    [ngClass]="{btnDisabled:!formPedido.valid || carritoCompras==null || carritoCompras.length==0}"
                                    class="food__btn fullWidth">
                                    Enviar mi pedido <img class="whatsappImg"
                                        src="assets/img/white-whatsapp.png"></button></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div> -->
    </div>
</section>