import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general-service.service';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {
  tiendas: any;
  filteredTiendas: Observable<any[]>;;
  searchTienda = new FormControl();

  constructor(private dataService: GeneralService, private router: Router) { }

  goToMenu() {
    if (this.searchTienda.value != null && this.searchTienda.value['urlPersonalizada'] != null) {
      this.router.navigate(['/', this.searchTienda.value['urlPersonalizada']]);
      return true;
    }
  }

  onTiendaSelected() {
    document.getElementById('inputSearchTienda').blur();
  }

  getTiendaText(tiendaSelected) {
    return tiendaSelected ? tiendaSelected.nombreComercial : "";
  }

  getTiendasConsumidor() {
    this.dataService.postRequest("getTiendasConsumidor", {}).toPromise().then(data => {
      this.tiendas = data["data"];
      this.filteredTiendas = this.searchTienda.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTiendas(value))
      );
    }).catch((e) => {
       
    }).finally();
  }

  private _filterTiendas(wordToSearch: string) {
    const filterValue = wordToSearch != null ? wordToSearch.toString().toLowerCase() : '';
    // return this.tiendas.filter(tienda =>
    //   (JSON.stringify(tienda).toString().toLowerCase().indexOf(filterValue) != -1));
    return this.tiendas.filter(tienda =>
      (tienda["nombreComercial"].toLowerCase().indexOf(filterValue) != -1
        || (tienda["etiquetas"].filter(etiqueta => etiqueta["nombreEtiqueta"].toLowerCase().indexOf(filterValue) != -1)).length > 0));
  }

  ngOnInit() {
    this.getTiendasConsumidor();
  }

}
