<!-- Start Food Category (default) -->
<section class="food__category__area bg--white section-padding--lg"
    *ngIf="girosComerciales && girosComerciales.length>0 && showAs=='grid'">
    <div class="container">
        <div class="row">
            <div class="col m12 l12">
                <div class="section__title service__align--left">
                    <h2 class="title__line">Tipos de tiendas</h2>
                </div>
            </div>
        </div>
        <div class="food__category__wrapper mt--40">
            <div class="row">
                <ng-container *ngFor="let giroComercial of girosComerciales;">
                    <!-- Start Single Category -->
                    <div class="col m6 s12 cursorPointer" (click)="navigateToRestaurante(giroComercial.idGiroComercial)"
                        [ngStyle]="{'display':giroComercial.showItem ? 'block' : 'none'}"
                        *ngIf="giroComercial.showItem">
                        <div class="food__item foo">
                            <div class="food__thumb">
                                <a>
                                    <img [src]="imgUrl + giroComercial.imagen"
                                        onError="this.src='assets/css/ahaar/images/bg/bg-categoria-default.png'"
                                        alt="{{giroComercial.descripcion}}">
                                </a>
                            </div>
                            <div class="food__title">
                                <h2><a>{{giroComercial.descripcion}}</a></h2>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Category -->
                </ng-container>
            </div>
        </div>
    </div>
</section>
<!-- End Food Category -->

<!-- Se muestran categorías como chips -->

<section *ngIf="girosComerciales && girosComerciales.length>0 && showAs=='chips'">
    <ng-container *ngFor="let giroComercial of girosComerciales;">
        <div class="chip" (click)="navigateToRestaurante(giroComercial.idGiroComercial)" *ngIf="giroComercial.showItem">
            <img [src]="imgUrl + giroComercial.imagen"
                onError="this.src='assets/css/ahaar/images/bg/bg-categoria-default.png'"
                alt="{{giroComercial.descripcion}}">
            {{giroComercial.descripcion}}
        </div>
    </ng-container>
</section>

<!-- FIN Se muestran categorías como chips -->