<kt-generic-loading></kt-generic-loading>
<section class="htc__checkout bg--white section-padding--lg" style="padding-top: 14vh;  min-height:  79vh;">
    <div *ngIf="!pedidoFinalized" class="container">
        <div class="row">
            <div class="col-12 col-md-6" style="text-align: left;" [innerHtml]="text">
                
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="fullWidth">
                    <mat-label>Estatus final</mat-label>
                    <mat-select required [(ngModel)]="reason">
                        <mat-option *ngFor="let opt of options" [value]="opt.id"> {{opt.razon}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <form action="" >
                    <button type="submit" (click)="validarCodigo()"
                        class="food__btn fullWidth">
                        Finalizar pedido </button>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="pedidoFinalized" class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-10">
                <h2>El pedido ha finalizado</h2>
            </div>
        </div>
    </div>
</section>