import { ProductoCarrito } from './ProductoCarrito'
import { Combo } from './Combo';
import { Direccion } from './Direccion';
import { BankCard } from './BankCard';
import { Moment } from 'moment';
import * as moment from 'moment';

export class Pedido {
    id?: number;
    text: string;
    status?: number;
    idTienda: number;
    nombreTienda: string;
    idSucursal: number;
    nombreSucursal: string;
    programarPedido: boolean;
    fecha: string | Date;
    hora: string;
    nombreCliente: string;
    telefono: string;
    comentarios: string;
    tipoEnvio: number;
    referencia: string;
    costoPedido: number;
    costoEnvio: number;
    costoTotal: number;
    estatus: boolean;
    detalleProducto: ProductoCarrito[];
    detalleCombo: Combo[];
    tipoPago: string | "Efectivo" | "TPV" | "DebitCreditCard";
    pagoCon: number | string;
    direccion: Direccion = new Direccion();
    cardData: BankCard = new BankCard();
    billPocketTicketURL: any;

    clearModel() {
        this.idTienda = null;
        this.nombreTienda = null;
        this.idSucursal = null;
        this.nombreSucursal = null;
        this.programarPedido = false;
        this.fecha = null;
        this.hora = null;
        this.nombreCliente = null;
        this.telefono = null;
        this.comentarios = '';
        this.tipoEnvio = null;
        this.direccion.idColonia = null;
        this.direccion.nombreColonia = null;
        this.direccion.calle = null;
        this.direccion.numero = null;
        this.direccion.referencia = null;
        this.direccion.entreCalle = null;
        this.direccion.yCalle = null;
        this.costoPedido = null;
        this.costoEnvio = null;
        this.costoTotal = null;
        this.estatus = true;
        this.detalleProducto = [];
        this.detalleCombo = [];
        this.tipoPago = 'Efectivo';
        this.pagoCon = null;
        this.cardData.cardCVV = null;
        this.cardData.cardExp = null;
        this.cardData.cardNumber = '';
        this.cardData.cardProprietaryName = null;
        this.billPocketTicketURL = null;
    }

    fillModel(pedido: any, productos: any, combos: any) {
        this.idTienda = pedido["idTienda"];
        this.nombreTienda = pedido["nombreTienda"];
        this.idSucursal = pedido["idSucursal"];
        this.nombreSucursal = pedido["nombreSucursal"];
        this.programarPedido = pedido["programarPedido"];
        this.fecha = pedido["fecha"] || moment(new Date()).local().format("YYYY-MM-DD");
        this.hora = pedido["hora"] || moment(new Date()).local().format("hh:mm:ss a");
        this.nombreCliente = pedido["nombreCliente"];
        this.telefono = pedido["telefono"];
        this.comentarios = ((pedido["comentarios"] != null && pedido["comentarios"].length > 0) ? pedido["comentarios"] : 'NA');
        this.tipoEnvio = pedido["tipoEnvio"];
        this.direccion.idColonia = pedido["idColonia"];
        this.direccion.nombreColonia = pedido["nombreColonia"];
        this.direccion.calle = pedido["calle"];
        this.direccion.numero = pedido["numero"];
        this.direccion.referencia = pedido["referencia"];
        this.direccion.entreCalle = pedido["entreCalle"];
        this.direccion.yCalle = pedido["yCalle"];
        this.costoPedido = pedido["costoPedido"];
        this.costoEnvio = pedido["costoEnvio"];
        this.costoTotal = pedido["costoTotal"];
        this.estatus = true;
        this.detalleProducto = productos;
        this.detalleCombo = combos;
        this.tipoPago = pedido["tipoPago"];
        this.pagoCon = pedido["pagoCon"] != null ? pedido["pagoCon"] : 'No definido';
    }

    asJson(){
        return JSON.parse(JSON.stringify(this));
    }

}