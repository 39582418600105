import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubheaderComponent } from './layout/subheader/subheader.component';
import { TiendaMenuComponent } from './components/tienda-menu/tienda-menu.component';
import { HomeComponent } from './layout/home/home.component';
import { PedidoComponent } from './components/pedido/pedido.component';
import { RestaurantesComponent } from './components/restaurantes/restaurantes.component';
import { FormTiendaComponent } from './components/tienda/form-tienda/form-tienda.component';
import { LandingComponent } from './components/landing/landing.component';
import { ComoFuncionaComponent } from './components/como-funciona/como-funciona.component';
import { SaberMasComponent } from './components/saber-mas/saber-mas.component';
import { PedidoEstatusComponent } from './components/pedido-estatus/pedido-estatus.component';
import { FinalizarPedidoComponent } from './components/finalizar-pedido/finalizar-pedido.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'pricing',
    component: LandingComponent,
  },
  {
    path: 'registro',
    component: FormTiendaComponent,
  },
  {
    path: 'como-funciona',
    component: ComoFuncionaComponent,
  },
  {
    path: 'saber-mas',
    component: SaberMasComponent,
  },
  {
    path: ':urlPersonalizada',
    component: TiendaMenuComponent,
  },
  {
    path: ':urlPersonalizada/favorite/:index',
    component: TiendaMenuComponent,
  },
  {
    path: 'home/hacer-pedido',
    component: PedidoComponent,
  },
  {
    path: 'home/estatus-pedido/:id',
    component: PedidoEstatusComponent,
  },
  {
    path: 'home/finalizar-pedido/:id',
    component: FinalizarPedidoComponent,
  },
  {
    path: 'home/restaurantes',
    component: RestaurantesComponent,
  },
  {
    path: 'home/restaurantes/:idGiroComercial',
    component: RestaurantesComponent,
  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
