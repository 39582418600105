<!-- Start Footer Area -->
<footer class="footer__area footer--1">
    <section class="row footer center" style="margin-bottom: 0;">
        <div class="col s12 m4" style="padding-top:12px">
            <a routerLink="/home"><b>AQUÍ PIDE</b></a>
            <p>
                Horario de Atención: Lunes a Viernes de
                9:00-15:00</p>
            <p style="font-size: 0.75em">Todos los derechos reservados 2021</p>
            <p style="font-size: 0.75em">Versión {{versionCode}}</p>
        </div>
        <div class="col s12 m4 hide-on-small-only" style="padding-top:12px">
            <a class="avisoPrivacidad btn" target="_blank"
                href="assets/files/QXZpc28gZGUgUHJpdmFjaWRhZCBBcXVpUGlkZSBBZ29zdG8gZGUgMjAyMQ==.pdf">
                Aviso de privacidad
            </a><br><br>
            <a class="avisoPrivacidad btn" target="_blank"
                href="assets/files/VMOpcm1pbm9zIFkgQ29uZGljaW9uZXMgRGUgVXNvIEFxdWlQaWRl.pdf">
                Términos y condiciones
            </a>
        </div>
        <div class="col s12 m4" style="padding-top:12px;">
            <div class="col s12">
                <a target="_blank"
                    href="https://api.whatsapp.com/send?phone=+526681584547&text=¡Hola!, me gustaría obtener más información acerca de aquipide.com."><img
                        src="assets/img/white-whatsapp.png" class="whatsappImg">&nbsp;&nbsp;66 81 58 45 47</a>
            </div>
            <div class="col s12">
                <a target="_blank" href="https://facebook.com/AquiPide"><img src="assets/img/logoFace.svg"
                        class="whatsappImg">&nbsp;&nbsp;AquiPide</a>
            </div>
            <div class="col s12">
                <a target="_blank" href="https://instagram.com/aquipide"><img src="assets/img/logoInsta.svg"
                        class="whatsappImg">&nbsp;&nbsp;@AquiPide</a>
            </div>
            <div class="col s12">
                <a class="centerAlign-all" href="mailto:afiliados@aquipide.com"><i
                        class="material-icons">email</i>&nbsp;&nbsp;afiliados@aquipide.com</a>
            </div>
        </div>
        <div class="col s12 m4 show-on-small-only hide-on-med-and-up" style="padding-top:12px">
            <a class="avisoPrivacidad btn" target="_blank"
                href="assets/files/QXZpc28gZGUgUHJpdmFjaWRhZCBBcXVpUGlkZSBBZ29zdG8gZGUgMjAyMQ==.pdf">
                Aviso de privacidad
            </a>
            <br>
            <a style="margin-top: 1em;" class="avisoPrivacidad btn" target="_blank"
                href="assets/files/VMOpcm1pbm9zIFkgQ29uZGljaW9uZXMgRGUgVXNvIEFxdWlQaWRl.pdf">
                Términos y condiciones
            </a>
        </div>
    </section>
</footer>
<!-- End Footer Area -->