import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { doc, DocumentData, DocumentSnapshot, getFirestore, onSnapshot } from 'firebase/firestore';
import { GeneralService } from 'src/app/services/general-service.service';

@Component({
  selector: 'app-pedido-estatus',
  templateUrl: './pedido-estatus.component.html',
  styleUrls: ['./pedido-estatus.component.scss']
})
export class PedidoEstatusComponent implements OnInit {
  id: number;
  pedido: any;
  estatusPedido: string;

  textStatus(status: number): string {
    let message = "";
    switch (status) {
      case 1:
        message = 'En revision';
        break;
      case 2:
        message = 'En preparacion';
        break;
      case 3:
        message = 'En reparto';
        break;
      case 4:
        message = this.pedido.reason
    }
    return message;
  }

  constructor(private dataService: GeneralService, private route: ActivatedRoute) {
    const db = getFirestore();
    this.route.paramMap.subscribe(async params => {
      let [idTienda, idSucursal, idPedido, ...rest] = params.get('id').split("-");
      let docRef = doc(db, idTienda, "sucursales", idSucursal, idPedido);
      onSnapshot(doc(db, idTienda, "sucursales", idSucursal, idPedido),(doc: DocumentSnapshot<DocumentData>)=>{
        if(doc.exists()){
          this.pedido = doc.data();
          this.estatusPedido = this.textStatus(this.pedido.status).toUpperCase();
        }
      })
    });

  }

  ngOnInit(): void {
  }

}
