<kt-generic-loading></kt-generic-loading>
<!-- <section class="htc__checkout bg--white section-padding--lg">
    <div class="checkout-section"> -->
<div class="container" style="margin-top: 13vh">
    <div class="row">
        <form [formGroup]="formTienda">
            <div class="row" style="margin: 0 16px;">
                <!--PARTE LATERAL IZQUIERDA-->
                <div class="col s12 l6">
                    <br>
                    <div class="row">
                        <div class="col s12">
                            <h4>Datos generales de la empresa</h4>
                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-flag">&nbsp;&nbsp;</mat-icon>
                                <mat-label>País</mat-label>
                                <mat-select formControlName="idPais">
                                    <mat-option>Selecciona el país</mat-option>
                                    <mat-option *ngFor="let pais of paises" [value]="pais?.idPais">
                                        {{pais?.claveInternacional}} - {{pais?.nombre}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formTienda.get('idPais').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-briefcase">&nbsp;&nbsp;</mat-icon>
                                <mat-label>Giro comercial</mat-label>
                                <mat-select formControlName="giroComercial">
                                    <mat-option>Selecciona el giro comercial</mat-option>
                                    <mat-option *ngFor="let giro of girosComerciales" [value]="giro?.idGiroComercial">
                                        {{giro?.descripcion}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formTienda.get('giroComercial').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-building">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="100" matInput placeholder="Nombre Comercial de la Empresa" formControlName="nombreComercial"
                                    required>
                                <mat-error *ngIf="formTienda.get('nombreComercial').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('nombreComercial').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('nombreComercial').errors?.maxlength.actualLength}}/{{formTienda.get('nombreComercial').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-registered">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="13" matInput placeholder="R F C" formControlName="rfc" required>
                                <mat-error *ngIf="formTienda.get('rfc').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('rfc').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('rfc').errors?.maxlength.actualLength}}/{{formTienda.get('rfc').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-phone">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="10" type="text" matInput placeholder="Teléfono de la empresa"
                                    formControlName="telefono" required>
                                <mat-error *ngIf="formTienda.get('telefono').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('telefono').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('telefono').errors?.maxlength.actualLength}}/{{formTienda.get('telefono').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-envelope">&nbsp;&nbsp;</mat-icon>
                                <input type="email" matInput placeholder="Correo de la empresa" formControlName="correo" required>
                                <mat-error *ngIf="formTienda.get('correo').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('correo').errors?.email">
                                    Introduzca un correo válido
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-external-link-alt">&nbsp;&nbsp;</mat-icon>
                                <input minlength="4" maxlength="20" onkeydown="if( event.keyCode == 32) return false;"
                                    type="text" matInput placeholder="Url Personalizada (Nombre comercial empresa)"
                                    formControlName="urlPersonalizada" required>
                                <mat-error *ngIf="formTienda.get('urlPersonalizada').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('urlPersonalizada').errors?.minlength">
                                    La url debe tener mínimo
                                    {{formTienda.get('urlPersonalizada').errors?.minlength.requiredLength}}
                                    caracteres
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('urlPersonalizada').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('urlPersonalizada').errors?.maxlength.actualLength}}/{{formTienda.get('urlPersonalizada').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>
                            <div class="fullWidth" style="text-align: center;">
                                <span>https://aquipide.com/{{formTienda.get('urlPersonalizada').value}}</span>
                            </div>

                            <br><br><br>
                            <h4>Datos del administrador</h4><br>
                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-user-tie">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="100" matInput placeholder="Nombre administrador"
                                    formControlName="nombreAdministrador" required>
                                <mat-error *ngIf="formTienda.get('nombreAdministrador').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('nombreAdministrador').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('nombreAdministrador').errors?.maxlength.actualLength}}/{{formTienda.get('nombreAdministrador').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-phone">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="10" matInput type="text" placeholder="Teléfono administrador"
                                    formControlName="telefonoAdministrador" required>
                                <mat-error *ngIf="formTienda.get('telefonoAdministrador').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('telefonoAdministrador').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('telefonoAdministrador').errors?.maxlength.actualLength}}/{{formTienda.get('telefonoAdministrador').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <mat-icon matPrefix class="fas fa-envelope">&nbsp;&nbsp;</mat-icon>
                                <input matInput type="email" placeholder="Correo administrador"
                                    formControlName="correoAdministrador" required>
                                <mat-error *ngIf="formTienda.get('correoAdministrador').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('correoAdministrador').errors?.email">
                                    Introduzca un correo válido
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col s12">
                                <span mat-icon-button matPrefix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon matPrefix class="{{hide? 'fas fa-eye' : 'fas fa-eye-slash'}}">
                                        &nbsp;&nbsp;
                                    </mat-icon>
                                </span>
                                <input type="password" matInput placeholder="Contraseña" formControlName="contrasena"
                                    [type]="hide ? 'password' : 'text'" required>
                                <mat-error *ngIf="formTienda.get('contrasena').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('contrasena').errors?.minlength">
                                    La contraseña debe tener mínimo
                                    {{formTienda.get('contrasena').errors?.minlength.requiredLength}}
                                    caracteres
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
                <!--FIN PARTE LATERAL IZQUIERDA-->

                <!--PARTE LATERAL DERECHA-->
                <div class="col s12 l6">
                    <div class="col s12">
                        <div class="row centerAlign-all">
                            <img *ngIf="loadingImage==false" class="circleImg"
                                src="{{apiImg}}{{formTienda.controls['logoTienda'].value}}"
                                onError="this.src='assets/img/noimage.png'">
                            <img *ngIf="loadingImage==true" class="circleImg" src="assets/img/loading.gif">
                        </div>
                        <div class="row centerAlign-all">
                            <div class="col">
                                <mat-form-field>
                                    <mat-icon matSuffix *ngIf="removableInput.empty" class="fas fa-image">
                                    </mat-icon>
                                    <mat-icon matSuffix *ngIf="!removableInput.empty" class="fas fa-close"
                                        (click)="removableInput.clear($event);formTienda.controls['logoTienda'].setValue('')">
                                    </mat-icon>
                                    <ngx-mat-file-input #removableInput formControlName="logoInput"
                                        placeholder="Imagen de su tienda" [accept]="'.png'"
                                        (change)="fileChangeEvent($event)" [errorStateMatcher]="matcher">
                                    </ngx-mat-file-input>
                                    <!-- <mat-error *ngIf="formTienda?.controls?.logoInput?.errors?.extension">
                                        Por favor, selecciona la imagen de la Tienda (Sólo imagenes con
                                        extensión .png)
                                    </mat-error>
                                    <mat-error *ngIf="formTienda?.controls?.logoInput?.errors?.maxContentSize">
                                        El tamaño de la imagen no debe de superar los
                                        {{formTienda?.controls?.logoInput?.errors?.maxContentSize?.maxSize | byteFormat}}
                                        ({{formTienda?.controls?.logoInput?.errors?.maxContentSize?.actualSize | byteFormat}}).
                                    </mat-error> -->
                                    <mat-error *ngIf="formTienda?.controls?.logoTienda?.errors?.required" style="margin-top: 14px;">
                                        {{datoRequerido}}
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="formTienda?.controls?.logoTienda?.errors?.required || formTienda?.controls?.logoInput?.errors?.maxContentSize" style="margin-top: -20px;">
                                    <span style="color: red; font-size: small; font-weight: 400;"> * Formato PNG, 1MB.</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <h4>Etiquetas </h4>
                    <mat-form-field class="col s12">
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let etiqueta of selectedEtiquetas" (removed)="remove(etiqueta)">
                                {{etiqueta.nombreEtiqueta}}
                                <i class="material-icons" matChipRemove>cancel</i>
                            </mat-chip>
                            <input #tagInput placeholder="Nueva etiqueta..." [matChipInputFor]="chipList"
                                [formControl]="tagCtrl" [matAutocomplete]="auto"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            (optionSelected)="selected($event)">
                            <mat-option *ngFor="let tag of filteredEtiquetas | async" [value]="tag">
                                {{tag.nombreEtiqueta}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field> -->


                    <br><br><br>
                    <h4>Métodos de pago</h4>
                    <br>
                    <span class="col s12 centerAlign-vertical">
                        <mat-label>Pago en efectivo</mat-label>
                        <mat-slide-toggle formControlName="pagoEfectivo">
                        </mat-slide-toggle>
                    </span><br><br>

                    <span class="col s12 centerAlign-vertical">
                        <mat-label>Pago TPV</mat-label>
                        <mat-slide-toggle formControlName="pagoTPV">
                        </mat-slide-toggle>
                    </span>

                    <br><br><br>
                    <h4>Redes sociales</h4>
                    <mat-form-field class="col s12">
                        <mat-icon matPrefix class="fab fa-facebook">&nbsp;&nbsp;</mat-icon>
                        <input maxlength="50" matInput placeholder="https://www.facebook.com/AquiPide" formControlName="facebook">
                        <mat-error *ngIf="formTienda.get('facebook').errors?.maxlength">
                            Máximo número de caracteres
                            {{formTienda.get('facebook').errors?.maxlength.actualLength}}/{{formTienda.get('facebook').errors?.maxlength.requiredLength}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col s12">
                        <mat-icon matPrefix class="fab fa-twitter">&nbsp;&nbsp;</mat-icon>
                        <input maxlength="50" matInput placeholder="https://www.twitter.com/AquiPide" formControlName="twitter">
                        <mat-error *ngIf="formTienda.get('twitter').errors?.maxlength">
                            Máximo número de caracteres
                            {{formTienda.get('twitter').errors?.maxlength.actualLength}}/{{formTienda.get('twitter').errors?.maxlength.requiredLength}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col s12">
                        <mat-icon matPrefix class="fab fa-instagram">&nbsp;&nbsp;</mat-icon>
                        <input maxlength="50" matInput placeholder="https://www.instagram.com/AquiPide" formControlName="instagram">
                        <mat-error *ngIf="formTienda.get('instagram').errors?.maxlength">
                            Máximo número de caracteres
                            {{formTienda.get('instagram').errors?.maxlength.actualLength}}/{{formTienda.get('instagram').errors?.maxlength.requiredLength}}
                        </mat-error>
                    </mat-form-field>

                    <p>
                        Al usar la aplicación AQUI PIDE he leido el <a
                            href="https://aquipide.com/Politica%20de%20privacidad%20AQUIPIDE.pdf" target="_blank"
                            rel="noopener noreferrer">acuerdo de privacidad</a> y acepto los <a
                            href="https://aquipide.com/Terminos%20y%20Condiciones%20AQUIPIDE.pdf" target="_blank"
                            rel="noopener noreferrer">términos y condiciones</a>.
                    </p>

                    <span class="col s12 m6">
                        <mat-checkbox formControlName="terminos">Acepto los términos y condiciones
                        </mat-checkbox>
                    </span>
                    <br><br>
                    <div class="centerAlign-all">
                        <div class="col s12">
                            <button style="width:100%" 
                                [ngClass]="{btnDisabled:!formTienda.valid}"
                                [class.spinner]="loading"
                                class="col s4 food__btn btnDisabled" (click)="saveTienda()">
                                <i class="fas fa-save"></i>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
                <!--FIN PARTE LATERAL DERECHA-->
            </div>
        </form>
    </div>
</div>
<!-- </div>
</section> -->