import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ScrollingService } from '../services/scrolling.service';

@Directive({
  selector: '[appScrollspy]'
})
export class ScrollspyDirective {

  @Input() public spiedTags = [];
  @Input() public idContainer = "";
  @Output() public sectionChange = new EventEmitter<string>();
  private currentSection: string;
  private vh = (33 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) / 100;
  private isSpyEnabled: boolean;

  constructor(private _el: ElementRef, private scrollService: ScrollingService) {
    this.scrollService.enabledSpy$.subscribe(spyStatus => {
      this.isSpyEnabled = spyStatus;
    });
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (!this.isSpyEnabled) {
      return;
    }
    let currentSection: string;
    const children = this._el.nativeElement.querySelector('#' + this.idContainer).children;
    const scrollTop = event.target.scrollTop;
    const parentOffset = event.target.offsetTop;
    for (let i = 0; i < children.length; i++) {
      const element = children[i];
      if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
        if ((element.offsetTop - parentOffset - this.vh) <= (scrollTop)) {
          currentSection = element.id;
        }
      }
    }
    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
      this.sectionChange.emit(this.currentSection);
    }
  }

}
