import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillPocketService {

  constructor(private http: HttpClient) { }

  tokenizeCard(body: any) {
    return this.http.post(`${environment.apiUrl}storeCard`, body/*, { headers: new HttpHeaders().append('Access-Control-Allow-Origin', "*") }*/);
  }

  getMyIpAdress() {
    return this.http.get("https://api.ipify.org?format=json");
  }

  processTnx(body: any) {
    return this.http.post(`${environment.apiUrl}txn`, body);
  }

}
