import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CarritoService } from 'src/app/services/carrito.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss']
})
export class CarritoComponent implements OnInit {

  carritoCompras: any = [];
  imageUrl = environment.imageUrlBase;
  @Output()
  onHacerPedidoPressed = new EventEmitter<string>();
  constructor(private carritoService: CarritoService, private router: Router) { }


  navigateToPedido() {
    this.onHacerPedidoPressed.emit('');
    this.router.navigateByUrl('home/hacer-pedido');
  }

  getTotal() {
    return this.carritoCompras.map(e => e.subTotal).reduce((acc, value) => acc + value, 0);
  }

  deleteProductoEnCarrito(index: number) {
    this.carritoService.deleteItemInCarrito(index);
  }

  ngOnInit() {
    this.carritoService.getCurrentCarrito();
    this.carritoService.currentCarritoCompras.subscribe(carrito => {
      this.carritoCompras = carrito;
    })
  }

}
