import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CarritoService } from 'src/app/services/carrito.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Pedido } from 'src/app/models/Pedido';
import { GeneralService } from 'src/app/services/general-service.service';
import { WhatsappService } from 'src/app/services/whatsapp.service';
import { ProductoCarrito } from 'src/app/models/ProductoCarrito';
import { Combo } from 'src/app/models/Combo';
import Swal from 'sweetalert2';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { map, startWith } from 'rxjs/operators';
import { DireccionesService } from 'src/app/services/direcciones.service';
import { Direccion } from 'src/app/models/Direccion';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { PayTransaction } from 'src/app/models/PayTransaction';
import { BillPocketService } from 'src/app/services/bill-pocket-service.service';
import { horarios } from '../constants/horarios';
import { doc, getFirestore, setDoc } from "firebase/firestore";

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

declare var $: any;
@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class PedidoComponent implements OnInit, AfterViewInit {
  carritoCompras: any = [];
  imageUrl = environment.imageUrlBase;
  formPedido: FormGroup;
  pedido: Pedido;
  sucursales: any = [];
  sucursalSeleccionada: any;
  idTienda: any;
  colonias: any;
  coloniaSelected: any;
  tienda: any;
  datoRequerido: String = "Este dato es requerido";
  filteredColonias: any;
  urlImgs = environment.imageUrlBase;
  misDireccionesGuardadas: Direccion[];
  minDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  today = _moment(new Date()).local().format("YYYY-MM-DD");
  isAmericanExpress = false;

  constructor(
    private carritoService: CarritoService,
    private router: Router,
    private fb: FormBuilder,
    public scrollingService: ScrollingService,
    private dataService: GeneralService,
    private whatsappService: WhatsappService,
    private billPocketService: BillPocketService,
    private direccionesService: DireccionesService) {
    this.pedido = new Pedido();
    this.idTienda = this.carritoService.getTiendaLS();
  }


  goToTienda(urlPersonalizada: string) {
    this.router.navigateByUrl('/' + urlPersonalizada);
  }

  //#region Direcciones del usuario
  askSaveDireccion(direccion: Direccion) {
    let promise = new Promise((resolve, reject) => {
      let existeDireccion: Direccion[] = this.misDireccionesGuardadas.filter((item) => {
        return JSON.stringify(item) == JSON.stringify(direccion);
      });
      if (existeDireccion == null || existeDireccion.length == 0) {
        Swal.fire({
          title: '¿Deseas guardar esta dirección para futuros pedidos?',
          text: 'Tus direcciones no se almacenarán en nuestra base de datos',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, guardarla',
          cancelButtonText: 'No, gracias'
        }).then((result) => {
          if (result.value) {
            this.direccionesService.addDireccionToMisDirecciones(direccion);
            Swal.fire({
              title: 'Direccion guardada correctamente',
              text: 'Su dirección se ha guardado en el dispositivo.',
              icon: 'success'
            }).then((result) => {
              resolve(true);
              return resolve;
            });
          } else {
            this.misDireccionesGuardadas = this.direccionesService.getMisDirecciones();
            resolve(true);
            return resolve;
          }
        })
      } else {
        resolve(true);
        return resolve;
      }
    })
    return promise;
  }

  ngAfterViewInit(): void {
    $(document).ready(function () {
      $('#modalDirecciones').modal();
    });
  }

  showDirecciones() {
    this.misDireccionesGuardadas = this.direccionesService.getMisDirecciones();
    $('#modalDirecciones').modal('open');
  }

  closeDirecciones() {
    $('#modalDirecciones').modal('close');
  }

  composeDireccion(i: number) {
    return this.misDireccionesGuardadas[i].calle + " " +
      this.misDireccionesGuardadas[i].numero + ", Colonia " +
      this.misDireccionesGuardadas[i].nombreColonia + ", " +
      this.misDireccionesGuardadas[i].referencia + ", " +
      "entre " + " " +
      this.misDireccionesGuardadas[i].entreCalle + " y " +
      this.misDireccionesGuardadas[i].yCalle + "."
  }

  onDireccionSelected(i: number) {
    let direccion: Direccion = this.misDireccionesGuardadas[i];
    this.formPedido.controls['idColonia'].setValue(direccion.idColonia);
    this.formPedido.controls['nombreColonia'].setValue(direccion.nombreColonia);
    this.formPedido.controls['calle'].setValue(direccion.calle);
    this.formPedido.controls['numero'].setValue(direccion.numero);
    this.formPedido.controls['referencia'].setValue(direccion.referencia);
    this.formPedido.controls['entreCalle'].setValue(direccion.entreCalle);
    this.formPedido.controls['yCalle'].setValue(direccion.yCalle);
    this.closeDirecciones();
  }

  onDireccionDeleted(i: number) {
    Swal.fire({
      title: '¿Deseas eliminar esta dirección?',
      text: 'Esta acción no se podrá deshacer',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminarla',
      cancelButtonText: 'No, gracias'
    }).then((result) => {
      if (result.value) {
        this.direccionesService.deleteDireccion(i);
        this.misDireccionesGuardadas = this.direccionesService.getMisDirecciones();
        this.closeDirecciones();
      }
    })
  }
  //#endregion

  private processPayment(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let date = new Date(this.formPedido.get("cardExp").value);
      let body = {
        pan: this.formPedido.get("cardNumber").value,
        expDate: date.getFullYear().toString().substr(-2) + ("0" + (date.getMonth() + 1)).slice(-2),
        idTienda: this.tienda.idTienda
      }

      let ip = "";
      if (this.isAmericanExpress) {
        await this.billPocketService.getMyIpAdress()
          .toPromise()
          .then(
            resp => {
              ip = resp["ip"];
            },
            () => { reject("code error [1]"); }
          )
          .catch(() => { reject("code error [1]"); }
          );
        if (ip == "" || !ip) {
          return;
        }
      }

      let cardToken = "";
      await this.billPocketService
        .tokenizeCard(body)
        .toPromise()
        .then(
          data => {
            if (data["estatus"] == 200) {
              body = data["data"];
              if (body["cardToken"] && body["cardToken"].length > 0) {
                cardToken = body["cardToken"];
              } else {
                reject("code error [2]");
              }
            } else {
              reject("code error [2]");
            }
          },
          () => { reject("code error [2]"); })
        .catch(() => { reject("code error [2]"); });

      if (cardToken == "" || !cardToken) {
        return;
      }

      let payTnx: PayTransaction = new PayTransaction();
      payTnx.idTienda = this.tienda.idTienda;
      payTnx.cardToken = cardToken;
      payTnx.cvv = this.formPedido.get("cardCVV").value;
      payTnx.amount = this.getTotal();
      payTnx.txnType = null;
      payTnx.contractNumber = null;
      payTnx.paymentPlan = null;
      payTnx.amexCustPostalCode = this.formPedido.get('amexCustPostalCode').value;
      payTnx.amexCustAddress = this.formPedido.get('amexCustAddress').value;
      payTnx.amexCustFirstName = this.formPedido.get('amexCustFirstName').value;
      payTnx.amexCustLastName = this.formPedido.get('amexCustLastName').value;
      payTnx.amexCustEmailAddr = this.formPedido.get('amexCustEmailAddr').value;
      payTnx.amexCustHostServerNm = location.href;
      payTnx.amexCustBrowserTypDescTxt = window.navigator.userAgent;
      payTnx.amexShipToCtryCd = "484";
      payTnx.amexShipMthdCd = "02";
      payTnx.amexMerSKUNbr = "AquiPide->" + this.tienda.nombreComercial;
      payTnx.amexCustIPAddr = ip;
      payTnx.amexCustIdPhoneNbr = this.formPedido.get('amexCustIdPhoneNbr').value;
      payTnx.amexCallTypId = "61";

      this.billPocketService
        .processTnx(payTnx)
        .toPromise()
        .then(
          data => {
            if (data["estatus"] == 200) {
              let resp = data["data"];
              if (resp) {
                if (resp["status"] == 1) {
                  resolve(resp);
                }
                else reject(resp["message"] + ". " + "code error [3]");
              } else reject("code error [3]");
            } else reject("code error [3]");
          },
          () => { reject("code error [3]"); })
        .catch(() => { reject("code error [3]"); })
    });
  }

  async enviarPedido() {
    if (this.formPedido.invalid) {
      Swal.fire({
        text: 'No has agregado los datos requeridos MARCADOS EN ROJO',
        icon: 'info'
      }).finally(() => this.scrollingService.scrollTop());
    } else if (this.carritoCompras == null || this.carritoCompras.length == 0) {
      Swal.fire({
        title: 'Ups...',
        text: 'Parece que tu carrito está vacío',
        icon: 'info'
      });
    } else {
      this.formPedido.controls['nombreTienda'].setValue(this.tienda.nombreComercial);
      this.formPedido.controls['nombreSucursal'].setValue(this.sucursalSeleccionada.nombre);
      let pedido = new Pedido();
      let productos: ProductoCarrito[] = [];
      let combos: Combo[] = [];
      await this.carritoCompras.forEach(producto => {
        if (producto.idProducto) {
          productos.push(producto);
        } else if (producto.idCombo) {
          combos.push(producto);
        }
      });
      pedido.fillModel(this.formPedido.value, productos, combos);
      pedido.costoEnvio = this.getCostoEnvio();
      pedido.costoPedido = this.getSubTotal();
      pedido.costoTotal = this.getTotal();
      if (pedido.tipoEnvio == 1) {
        await this.askSaveDireccion(pedido.direccion);
      }

      if (pedido.tipoPago == "DebitCreditCard") {
        let payProcess = false;
        await this.processPayment()
          .then(value => {
            payProcess = true;
            pedido.billPocketTicketURL = value["ticketUrl"];
          }, (codeError) => {
            this.dataService.displayErrorMessage("Ocurrió un error al procesar el pago. Intenta nuevamente o selecciona otra forma de pago. " + codeError);
          }).catch(() => {
            this.dataService.displayErrorMessage("Ocurrió un error al procesar el pago. Intenta nuevamente o selecciona otra forma de pago. " + "[4]");
          });
        if (!payProcess) {
          return;
        }
      }
      this.dataService.postRequest("createPedido", pedido).toPromise().then(data => {
        if (data["data"]) {
          if (data["estatus"] == 200) {
            //Insercion en Firebase
            pedido.id = data["data"]
            pedido.status = 1
            pedido.text = this.whatsappService.createMessage(pedido, this.sucursalSeleccionada.domicilio);
            const db = getFirestore();
            setDoc(doc(db, `${pedido.idTienda}/sucursales/${pedido.idSucursal}/${pedido.id}`), pedido.asJson()).then((docRef)=>console.info("Document written with ID: ", pedido.id))
            .catch((e)=>console.error("Error adding document: ", e));

            let link = this.whatsappService.sendWhatsapp(this.sucursalSeleccionada.whatsApp, pedido, this.sucursalSeleccionada.domicilio);
            var popupWindow = window.open(link, '_blank');
            if (popupWindow) {
              this.infoStatus(`${pedido.idTienda}-${pedido.idSucursal}-${pedido.id}`).finally(()=>
                this.router.navigateByUrl('/' + this.tienda.urlPersonalizada)
              );
            } else {
              Swal.fire({
                title: 'Enviar pedido por Whatsapp',
                text: 'Tu navegador bloqueó el envío automático mediante Whatsapp 😓, ' +
                  'por favor,  presiona el siguiente botón o habilita las ventanas emergentes para no volver a mostrar este mensaje.',
                customClass: {
                  confirmButton: 'whatsapp-swal-wrapper'
                },
                confirmButtonText: `<a class="whatsapp-swal" href="${link}" target="_blank"><img style="margin-right:15px;" src="assets/img/whatsa.png" height="35" width="35"> Enviar WhatsApp</a>`,
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
              }).finally(() =>{
                this.infoStatus(`${pedido.idTienda}-${pedido.idSucursal}-${pedido.id}`).finally(()=>
                  this.router.navigateByUrl('/' + this.tienda.urlPersonalizada)
                );
              })
            }
            this.carritoService.destroyCarrito();
          } else {
            this.dataService.displayErrorMessage('Lo sentimos, no fue posible realizar el pedido.');
          }
        }
      }).catch(e => {
        this.dataService.displayErrorByRequest(e);
      });
    }
  }

  infoStatus(id: string){
    let link = `${environment.estatusPedido}/home/estatus-pedido/${id}`;
    return Swal.fire({
      title: 'Confirma el estatus de tu pedido',
      text: `Para confirmar en que estatus se encuentra tu pedido dirigite a: ${link}`,
      customClass: {
        confirmButton: 'whatsapp-swal-wrapper'
      },
      confirmButtonText: `<a class="whatsapp-swal" href="${link}" target="_blank">Revisar estatus</a>`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      allowEnterKey: false,
    });
  }

  onSucursalSelected() {
    if (this.formPedido.value.idSucursal !== null) {

      this.sucursales.forEach(suc => {
        if (suc.idSucursal == this.formPedido.value.idSucursal) {
          this.sucursalSeleccionada = suc;

          if (this.formPedido.value.tipoEnvio == 0) {//Si el tipo envio es igual a ordena y recoja
            if (this.sucursalSeleccionada.ordenaRecoja == false || this.sucursalSeleccionada.ordenaRecoja == 0) {
              //Si la sucursal selecciona acepta el ordena y recoja, se mantiene el valor de tipoEnvio
              this.formPedido.controls['tipoEnvio'].setValue(1);
              this.formPedido.controls['pagoCon'].clearValidators();
              this.formPedido.controls['pagoCon'].setValidators([Validators.required, Validators.min(this.getTotal())]);
              this.formPedido.controls['pagoCon'].updateValueAndValidity();
            }
          } else if (this.formPedido.value.tipoEnvio == 1) {//Si el tipo envio es igual a servicio domicilio
            this.formPedido.controls['pagoCon'].clearValidators();
            this.formPedido.controls['pagoCon'].setValidators([Validators.required, Validators.min(this.getTotal())]);
            this.formPedido.controls['pagoCon'].updateValueAndValidity();
            if (this.sucursalSeleccionada.servicioDomicilio == false || this.sucursalSeleccionada.servicioDomicilio == 0) {
              //Si la sucursal selecciona acepta el ordena y recoja, se mantiene el valor de tipoEnvio
              this.formPedido.controls['tipoEnvio'].setValue(0);
              this.formPedido.controls['pagoCon'].clearValidators();
              this.formPedido.controls['pagoCon'].setErrors(null);
              this.formPedido.controls['pagoCon'].updateValueAndValidity();
            }
          }

          this.validateProgramacionPedido();
          return;
        }
      });
    }
  }

  private validateProgramacionPedido() {
    const programarPedido = this.formPedido.controls['programarPedido'];
    let errors = { ...programarPedido.errors };
    errors = errors && errors.invalidDate ? errors : { ...errors, invalidDate: false };
    if (!programarPedido.value) {
      programarPedido.setErrors(errors && errors.required ? errors : null);
    } else {
      const fecha = this.formPedido.controls['fecha'];
      const hora = this.formPedido.controls['hora'];
      if (!this.validateFechaSelected(fecha.value, hora.value)) {
        errors["invalidDate"] = true;
        programarPedido.setErrors(errors);
      }
      else {
        programarPedido.setErrors(errors && errors.required ? errors : null);
      }
    }
  }

  get form() {
    return this.formPedido.controls;
  }

  getSubTotal() {
    return this.carritoCompras.map(e => e.subTotal).reduce((acc, value) => acc + value, 0);
  }

  getCostoEnvio() {
    return ((this.coloniaSelected && this.form.tipoEnvio.value == 1) ? this.coloniaSelected.costoEnvio : null);
  }

  getTotal() {
    return this.getSubTotal() + this.getCostoEnvio();
  }

  deleteProductoEnCarrito(index: number) {
    this.carritoService.deleteItemInCarrito(index);
  }

  initForm() {
    this.pedido.clearModel();
    this.formPedido = this.fb.group({
      idTienda: [this.idTienda, [Validators.required]],
      nombreTienda: [this.pedido.nombreTienda, /*[Validators.required]*/],
      idSucursal: [this.pedido.idSucursal, [Validators.required]],
      nombreSucursal: [this.pedido.nombreSucursal, /*[Validators.required]*/],
      programarPedido: [this.pedido.programarPedido, [Validators.required]],
      fecha: [this.pedido.fecha],
      hora: [this.pedido.hora],
      nombreCliente: [this.pedido.nombreCliente, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      telefono: [this.pedido.telefono, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(new RegExp('^[0-9]+$'))]],
      comentarios: [this.pedido.comentarios, [Validators.maxLength(100)]],
      tipoEnvio: [this.pedido.tipoEnvio, [Validators.required]],
      idColonia: [this.pedido.direccion.idColonia, /*[Validators.required]*/],
      nombreColonia: [this.pedido.direccion.nombreColonia, /*[Validators.required,Validators.minLength(5), Validators.maxLength(50)]*/],
      calle: [this.pedido.direccion.calle, [/*Validators.required,*/ Validators.maxLength(50)]],
      numero: [this.pedido.direccion.numero, [/*Validators.required,*/ Validators.maxLength(6)]],
      entreCalle: [this.pedido.direccion.entreCalle, [/*Validators.required,*/ Validators.maxLength(50)]],
      yCalle: [this.pedido.direccion.yCalle, [/*Validators.required,*/ Validators.maxLength(50)]],
      referencia: [this.pedido.referencia, [/*Validators.required,*/ Validators.maxLength(70)]],
      costoPedido: [0, [Validators.required]],
      costoEnvio: [0, [Validators.required]],
      costoTotal: [0, [Validators.required]],
      estatus: [this.pedido.estatus, [Validators.required]],
      tipoPago: [this.pedido.tipoPago, [Validators.required]],
      pagoCon: [this.pedido.pagoCon, [Validators.required, Validators.min(this.getTotal())]],
      cardNumber: [this.pedido.cardData.cardNumber, /*[Validators.required]*/],
      cardProprietaryName: [this.pedido.cardData.cardProprietaryName, /*[Validators.required]*/],
      cardCVV: [this.pedido.cardData.cardCVV, /*[Validators.required]*/],
      cardExp: [this.minDate, /*[Validators.required]*/],
      amexCustPostalCode: [null],
      amexCustAddress: [null],
      amexCustFirstName: [null],
      amexCustLastName: [null],
      amexCustEmailAddr: [null],
      amexCustIdPhoneNbr: [null],
    });
    this.setControlsListener();
  }

  setControlsListener() {
    const tipoEnvioControl = this.formPedido.get('tipoEnvio');
    const idColonia = this.formPedido.get('idColonia');
    const nombreColoniaControl = this.formPedido.get('nombreColonia');
    const calleControl = this.formPedido.get('calle');
    const numeroControl = this.formPedido.get('numero');
    const referenciaControl = this.formPedido.get('referencia');
    const entreCalleControl = this.formPedido.get('entreCalle');
    const yCalleControl = this.formPedido.get('yCalle');
    const tipoPagoControl = this.formPedido.get('tipoPago');
    const cardNumberControl = this.formPedido.get('cardNumber');
    const cardProprietaryNameControl = this.formPedido.get('cardProprietaryName');
    const cardCVVControl = this.formPedido.get('cardCVV');
    const cardExpControl = this.formPedido.get('cardExp');
    const programarPedidoControl = this.formPedido.get('programarPedido');
    const fechaControl = this.formPedido.get('fecha');
    const horaControl = this.formPedido.get('hora');

    tipoEnvioControl.valueChanges.subscribe(tipoEnvio => {
      //  
      if (tipoEnvio == 0) { //Si el tipo Envio es ordene y recoja
        //CLEAR VALIDATORS

        idColonia.clearValidators();
        nombreColoniaControl.clearValidators();
        calleControl.clearValidators();
        numeroControl.clearValidators();
        referenciaControl.clearValidators();
        entreCalleControl.clearValidators();
        yCalleControl.clearValidators();
        idColonia.setErrors(null);
        nombreColoniaControl.setErrors(null);
        calleControl.setErrors(null);
        numeroControl.setErrors(null);
        referenciaControl.setErrors(null);
        entreCalleControl.setErrors(null);
        yCalleControl.setErrors(null);
        // this.coloniaSelected = null;
        idColonia.setErrors(null);
      } else {
        //RESET CLEAR VALIDATORS

        idColonia.setValidators([Validators.required]);
        nombreColoniaControl.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(50)]);
        calleControl.setValidators([Validators.required, Validators.maxLength(50)]);
        numeroControl.setValidators([Validators.required, Validators.maxLength(6)]);
        referenciaControl.setValidators([Validators.required, Validators.maxLength(70)]);
        entreCalleControl.setValidators([Validators.required, Validators.maxLength(50)]);
        yCalleControl.setValidators([Validators.required, Validators.maxLength(50)]);
      }
      if (tipoPagoControl.value == "Efectivo")
        this.setPagoConControlValitadors([Validators.required, Validators.min(this.getTotal())]);
      else
        this.setPagoConControlValitadors(null);
      this.formPedido.updateValueAndValidity();
    });

    idColonia.valueChanges.subscribe(idColonia => {
      let colonia;
      if (this.colonias)
        colonia = this.colonias.find(colonia => colonia.idColonia == idColonia);
      if (colonia) {
        this.coloniaSelected = colonia;
        this.formPedido.controls['nombreColonia'].setValue(colonia.nombre);
        this.setPagoConControlValitadors([Validators.required, Validators.min(this.getTotal())]);
      } else {
        this.coloniaSelected = {
          idColonia: 0,
          costoEnvio: this.sucursalSeleccionada.costoenviodefault,
          nombre: this.formPedido.controls['nombreColonia'].value
        };
        this.setPagoConControlValitadors([Validators.required, Validators.min(this.getTotal())]);
      }
    });

    tipoPagoControl.valueChanges.subscribe(tipoPago => {
      if (tipoPago == 'TPV' || tipoPago == 'DebitCreditCard') {
        this.setPagoConControlValitadors(null);
        cardNumberControl.clearValidators();
        cardProprietaryNameControl.clearValidators();
        cardCVVControl.clearValidators();
        cardExpControl.clearValidators();
        cardNumberControl.setErrors(null);
        cardProprietaryNameControl.setErrors(null);
        cardCVVControl.setErrors(null);
        cardExpControl.setErrors(null);
        cardNumberControl.updateValueAndValidity();
        cardProprietaryNameControl.updateValueAndValidity();
        cardCVVControl.updateValueAndValidity();
        cardExpControl.updateValueAndValidity();
      } else if (tipoPago == 'Efectivo') {
        this.setPagoConControlValitadors([Validators.required, Validators.min(this.getTotal())]);
        cardNumberControl.clearValidators();
        cardProprietaryNameControl.clearValidators();
        cardCVVControl.clearValidators();
        cardExpControl.clearValidators();
        cardNumberControl.setErrors(null);
        cardProprietaryNameControl.setErrors(null);
        cardCVVControl.setErrors(null);
        cardExpControl.setErrors(null);
        cardNumberControl.updateValueAndValidity();
        cardProprietaryNameControl.updateValueAndValidity();
        cardCVVControl.updateValueAndValidity();
        cardExpControl.updateValueAndValidity();
        this.deactivateAmexControls(true);
      }
      if (tipoPago == 'DebitCreditCard') {
        this.setPagoConControlValitadors(null);
        cardNumberControl.setValidators([Validators.required, Validators.minLength(15), Validators.maxLength(16), Validators.pattern("^[0-9]+$")]);
        cardProprietaryNameControl.setValidators([Validators.required]);
        cardCVVControl.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern("^[0-9]+$")]);
        cardExpControl.setValidators([Validators.required]);
        cardNumberControl.updateValueAndValidity();
        cardProprietaryNameControl.updateValueAndValidity();
        cardCVVControl.updateValueAndValidity();
        cardExpControl.updateValueAndValidity();
      }
    });

    cardNumberControl.valueChanges.subscribe(value => {
      if (value[0] == "3" && !this.isAmericanExpress) {
        this.isAmericanExpress = true;
        this.deactivateAmexControls(false);
      } else if (value[0] != "3" && this.isAmericanExpress) {
        this.isAmericanExpress = false;
        this.deactivateAmexControls(true);
      }
    });

    programarPedidoControl.valueChanges.subscribe(value => {
      if (value) {
        fechaControl.setValidators(Validators.required);
        horaControl.setValidators(Validators.required);
        this.validateProgramacionPedido();
      }
      else {
        fechaControl.clearValidators();
        fechaControl.setErrors(null);
        horaControl.clearValidators();
        horaControl.setErrors(null);
      }
      fechaControl.updateValueAndValidity({ onlySelf: true });
      horaControl.updateValueAndValidity({ onlySelf: true });
    });

    fechaControl.valueChanges.subscribe(value => {
      this.validateProgramacionPedido();
    });

    horaControl.valueChanges.subscribe(value => {
      this.validateProgramacionPedido();
    });
  }

  private validateFechaSelected(fecha: string, hora: string): boolean {
    if (!fecha || !hora) return false;
    try {
      const day = _moment(fecha).day();
      const horarioApertura = horarios.apertura[day];
      const horarioCierre = horarios.cierre[day];

      const apertura = this.sucursalSeleccionada[horarioApertura].split(/[.:]/);
      const cierre = this.sucursalSeleccionada[horarioCierre].split(/[.:]/);
      const aperturaToNumber = Number((Number(apertura[0]) + Number(apertura[1]) / 60).toFixed(2));
      const cierreToNumber = Number((Number(cierre[0]) + Number(cierre[1]) / 60).toFixed(2));

      const horaSplitted = hora.split(/[.:]/);
      const horaToNumber = Number((Number(horaSplitted[0]) + Number(horaSplitted[1]) / 60).toFixed(2));

      return (horaToNumber > aperturaToNumber && horaToNumber < cierreToNumber)
    } catch (error) {
      return false;
    }
  }

  setPagoConControlValitadors(validators: ValidatorFn[]) {
    const pagoConControl = this.formPedido.get('pagoCon');
    pagoConControl.setErrors(null);
    pagoConControl.clearValidators();
    pagoConControl.setValidators(validators);
    pagoConControl.updateValueAndValidity();
  }

  private deactivateAmexControls(deactivate: boolean) {
    const amexCustPostalCode = this.formPedido.get('amexCustPostalCode');
    const amexCustAddress = this.formPedido.get('amexCustAddress');
    const amexCustFirstName = this.formPedido.get('amexCustFirstName');
    const amexCustLastName = this.formPedido.get('amexCustLastName');
    const amexCustEmailAddr = this.formPedido.get('amexCustEmailAddr');
    const amexCustIdPhoneNbr = this.formPedido.get('amexCustIdPhoneNbr');

    if (deactivate) {
      amexCustPostalCode.clearValidators();
      amexCustAddress.clearValidators();
      amexCustFirstName.clearValidators();
      amexCustLastName.clearValidators();
      amexCustEmailAddr.clearValidators();
      amexCustIdPhoneNbr.clearValidators();
      amexCustPostalCode.setErrors(null);
      amexCustAddress.setErrors(null);
      amexCustFirstName.setErrors(null);
      amexCustLastName.setErrors(null);
      amexCustEmailAddr.setErrors(null);
      amexCustIdPhoneNbr.setErrors(null);
    } else {
      amexCustPostalCode.setValidators([Validators.required, Validators.maxLength(9)]);
      amexCustAddress.setValidators([Validators.required, Validators.maxLength(20)]);
      amexCustFirstName.setValidators([Validators.required, Validators.maxLength(15)]);
      amexCustLastName.setValidators([Validators.required, Validators.maxLength(30)]);
      amexCustEmailAddr.setValidators([Validators.required, Validators.maxLength(60), Validators.email]);
      amexCustIdPhoneNbr.setValidators([Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]+$")]);
    }
    amexCustPostalCode.updateValueAndValidity();
    amexCustAddress.updateValueAndValidity();
    amexCustFirstName.updateValueAndValidity();
    amexCustLastName.updateValueAndValidity();
    amexCustEmailAddr.updateValueAndValidity();
    amexCustIdPhoneNbr.updateValueAndValidity();
  }

  //#region Date Handlers
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = new Date(this.formPedido.controls.cardExp.value);
    ctrlValue.setFullYear(normalizedYear.toDate().getFullYear());
    this.formPedido.controls.cardExp.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = new Date(this.formPedido.controls.cardExp.value);
    ctrlValue.setMonth(normalizedMonth.month());
    this.formPedido.controls.cardExp.setValue(ctrlValue);
    datepicker.close();
  }
  //#endregion

  getSucursalesByTienda() {
    this.dataService.postRequest("getSucursalesPorTiendaAbiertas", { idTienda: this.idTienda }).toPromise()
      .then(data => {
        this.sucursales = data["data"];
      })
      .catch()
      .finally()
  }

  getLocalidadesByTienda() {
    this.dataService.postRequest("getColoniasConsumidor", { idTienda: this.idTienda }).toPromise()
      .then(data => {
        this.colonias = data["data"];
        this.filteredColonias = this.formPedido.controls.nombreColonia.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      })
      .catch()
      .finally()
  }

  optionSelected(idColonia) {
    this.formPedido.controls.idColonia.setValue(idColonia);
  }

  onBlurColonia(forced = false) {
    if (!this.coloniaSelected || forced)
      this.formPedido.controls.idColonia.setValue(0);
  }

  getTiendaById() {
    this.dataService.postRequest('getTiendaById', { idTienda: this.idTienda }).toPromise()
      .then(data => {
        this.tienda = data["data"];
      })
      .catch()
      .finally();
  }

  ngOnInit() {
    this.carritoService.currentCarritoCompras.subscribe(carrito => {
      this.carritoCompras = carrito;
    });
    this.initForm();
    this.getSucursalesByTienda();
    this.getLocalidadesByTienda();
    this.getTiendaById();
    this.misDireccionesGuardadas = this.direccionesService.getMisDirecciones();
  }

  private _filter(value: string): string[] {
    if (typeof value == 'string' && this.colonias) {
      const filterValue = value.toLowerCase();
      return this.colonias.filter(colonia => colonia.nombre.toLowerCase().includes(filterValue));
    } else
      return [];
  }

}
