// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  estatusPedido: 'https://dev.aquipide.com',
  apiUrl: `https://dev.aquipide.com/api/?u=`,
  serverUrl: `https://dev.aquipide.com/api/`,
  imageUrlBase: `https://dev.aquipide.com/api/img/`,
  uploadImg: `https://dev.aquipide.com/api`,
  apiImg: `https://dev.aquipide.com/api/img/`,
  version: `1.2.1_dev`,
  firebaseConfig: {
    apiKey: "AIzaSyAkBaKrgjS6_a4WB9RsCQ0WWqsIzlyigXQ",
    authDomain: "aquipide-dev.firebaseapp.com",
    projectId: "aquipide-dev",
    storageBucket: "aquipide-dev.appspot.com",
    messagingSenderId: "14126796035",
    appId: "1:14126796035:web:8be9152054e96b3e10ed36",
    measurementId: "G-LN7PDV6E1R"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
