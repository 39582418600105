import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GeneralService } from 'src/app/services/general-service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-restaurantes',
  templateUrl: './restaurantes.component.html',
  styleUrls: ['./restaurantes.component.scss']
})
export class RestaurantesComponent implements OnInit {
  girosComerciales: any;
  imgUrl = environment.imageUrlBase;
  tiendas: any;
  filteredTiendas = [];
  idGiroComercialByRoute: any;

  constructor(
    private dataService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.idGiroComercialByRoute = this.route.snapshot.params["idGiroComercial"] ? this.route.snapshot.params["idGiroComercial"] : null;
     
  }

  goToMenu(urlPersonalizada: any) {
    this.router.navigate(['/', urlPersonalizada]);
  }

  filterTiendasByGiroComercial(idGiroComercial) {
    if (idGiroComercial) {
      this.filteredTiendas = this.tiendas.filter(tienda =>
        (tienda["girocomercial"] == idGiroComercial));
    } else {
      this.filteredTiendas = this.tiendas;
    }
  }

  getGiroInTiendaCounter(idGiroComercial: any) {
    let total = 0;
    if (this.tiendas && this.tiendas.length > 0) {
      this.tiendas.forEach(tienda => {
        if (tienda.girocomercial == idGiroComercial) {
          total++;
        }
      });
    } else {
      total = 0;
    }
    return total;
  }

  getGirosComerciales() {
    this.dataService.postRequest("getGirosComerciales", {}).toPromise().then(data => {
      this.girosComerciales = data["data"];
       
    })
      .catch()
      .finally();
  }

  getTiendasConsumidor() {
    this.dataService.postRequest("getTiendasConsumidor", {}).toPromise().then(data => {
      this.tiendas = data["data"];
      this.filteredTiendas = data["data"];
      this.filterTiendasByGiroComercial(this.idGiroComercialByRoute);
       
    })
      .catch()
      .finally();
  }

  ngOnInit() {
    this.getTiendasConsumidor();
    this.getGirosComerciales();
  }

}
